import React, { Component } from 'react';
import { ReactComponent as StarIcon} from '../icons/star.svg';

class StarsRating extends Component {

  render() {
    let {rating, onClickCallback} = this.props;
    let activeStars = [];
    for (let i = 0; i < Math.round(rating); i++) {
      activeStars.push(<StarIcon className="rating-stars__star-icon rating-stars__star-icon_active svg-icon"
                          key={i} onClick={onClickCallback ? onClickCallback.bind(this, i) : null}/>)
    }

    let stars = [];
    for (let i = 0; i < 5-Math.round(rating); i++) {
      stars.push(<StarIcon className="rating-stars__star-icon svg-icon" key={i}
                    onClick={onClickCallback ? onClickCallback.bind(this, activeStars.length + i) : null}/>)
    }
    return(
      <div className={"rating-stars " + this.props.className}>
        {activeStars}
        {stars}
      </div>
    )
  }
}

export default StarsRating;
