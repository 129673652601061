export const setDarkMode = () => {
    document.querySelector("html").setAttribute('data-theme','dark');
    localStorage.setItem("selectedTheme","dark");
    return "dark";
}

export const setLightMode = () => {
    document.querySelector("html").setAttribute('data-theme','light');
    localStorage.setItem("selectedTheme","light");
    return "light";
}

export const detectTheme = () => {
    const selectedTheme = localStorage.getItem("selectedTheme");

    // if (selectedTheme === "dark" || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    if (selectedTheme === "dark") {
        setDarkMode();
    }

    return selectedTheme || setDarkMode();
}

export const toggleTheme = (e) => {
    if (e.target.checked) return setDarkMode();

    return setLightMode();
};
