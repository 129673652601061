import React, { Component } from 'react';
import * as playlistUtils from "../../../utils/playlists";
import connect from "react-redux/es/connect/connect";
import DropTarget from "react-dnd/lib/DropTarget";
import PlaylistTrack from "./PlaylistTrack";
import UrlHelpers from "../../../helpers/Url";
import {PRELOADED_TRACKS_COUNT, preloadPlayerTrack} from "../../../utils/wavesurfer";

import {ReactComponent as ArrowLeftIcon} from '../../../icons/keyboard_arrow_left.svg';
import {ReactComponent as DeleteIcon} from '../../../icons/delete.svg';
import {ReactComponent as InfoIcon} from '../../../icons/info.svg';
import {ReactComponent as ShuffleIcon} from '../../../icons/shuffle.svg';
import {ReactComponent as CloudDownloadIcon} from '../../../icons/cloud_download.svg';
import UserHelpers from "../../../helpers/User";

class PlaylistItem extends Component {

  constructor(props, context) {
    super(props, context);
    this.onTrackStopCallback = this.onTrackStopCallback.bind(this);
    this.loadNextTracks = this.loadNextTracks.bind(this);
  }

  componentWillMount() {
    let {fetchAllTrackPeaks, playlist, peaks} = this.props;
    fetchAllTrackPeaks(playlist.trackPlayLists, peaks);
  }

  editPlaylist(playlist) {
    this.props.editPlaylist(playlist);
  }

  shuffleTracks() {
    let {playlist} = this.props;
    let tracks = playlistUtils.shuffleTracks(this.props.playlist.trackPlayLists);
    this.props.updatePlaylistTracks(playlist, tracks);
  }

  downloadPlaylist(playlist, e) {
    e.preventDefault();

    if (playlist.archivePlaylist !== null && playlist.download) {
      window.open(`${UrlHelpers.baseArchive()}/${playlist.archivePlaylist.archivePlaylistId}/playlist-${playlist.playListId}.zip`, '_blank');
      return;
    }

    UserHelpers.showError('Скачивание не доступно. Воспользуйтесь разделом "Лента" для трансляции онлайн.');
    //this.props.downloadPlayList(playlist);
  }

  onTrackStopCallback() {
    const {playlist, peaks, playingTrackIndex} = this.props;

    let nextTrack = null;
    const tracks = playlist.trackPlayLists;

    if(playingTrackIndex >= 0 && playingTrackIndex < tracks.length - 1) {
      nextTrack = tracks[playingTrackIndex + 1];
    }

    if (nextTrack) {
      const playlistInstance = this['playlistTrackItem_' + nextTrack.trackId + '_' + (playingTrackIndex + 1)];
      if (playlistInstance) {
        this.props.playbackStatistic(nextTrack.fileHash);
        playlistInstance.play(nextTrack, document, peaks[nextTrack.trackId], playingTrackIndex + 1);
      }
    }
  }

  loadNextTracks(playingTrackIndex) {
    const {playlist} = this.props;
    const tracks = playlist.trackPlayLists;
    let count = 0;
    while(count < PRELOADED_TRACKS_COUNT && tracks[playingTrackIndex + count + 1]) {
      count++;
      const nextTrack = tracks[playingTrackIndex + count];
      preloadPlayerTrack(nextTrack, "All");
    }
  }

  render() {
    let {playlist,
      pause,
      playingTrack,
      isPlayingTrackActive,
      playingWavesurfer,
      wavesurfer,
      playingTrackSource,
      isTrackActive,
      removeTrackFromPlaylist,
      connectDropTarget,
      peaks,
      fetchTrackPeaks,
      toggleLoader,
      playbackStatistic,
      startPlayingTrack,
      stopPlayingTrack,
      updateCurrentTimeForPlayingTrack,
      updatePlaylistTracks,
      playingTrackIndex
    } = this.props;
    const hasArchive = playlist && playlist.download && playlist.archivePlaylist !== null;
    return connectDropTarget(
      <div className="playlist-inside">
            <div className="right-actions">
                <a className="right-actions__item" onClick={this.props.fetchPlaylists}>
                    <ArrowLeftIcon className="right-actions__icon svg-icon" />
                </a>
                <a className="right-actions__item" onClick={this.props.deletePlaylist.bind(this, playlist.playListId)}>
                    <DeleteIcon className="right-actions__icon svg-icon" />
                </a>
                <a className="right-actions__item" onClick={this.editPlaylist.bind(this, playlist)}>
                    <InfoIcon className="right-actions__icon svg-icon" />
                </a>
                {
                  playlist.trackPlayLists.length > 1 &&
                  <a className="right-actions__item" onClick={this.shuffleTracks.bind(this)}>
                    <ShuffleIcon className="right-actions__icon svg-icon" />
                  </a>
                }
                {
                  hasArchive &&
                  playlist.trackPlayLists.length > 0 &&
                  <a className={`right-actions__item ${hasArchive ? 'right-actions__item__download' : ''}`} onClick={this.downloadPlaylist.bind(this, playlist)}>
                    <CloudDownloadIcon className="right-actions__icon svg-icon" />
                    <span className="right-actions__hint">Скачать плейлист</span>
                  </a>
                }
                {
                  !hasArchive &&
                  playlist.trackPlayLists.length > 0 &&
                  <a className="right-actions__item" onClick={this.downloadPlaylist.bind(this, playlist)}>
                    <CloudDownloadIcon className="right-actions__icon svg-icon" />
                  </a>
                }
            </div>
            <h3 className="playlist-inside__title">{playlist.name}</h3>

            { playlist.trackPlayLists.length > 0 &&
             <div className="drag-drop-icon_hide" >   </div>
            }

            { playlist.trackPlayLists.length === 0 &&
             <div className="drag-drop-icon" >   </div>
            }
   
            { playlist.trackPlayLists.length > 0 &&
              <div className="right-tracks playlist-inside__tracks">
                  <span className="right-tracks__count">Треков:&nbsp;
                      <b>{playlist.trackPlayLists.length}</b>
                  </span>
                  <span className="right-tracks__time">{playlistUtils.renderDuration(playlist.duration)}</span>
                  <div className="right-tracks__content">
                      <div className="right-tracks__tracks">
                         {playlist.trackPlayLists.map((track, i) =>
                          <PlaylistTrack key={i} index={i} track={track}
                                         playlist={playlist}
                                         pause={pause}
                                         isTrackActive={isTrackActive}
                                         removeTrackFromPlaylist={removeTrackFromPlaylist}
                                         ref={instance => { this['playlistTrackItem_' + track.trackId + '_' + i] = instance; }}
                                         fetchTrackPeaks={fetchTrackPeaks}
                                         peaks={peaks}
                                         playbackStatistic={playbackStatistic}
                                         startPlayingTrack={startPlayingTrack}
                                         stopPlayingTrack={stopPlayingTrack}
                                         playingTrack={playingTrack}
                                         isPlayingTrackActive={isPlayingTrackActive}
                                         playingWavesurfer={playingWavesurfer}
                                         wavesurfer={wavesurfer}
                                         playingTrackSource={playingTrackSource}
                                         toggleLoader={toggleLoader}
                                         onTrackStopCallback={this.onTrackStopCallback}
                                         updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                                         updatePlaylistTracks={updatePlaylistTracks}
                                         playingTrackIndex={playingTrackIndex}
                                         loadNextTracks={this.loadNextTracks} />
                          )}
                      </div>
                  </div>
              </div>
            }
            { playlist.trackPlayLists.length === 0 &&
              <p className="playlist-inside__hint">Перетащите треки из каталога или нажмите у трека в каталоге добавить в плейлист</p>
            }
          </div>
    )
  }
}

const dropTarget = {
  drop(props, monitor, component) {
    const track = monitor.getItem().track;
    if (props.playlist) {
      props.addTrackToPlaylist(props.playlist, track);
    }
  }
};

function collectDropTrack(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

const DroppablePlaylistItem =  DropTarget('playlist-track', dropTarget, collectDropTrack)(PlaylistItem);

export default connect(state => ({ playlist: state.catalog.playlist,
  playingTrackIndex: state.catalog.playingTrackIndex }))(DroppablePlaylistItem);
