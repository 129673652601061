import React, { Component } from 'react';
import CreatePlaylist from './CreatePlaylist.js';
import PlaylistItem from './PlaylistItem.js';
import Playlists from './Playlists.js';
import EmptyPlaylists from './EmptyPlaylists.js';
import EditPlaylist from './EditPlaylist.js';
import connect from "react-redux/es/connect/connect";

import {ReactComponent as QueueMusicIcon} from '../../../icons/queue_music.svg';
import {ReactComponent as AddIcon} from '../../../icons/playlist_add.svg';
import {downloadPlayList} from "../../../actions/catalog";

class PlaylistRightSidebar extends Component {

  constructor(props, context) {
    super(props, context);
    this.showPlaylistItem = this.showPlaylistItem.bind(this);
    this.savePlaylist = this.savePlaylist.bind(this);
    this.editPlaylist = this.editPlaylist.bind(this);
    this.firstLoad = true;
  }

  componentDidMount() {
    this.props.fetchPlaylists();
  }

  componentWillReceiveProps() {
    this.firstLoad = false;
  }

  createPlaylist() {
    this.props.createPlaylist();
  }

  cancelCreatePlaylist() {
    this.props.cancelCreatePlaylist();
  }

  cancelUpdatePlaylist() {
    this.props.cancelUpdatePlaylist();
  }

  showPlaylistItem(playlistId) {
    this.props.showPlaylist(playlistId);
  }

  savePlaylist(name, dayTime) {
    this.props.savePlaylist(name, dayTime);
  }

  editPlaylist(playlist) {
    this.props.editPlaylist(playlist);
  }

  deletePlaylist(id) {
    this.props.deletePlaylist(id);
  }

  addTrackToPlaylist(playlist, track) {
    this.props.addTrackToPlaylist(playlist, track);
  }

  render() {
    let {playlists,
      playlistAction,
      playlist,
      playbackStatistic,
      startPlayingTrack,
      playingWavesurfer,
      playingTrack,
      stopPlayingTrack,
      isPlayingTrackActive,
      playingTrackSource,
      updateCurrentTimeForPlayingTrack,
      updatePlaylist,
      downloadPlayList,
      updatePlaylistTracks,
      removeTrackFromPlaylist,
      peaks,
      fetchTrackPeaks,
      fetchAllTrackPeaks,
      toggleLoader,
      addTrackToPlaylist,
      fetchPlaylists} = this.props;

    return (
      <span>
        <div className="right-heading right-heading_border-dark">
            <span className="right-heading__icon"
                  onClick={this.props.fetchPlaylists.bind(this)}>
                <QueueMusicIcon className="svg-icon" />
                <span className="right-heading__count">{playlists.length}</span>
            </span>
            <span className="right-heading__text"
                  onClick={this.props.fetchPlaylists.bind(this)}>Плейлисты</span>
            <a onClick={this.createPlaylist.bind(this)}><AddIcon className="right-heading__arrow svg-icon"/></a>
        </div>
        {
          playlistAction === 'EDIT' &&
          <EditPlaylist playlist={playlist} cancelUpdatePlaylist={this.cancelUpdatePlaylist.bind(this)}
                        updatePlaylist={updatePlaylist} />
        }

        {
          playlistAction === 'CREATE' &&
          <CreatePlaylist cancelCreatePlaylist={this.cancelCreatePlaylist.bind(this)} savePlaylist={this.savePlaylist} />
        }

        { playlists.length === 0 && playlistAction === 'LIST' && !this.firstLoad &&
          <EmptyPlaylists createPlaylist={this.createPlaylist.bind(this)} />
        }

        { playlists.length !== 0 && playlistAction === 'LIST' &&
          <Playlists showPlaylistItem={this.showPlaylistItem} playlists={playlists}  />
        }

        { playlistAction === 'SHOW' &&
          <PlaylistItem playlist={playlist} editPlaylist={this.editPlaylist}
                        cancelCreatePlaylist={this.cancelCreatePlaylist.bind(this)}
                        deletePlaylist={this.deletePlaylist.bind(this)}
                        playbackStatistic={playbackStatistic}
                        startPlayingTrack={startPlayingTrack}
                        stopPlayingTrack={stopPlayingTrack}
                        downloadPlayList={downloadPlayList}
                        updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                        playingWavesurfer={playingWavesurfer}
                        playingTrack={playingTrack}
                        isPlayingTrackActive={isPlayingTrackActive}
                        playingTrackSource={playingTrackSource}
                        updatePlaylistTracks={updatePlaylistTracks}
                        removeTrackFromPlaylist={removeTrackFromPlaylist}
                        peaks={peaks}
                        fetchTrackPeaks={fetchTrackPeaks}
                        fetchAllTrackPeaks={fetchAllTrackPeaks}
                        toggleLoader={toggleLoader}
                        fetchPlaylists={fetchPlaylists}
                        addTrackToPlaylist={addTrackToPlaylist}
          />
        }
      </span>
    )
  }
}

export default connect(state => ({ playlist: state.catalog.playlist }))(PlaylistRightSidebar);
