import React, { Component } from 'react';
import PlayerWave from "../Tracks/TracklistPlayerWave";
import {connect} from "react-redux";
import DurationTracklistPlayer from "./DurationTracklistPlayer";

class TracklistPlayer extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {duration, genreTracks} = this.props.track;
    const {startPlayingTrack,
      track,
      playingTrack,
      playingWavesurfer,
      isPlayingTrackActive,
      stopPlayingTrack,
      playingTrackSource,
      updateCurrentTimeForPlayingTrack,
      fetchTrackPeaks,
      peaks,
      toggleLoader,
      loading} = this.props;
    return (
      <div className="track-list__player">
          <DurationTracklistPlayer duration={duration} playingTrackSource={playingTrackSource}
                                   trackId={track.trackId} playingTrackId={playingTrack.trackId} />

          {genreTracks.map((genre, i) =>
            <span className="track-list__tag" key={i}>{genre}</span>
          )}

          <PlayerWave className="track-list__equalizer"
                      track={track}
                      height={78}
                      playingTrack={playingTrack}
                      playingWavesurfer={playingWavesurfer}
                      startPlayingTrack={startPlayingTrack}
                      stopPlayingTrack={stopPlayingTrack}
                      isPlayingTrackActive={isPlayingTrackActive}
                      playingTrackSource={playingTrackSource}
                      updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                      fetchTrackPeaks={fetchTrackPeaks}
                      peaks={peaks}
                      toggleLoader={toggleLoader}
                      loading={loading}
          />
      </div>
    )
  }
}

export default connect(state => ({ playingTrack: state.catalog.playingTrack, loading: state.catalog.loading }))(TracklistPlayer);


