import React, { Component } from 'react';
import {connect} from "react-redux";
import FeedPlaylistHeader from "./FeedPlaylistHeader";
import FeedPlaylistPlayer from "./FeedPlaylistPlayer";
import TracksTableHeader from "../Tracks/TracksTableHeader";
import * as wsUtils from "../../../utils/wavesurfer";
import WaveSurfer from "wavesurfer.js";
import NProgress from "nprogress";
import * as tracksUtils from "../../../utils/tracks";
import FeedTracksTableItem from "./FeedTracksTableItem";
import {PRELOADED_TRACKS_COUNT} from "../../../utils/wavesurfer";
import {preloadPlayerTrack, trackUrl} from "../../../utils/wavesurfer";
import {removePreloadedTracks} from "../../../utils/wavesurfer";

class FeedPlaylist extends Component {

  constructor(props, context) {
    super(props, context);
    this.onTrackStopCallback = this.onTrackStopCallback.bind(this);
    this.changeFeedPlaylistPositionHandler = this.changeFeedPlaylistPositionHandler.bind(this);
    this.initPlayer = this.initPlayer.bind(this);
    this.play = this.play.bind(this);
    this.tracksChangedInGeneralPlaylist = this.tracksChangedInGeneralPlaylist.bind(this);
    this.pauseClickHandler = this.pauseClickHandler.bind(this);
    this.isTrackSelected = this.isTrackSelected.bind(this);
    this.isTrackActive = this.isTrackActive.bind(this);
    this.reinitPlayer = this.reinitPlayer.bind(this);
    this.isCurrentPlaylistTrackPlaying = this.isCurrentPlaylistTrackPlaying.bind(this);
    this.isCurrentPlaylistTrackPaused = this.isCurrentPlaylistTrackPaused.bind(this);
    this.isCurrentPlaylistTrackSelected = this.isCurrentPlaylistTrackSelected.bind(this);
    this.wavesurfer = null;
    this.firstLoad = true;

    this.state = {
      playlistPositionValue: props.playlist.index ? props.playlist.index : props.index
    };

    this.firstLoad = true;
  }

  updateWave() {
    if (this.wavesurfer && !this.wavesurfer.isDestroyed) {
      this.wavesurfer.drawBuffer();
    }
  }

  componentDidMount() {
    let {fetchAllTrackPeaks, playlist, peaks} = this.props;

    if (this.firstLoad && playlist.tracks.length > 0 && peaks[playlist.tracks[0].trackId]){
      this.firstLoad = false;
      const trackPeaks = peaks[playlist.tracks[0].trackId];
      this.initPlayer(trackPeaks, wsUtils.getFeedPlaylistWaveContainer(playlist));
    }

    fetchAllTrackPeaks(playlist.tracks, peaks);

    this.updateWave();
    window.addEventListener("resize", this.updateWave);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWave);
     const {playingWavesurfer, playingFeedWavesurfer, isPlayingFeedTrackActive} = this.props;

    if (playingWavesurfer) {
      tracksUtils.stopPlayer(playingWavesurfer);
    }

    if (isPlayingFeedTrackActive) {
      tracksUtils.stopPlayer(playingFeedWavesurfer);
    }

    removePreloadedTracks();
  }

  componentWillReceiveProps(props) {
    const {playlist, peaks} = props;
    if (this.firstLoad && playlist.tracks.length > 0 && peaks[playlist.tracks[0].trackId]){
      this.firstLoad = false;
      const trackPeaks = peaks[playlist.tracks[0].trackId];
      this.initPlayer(trackPeaks, wsUtils.getFeedPlaylistWaveContainer(playlist));
    }
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  onTrackStopCallback(prevPlayingTrack) {
    const {playingFeedPlaylist, activeFeed, playNextPlaylist, playingFeedTrackIndex} = this.props;

    let nextTrack = null;
    let nextPlaylist = playingFeedPlaylist;
    const tracks = playingFeedPlaylist.tracks;

    if (playingFeedPlaylist.shuffle) {
      const randomIndex = this.getRandomInt(0, tracks.length - 1);
      nextTrack = tracks[randomIndex + 1];
      if (nextTrack) {
        this.play(nextTrack, playingFeedPlaylist, randomIndex + 1);
      }
      return;
    }

    const index = tracks.indexOf(prevPlayingTrack);
    if(index >= 0 && index < tracks.length - 1) {
      nextTrack = tracks[index + 1];
      if (nextTrack) {
        this.play(nextTrack, nextPlaylist, playingFeedTrackIndex + 1);
      }
    } else {
      const playlists = activeFeed.playlists;
      const found = playlists.find(function(p) {
        return playingFeedPlaylist.playListId === p.playListId;
      });
      let playlistIndex = playlists.indexOf(found);
      while (playlists[playlistIndex + 1] && !playlists[playlistIndex + 1].active) {
        playlistIndex++;
      }
      if(playlistIndex >= 0 && playlistIndex < playlists.length - 1) {
        nextTrack = playlists[playlistIndex + 1].tracks[0];
        nextPlaylist = playlists[playlistIndex + 1];
        if (nextTrack) {
          playNextPlaylist(nextTrack, nextPlaylist);
        }
      }
    }
  }

  loadNextTracks(prevPlayingTrack) {
    const {playingFeedPlaylist, playingFeedTrackIndex} = this.props;
    if (playingFeedPlaylist.shuffle) {
      return;
    }
    const tracks = playingFeedPlaylist.tracks;
    const index = tracks.indexOf(prevPlayingTrack);

    if(index >= 0 && index < tracks.length - 1) {
      let count = 0;
      while(count < PRELOADED_TRACKS_COUNT && tracks[playingFeedTrackIndex + count + 1]) {
        count++;
        const nextTrack = tracks[playingFeedTrackIndex + count];
        preloadPlayerTrack(nextTrack, playingFeedPlaylist.playListId);
      }
    }
  }

  changeFeedPlaylistPositionHandler(e) {
    const {changeFeedPlaylistPosition, playlist, activeFeed} = this.props;
    const index = +e.target.value;
    if (Number.isInteger(index)) {
      changeFeedPlaylistPosition(playlist, activeFeed, index);
    }
    this.setState({playlistPositionValue: index});
  }

  isTrackSelected(track, trackIndex) {
    const {playingFeedPlaylist, playlist, playingFeedWavesurfer, playingFeedTrack, playingFeedTrackIndex} = this.props;
    return playingFeedWavesurfer &&
      playingFeedTrack.trackId === track.trackId &&
      playingFeedPlaylist.playListId === playlist.playListId &&
      playingFeedTrackIndex === trackIndex;
  }

  isTrackActive(track, trackIndex) {
    return this.isTrackSelected(track, trackIndex) && this.props.isPlayingFeedTrackActive;
  }

  initPlayer(trackPeaks, container) {
    if (container) {
      if (wsUtils.wavesurferDestroyed(this.wavesurfer)) {
        this.wavesurfer = null;
      }

      this.wavesurfer = WaveSurfer.create(wsUtils.getWavesurferInitOptions(container, 90, 'MediaElement'));
      if (trackPeaks) {
        this.wavesurfer.backend.peaks = trackPeaks;
      }
      this.wavesurfer.drawBuffer();
      this.wavesurfer.on("ready", () => {
        if(!this.wavesurfer.loaded && !wsUtils.wavesurferDestroyed(this.wavesurfer)) {
          this.wavesurfer.loaded = true;
          this.props.toggleLoader(false);
        }
      });

      this.wavesurfer.on('destroy', () => {
        this.props.stopPlayingFeedTrack();
      });
      this.wavesurfer.on('pause', () => {
        this.props.stopPlayingFeedTrack();
      });

      this.wavesurfer.container.addEventListener('mouseenter', (e) => {
        if (!wsUtils.wavesurferDestroyed(this.wavesurfer)) {
          this.wavesurfer.params.progressColor = wsUtils.generateWaveColor(0.8);
          this.wavesurfer.drawBuffer();
        }
      });

      this.wavesurfer.container.addEventListener('mouseleave', (e) => {
        if (!wsUtils.wavesurferDestroyed(this.wavesurfer)) {
          this.wavesurfer.params.progressColor = wsUtils.generateWaveColor(1);
          this.wavesurfer.drawBuffer();
        }
      });

      this.wavesurfer.on('audioprocess',  () => {
        this.props.updateCurrentTimeForPlayingFeedTrack( wsUtils.formatTime(this.wavesurfer.getCurrentTime()) );
      });

      this.wavesurfer.on('seek',  () => {
        this.props.updateCurrentTimeForPlayingFeedTrack( wsUtils.formatTime(this.wavesurfer.getCurrentTime()) );
      });
    }
  }

  tracksChangedInGeneralPlaylist(newTrack, newPlaylist, trackIndex) {
    const {playingFeedTrack, playingFeedPlaylist, playingFeedTrackIndex} = this.props;
    return playingFeedTrack.trackId &&
      (newTrack.trackId !== playingFeedTrack.trackId || trackIndex !== playingFeedTrackIndex) &&
        playingFeedPlaylist.playListId === newPlaylist.playListId;
  }

  pauseClickHandler() {
    const {playingFeedWavesurfer} = this.props;
    playingFeedWavesurfer.pause();
    this.props.stopPlayingFeedTrack();
  }

  reinitPlayer(newTrack, newPlaylist) {
    const {peaks} = this.props;
    let container = wsUtils.getFeedPlaylistWaveContainer(newPlaylist);
    this.initPlayer(peaks[newTrack.trackId], container);
  }

  play(newTrack, newPlaylist, trackIndex) {
    const {playingFeedWavesurfer,
      playingWavesurfer,
      peaks,
      playbackStatistic,
      toggleLoader,
      startPlayingFeedTrack,
      stopPlayingFeedTrack,
      playingFeedPlaylist,
      isPlayingFeedTrackActive} = this.props;

    //If header player from tracks list is playing in the moment
    if (playingWavesurfer) {
      tracksUtils.stopPlayer(playingWavesurfer);
    }

    //If feed track is playing in the moment
    if (isPlayingFeedTrackActive) {
      tracksUtils.stopPlayer(playingFeedWavesurfer);
    }

    //Tracks changed in different playlist
    if (playingFeedPlaylist.playListId !== newPlaylist.playListId) {
      // Selected playlist was already active
      if (this.wavesurfer.loaded) {
        tracksUtils.clearPlayer(this.wavesurfer);
        this.reinitPlayer(newTrack, newPlaylist);
      }
    }

    //Tracks changed in general playlist
    if (this.tracksChangedInGeneralPlaylist(newTrack, newPlaylist, trackIndex)) {
      tracksUtils.clearPlayer(playingFeedWavesurfer);
      this.reinitPlayer(newTrack, newPlaylist);
    }

    if (!this.wavesurfer.loaded) {
      NProgress.start();
      toggleLoader(true);
      startPlayingFeedTrack(newTrack, this.wavesurfer, newPlaylist, trackIndex);
      const audio = trackUrl(newTrack.audioFile);
      // wsUtils.loadWavesurfer(this.wavesurfer, audio, peaks[newTrack.trackId]);
      this.wavesurfer.load(audio, peaks[newTrack.trackId]);
      wsUtils.setPeaks(this.wavesurfer, peaks[newTrack.trackId]);
      playbackStatistic(newTrack.fileHash);
      this.wavesurfer.on('play', () => {
        NProgress.done();
        startPlayingFeedTrack(newTrack, this.wavesurfer, newPlaylist, trackIndex);
        this.loadNextTracks(newTrack);
      });

      this.wavesurfer.on('finish', () => {
        stopPlayingFeedTrack();
        this.onTrackStopCallback(newTrack);
      });
    }
    this.wavesurfer.play();
  }

  isCurrentPlaylistTrackPlaying() {
    const {playlist, playingFeedPlaylist, playingFeedWavesurfer, isPlayingFeedTrackActive} = this.props;
    return playingFeedPlaylist.playListId === playlist.playListId && playingFeedWavesurfer && isPlayingFeedTrackActive;
  }

  isCurrentPlaylistTrackPaused() {
    const {playlist, playingFeedPlaylist, playingFeedWavesurfer, isPlayingFeedTrackActive} = this.props;
    return playingFeedPlaylist.playListId === playlist.playListId && playingFeedWavesurfer && !isPlayingFeedTrackActive;
  }

  isCurrentPlaylistTrackSelected() {
    return this.isCurrentPlaylistTrackPlaying() || this.isCurrentPlaylistTrackPaused();
  }

  removePlaylistFromFeedHandler(playlist, activeFeed) {
    const {playingFeedWavesurfer, removePlaylistFromFeed, clearPlayingFeedTrack} = this.props;
    if (this.isCurrentPlaylistTrackSelected()) {
      tracksUtils.clearPlayer(playingFeedWavesurfer);
      clearPlayingFeedTrack();
    }
    removePlaylistFromFeed(playlist, activeFeed);
  }

  render() {
    const {playlist,
      playingFeedTrack,
      toggleCollapsePlaylist,
      activeFeed,
      toggleSwitchOffPlaylist,
      toggleRandomPlaylist,
      collapsedPlaylists,
      fetchTrackPeaks,
      playingFeedTrackIndex
    } = this.props;

    const {playlistPositionValue} = this.state;

    return (
      <div className={"playlist-wrap feed__playlist" + (!playlist.active ? " playlist-wrap_disabled" : '')} id={"playlist-" + playlist.playListId}>
        <div className="playlist">
          <FeedPlaylistHeader playlist={playlist}
                              collapsedPlaylists={collapsedPlaylists}
                              toggleCollapsePlaylist={toggleCollapsePlaylist}
                              activeFeed={activeFeed}
                              pauseClickHandler={this.pauseClickHandler}
                              playClickHandler={this.play}
                              playingFeedTrack={playingFeedTrack}
                              playingFeedTrackIndex={playingFeedTrackIndex}
                              toggleSwitchOffPlaylist={toggleSwitchOffPlaylist}
                              toggleRandomPlaylist={toggleRandomPlaylist}
                              isCurrentPlaylistTrackPlaying={this.isCurrentPlaylistTrackPlaying}
          />
          <div className="feed-playlist-body">
            <div className="track-list">
              {playlist.tracks && playlist.tracks.length > 0 &&
                <FeedPlaylistPlayer playlist={playlist} playingFeedTrack={playingFeedTrack}
                                    playingFeedTrackIndex={playingFeedTrackIndex}
                                    pauseClickHandler={this.pauseClickHandler} play={this.play}
                                    isCurrentPlaylistTrackPlaying={this.isCurrentPlaylistTrackPlaying}
                                    isCurrentPlaylistTrackPaused={this.isCurrentPlaylistTrackPaused}
                                    isCurrentPlaylistTrackSelected={this.isCurrentPlaylistTrackSelected}
                />
              }
            </div>
            <div className={"feed-playlist-collapsable " + (collapsedPlaylists.includes(playlist.playListId) ? '' : 'active')}>
              <div className="track-table playlist__table">
                <TracksTableHeader />
                <div className="playlist__table-inner">
                  {playlist.tracks && playlist.tracks.map((track, i) =>
                    <FeedTracksTableItem key={i} track={track}
                                          pause={this.pauseClickHandler}
                                          isTrackActive={this.isTrackActive}
                                          isTrackSelected={this.isTrackSelected}
                                          feedPlaylist={playlist}
                                          play={this.play}
                                          fetchTrackPeaks={fetchTrackPeaks}
                                          trackIndex={i}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="playlist__footer">
              <input className="playlist__position" type="text" name="position" value={playlistPositionValue}
                     onChange={this.changeFeedPlaylistPositionHandler} />позиция
              <a className="playlist__remove" onClick={this.removePlaylistFromFeedHandler.bind(this, playlist, activeFeed)}>Удалить</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({  playingFeedWavesurfer: state.feed.playingFeedWavesurfer,
  playingFeedTrack: state.feed.playingFeedTrack,
  peaks: state.catalog.peaks,
  peaksLength: state.catalog.peaksLength,
  isPlayingFeedTrackActive: state.feed.isPlayingFeedTrackActive,
  playingFeedPlaylist: state.feed.playingFeedPlaylist,
  playingWavesurfer: state.catalog.playingWavesurfer,
  activeFeed: state.feed.activeFeed,
  playingFeedTrackIndex: state.feed.playingFeedTrackIndex}), null, null, { withRef: true })(FeedPlaylist);





