import React, { Component } from 'react';
import withWavesurfer from '../Player/withWavesurfer';
import * as utils from "../../../utils/tracks";
import {ReactComponent as PlayIcon} from '../../../icons/play_circle_outline.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';

class TracklistPlayerWave extends Component {
  constructor(props, context) {
    super(props, context);
    this.playClickHandler = this.playClickHandler.bind(this);
    this.initTrackListPlayer = this.initTrackListPlayer.bind(this);
  }

  componentWillUnmount() {
    utils.clearPlayer(this.wavesurfer);
  }

  playClickHandler(track) {
    let {play, peaks} = this.props;
    play(track, null, peaks[track.trackId]);
  }

  initTrackListPlayer(elem) {
    this.props.initPlayer(this.props.peaks[this.props.track.trackId], elem);
  }

  render() {
    const {track,
      className,
      pause,
      isTrackActive,
      peaks,
      loading
    } = this.props;
    return (
      <span>
        <div className="player-wave-wrap">
          {  peaks[track.trackId] && peaks[track.trackId].length > 0 &&
            <div className={className} ref={this.initTrackListPlayer}>
            </div>
          }
          {
            loading &&
            <div className="player-wave-stub"></div>
          }
        </div>
        <div className="track-list__player-info">
          <div className="track-list__controls">
            { isTrackActive(track) &&
              <PauseIcon className="track-list__control-icon track-list__control-icon_pause svg-icon" onClick={pause} />
            }
            { !isTrackActive(track) &&
              <PlayIcon className="track-list__control-icon track-list__control-icon_play svg-icon"
                 onClick={this.playClickHandler.bind(this, track)} />
            }
          </div>
          <div className="track-list__track-title">
          <span className="track-list__title">{track.title}</span><br/>
          <span className="track-list__title">{track.artist}</span>
          </div>
        </div>
      </span>
    )
  }
}

export default withWavesurfer(TracklistPlayerWave, {height: 90, playerWidth: 720, source: utils.TRACK_SOURCE_LIST});


