import React from 'react';
import PropTypes from 'prop-types';

export default class LayoutTab extends React.Component {
  generateIcon() {
    if(this.props.iconClass == null) 
    {
      return;
    }

    return (
      <i className={`tab-icon ${this.props.iconClassName}`}/>
    );   
  }

  render() {
    return (
      <li className={`tabs__tab ${this.props.isActive ? 'active' : ''}`}
          onClick={(event) => {
            event.preventDefault();
            this.props.onClick(this.props.tabIndex);
          }}>
        <a className={`${this.props.linkClassName}`}>
            {this.props.linkName}
            {this.generateIcon()}            
        </a>
      </li>
    );
  }
};

LayoutTab.propTypes = {
  onClick      : PropTypes.func,
  tabIndex     : PropTypes.number,
  isActive     : PropTypes.bool,
  iconClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  linkName: PropTypes.string.isRequired
};