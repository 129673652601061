import React from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {createTicket, clearTicket} from "../../actions/users";

class TicketForm extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      message: '',
      ticketType: 6,
      header: 'Техподдержка'
    };

    this.createTicket = this.createTicket.bind(this);
    this.onHeaderChange = this.onHeaderChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
  }

  componentWillUnmount() {
    this.props.clearTicket();
  }

  createTicket(e) {
    e.preventDefault();
    const header = this.state.header;
    const msg = this.state.message;
    const ticketType = this.state.ticketType;
    if (msg && msg !== '' && header !== '' && ticketType !== null) {
      this.props.createTicket(header, msg, ticketType);
    }
  }

  onMessageChange(e) {
    this.setState({message: e.target.value});
  }

  onHeaderChange(e) {
    this.setState({header: e.target.value});
  }

  render() {
    const {ticket} = this.props;
    return (
      <div>
          {ticket &&
            <h1 className="heading-main">Мы уже работаем над Вашим запросом, номер тикета: {ticket.TicketId}</h1>
          }
          {!ticket &&
          <div className="ticket-header__container">
            <h1 className="heading-main">Создайте тикет, задайте свой вопрос</h1>
            <form className="help__form">
              <select onChange={this.onHeaderChange}
                      defaultValue={this.state.header}
                      className="input help__select">
                <option value="Техподдержка">Техподдержка</option>
                <option value="Финансовый отдел">Финансовый отдел</option>
                <option value="Музыкальный редактор">Музыкальный редактор</option>
              </select>
              <textarea value={this.state.message}
                        onChange={this.onMessageChange}
                        className="input help__textarea"
                        placeholder="Описание вопроса"/>
              <button className="button button_color_blue button_size_big help__send" onClick={this.createTicket}>
                <span>Отправить</span>
              </button>
            </form>
          </div>
          }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  createTicket: bindActionCreators(createTicket, dispatch),
  clearTicket: bindActionCreators(clearTicket, dispatch)
});

export default connect(state => ({ticket: state.user.ticket}),
  mapDispatchToProps)(TicketForm);
