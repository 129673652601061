import React, { Component } from 'react';

class RememberCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

  toggleCheckbox() {
    this.setState(prevState => ({
      isChecked: !prevState.isChecked,
    }));
  }

  render() {
    const { isChecked } = this.state;

    return (
      <div className="logged__row logged__remember">
        <input
          type="checkbox"
          className="logged__checkbox_input"
          checked={isChecked}
          onChange={this.toggleCheckbox}
        />
        <label className="logged__checkbox_label" onClick={this.toggleCheckbox}>
          Запомнить
        </label>
      </div>
    );
  }
}

export default RememberCheckbox;
