import React, { Component } from 'react';
import UrlHelpers from "../../../helpers/Url";
import withWavesurfer from "../Player/withWavesurfer";
import * as utils from "../../../utils/tracks";
import DragSource from "react-dnd/lib/DragSource";
import DropTarget from "react-dnd/lib/DropTarget";

import {ReactComponent as PlayIcon} from '../../../icons/play_arrow.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';
import {ReactComponent as CloseIcon} from '../../../icons/close.svg';
import {randomTrackImage} from '../../../utils/images';

class PlaylistTrack extends Component {

  constructor(props, context) {
    super(props, context);
    this.playClickHandler = this.playClickHandler.bind(this);
  }

  playClickHandler(track, index, e) {
    let {play, peaks, playbackStatistic} = this.props;

    playbackStatistic(track.fileHash);
    play(track, e.target, peaks[track.trackId], index);
  }

  render() {
    const { track,
            playlist,
            pause,
            isTrackActive,
            isContentTrackActive,
            removeTrackFromPlaylist,
            connectDragSource,
            connectDropTarget,
            index
    } = this.props;
    let imageFileFix;
    if (track.imageFile && track.imageFile !== undefined) {
      imageFileFix = track.imageFile.replace(".", "_small.");
    }
    const trackName = utils.trackName(track);
    return connectDragSource(
        connectDropTarget(
          <div className={`right-tracks__track ${isContentTrackActive(track) ? 'right-tracks__track__playing' : ''}`} key={track.trackId}>
            <div className="right-tracks__album">
              {
                track.imageFile && track.imageFile !== undefined &&
                <img className="card__image" src={UrlHelpers.baseAudioImg() + '/' + imageFileFix} alt="track"/>
              }

              {
                (!track.imageFile || track.imageFile === '') &&
                <img className="card__image" src={randomTrackImage['image_small']} alt="track"/>
              }
              {isContentTrackActive(track, index) &&
              <a className="right-tracks__album-button" onClick={pause} title={trackName}>
                <PauseIcon className="right-tracks__icon svg-icon"/>
              </a>
              }
              {!isContentTrackActive(track, index) &&
              <a className="right-tracks__album-button" title={trackName}
                 onClick={this.playClickHandler.bind(this, track, index)}>
                <PlayIcon className="right-tracks__icon svg-icon"/>
              </a>
              }
              <a className="right-tracks__album-button" title={trackName}
                 onClick={removeTrackFromPlaylist.bind(this, playlist, track)}>
                <CloseIcon className="right-tracks__icon svg-icon"/>
              </a>
            </div>
          </div>
    ))
  }
}

const trackDragSource = {
  beginDrag(props) {
    return {
      track: props.track,
      index: props.index
    };
  }
};

function getCollectTrack(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

const dropTarget = {
  drop(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (props.playlist) {
      let pls = props.playlist.trackPlayLists.slice(0);
      const dragTrack = props.playlist.trackPlayLists[dragIndex];
      pls.splice(dragIndex, 1);
      pls.splice(hoverIndex, 0, dragTrack);
      props.updatePlaylistTracks(props.playlist, pls);
    }
  }
};

function collectDropTrack(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}



const DraggableTrackItem = DragSource('sortable-track', trackDragSource, getCollectTrack)(PlaylistTrack);
const DroppableTrackItem =  DropTarget('sortable-track', dropTarget, collectDropTrack)(DraggableTrackItem);

export default withWavesurfer(DroppableTrackItem, {height: 90,
  waveContainerSelector: ".layout__header .player__equalizer", generalWave: true, source: utils.TRACK_SOURCE_PLAYLIST});
