import React, { Component } from 'react';

class TracksTableHeader extends Component {

  render() {
    return (
      <div className="track-table-item track-table-item_head">
                            <span className="track-table-item__text track-table-item__text_title">
                                <b>Название трека</b>
                            </span>
        <span className="track-table-item__text track-table-item__text_artist">
                                <b>Артист</b>
                            </span>
        <span className="track-table-item__text track-table-item__text_genre">
                                <b>Жанр</b>
                            </span>
        <span className="track-table-item__text track-table-item__text_time">
                                <b>Время</b>
                            </span>
        <span className="track-table-item__text">
                                <b>Популярность</b>
                            </span>
      </div>
    );
  }
}

export default TracksTableHeader;
