import React from 'react';
// import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import User from '../../helpers/User';
import connect from "react-redux/es/connect/connect";
import DayNight from './DayNight';
import '../../index.css';
import {bindActionCreators} from "redux";
import { toggleUserTheme, detectUserTheme } from '../../actions/users.js';

import {ReactComponent as AccountIcon} from '../../icons/account_circle.svg';

class AccountMenuItem extends React.Component
{
  componentWillMount() {
    if (!this.props.theme) {
      this.props.detectUserTheme();
    }
  }

  render() {
    const { theme, isGeneralHeaderFeedPlaying } = this.props;

    return (
      <div className="right-header"> 
        {
          theme &&
          <DayNight toggleTheme={this.props.toggleUserTheme} selectedTheme={theme} />
        }
        {
          this.props.isGeneralHeaderFeedPlaying &&
          <div className="general-feed-playing-stub transparent" />
        }
        { User.isLoggedin() && User.withPlace() &&
          <ul className="icon-menu right-header__menu">
              <li className="icon-menu__item">
                  <Link to="/account" className={"icon-menu__link " + (isGeneralHeaderFeedPlaying ? 'disabled' : '')}>
                      <AccountIcon className="icon-menu__icon svg-icon" />
                      <span className="icon-menu__text">Аккаунт</span>
                  </Link>
              </li>
          </ul>
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleUserTheme: bindActionCreators(toggleUserTheme, dispatch),
  detectUserTheme: bindActionCreators(detectUserTheme, dispatch)
});

export default connect(state => ({ 
  theme: state.user.theme, 
  isGeneralHeaderFeedPlaying: state.feed.isGeneralHeaderFeedPlaying
}), mapDispatchToProps)(AccountMenuItem);
