import React from 'react';
import { Route } from 'react-router';
import {Switch} from 'react-router-dom';
import UrlHelpers from './helpers/Url';
import EnvHelpers from './helpers/Env';
import Layout from './components/Layout';
import Account from './containers/AccountTabsContainer';
import SignIn from './containers/SignInFormContainer';
import SelectPlace from './containers/SelectPlaceFormContainer';
import Logout from './components/Logout';
import Help from './components/Account/Help';
import Tracks from './containers/TracksContainer';
import Compilations from './containers/CompilationsContainer';
import PlaylistRightSidebar from './containers/PlaylistRightSidebarContainer';
import {PrivateRoute} from './PrivateRoute';
import {PublicRoute} from './PublicRoute';
import HelpSidebar from './components/Account/HelpSidebar';
import HelpForm from './components/Account/HelpForm';
import Feed from './containers/FeedContainer';
import NotFound from './NotFound';
import FeedCalendarSidebar from "./containers/FeedCalendarSidebarContainer";
import Compilation from './components/Catalog/Compilations/Compilation';
const rollbar = require('rollbar');

window.APP_ENV = (UrlHelpers.isLocalhost() || UrlHelpers.isAspNetDev()) ? "development" : "production";

const rollbarConfig = {
  enabled: window.APP_ENV === 'production',
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: EnvHelpers.jsEnv()
  }
};

window.Rollbar = new rollbar(rollbarConfig);

export default () => (
  <Layout>
    <Switch>
      <PublicRoute path="/login" withLayout={false} components={{main: SignIn}} />
      <PublicRoute path="/place" withLayout={false} components={{main: SelectPlace}}/>
      <Route path='/logout' component={Logout} />
      <PrivateRoute path="/" exact={true} components={{main: Tracks, right: PlaylistRightSidebar}}/>
      <PrivateRoute path="/feed" components={{main: Feed, right: FeedCalendarSidebar}} />
      <PrivateRoute path="/account" components={{main: Account}}  />
      <PrivateRoute path="/compilations" components={{main: Compilations, right: PlaylistRightSidebar}} />
      <PrivateRoute path="/compilation/:id" components={{main: Compilation, right: PlaylistRightSidebar}} />
      <PrivateRoute path="/help" components={{main: Help, right: HelpSidebar, pageHeader: HelpForm}} />
      <PublicRoute path="*" components={{main: NotFound}} />
    </Switch>
  </Layout>
);
