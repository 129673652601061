import React, { Component } from 'react';

import {ReactComponent as PlayListAddIcon} from '../../../icons/playlist_add.svg';

class EmptyPlaylists extends Component {


  render() {
    return (
      <div className="playlist-info">
          <h3 className="playlist-info__heading"> </h3>
          <p className="playlist-info__hint">Выберите плейлист, в который собираетесь добавлять музыкальные композиции.
              <br /> Для добавления выберите трек и нажмите на иконку добавления в плейлист или перетащите композицию в правую область.</p>
          <div className="no-playlist playlist-info__no-playlist">
              <p className="no-playlist__heading">Вы не создали еще ни одного плейлиста</p>
              <a className="no-playlist__icon" onClick={this.props.createPlaylist} ><PlayListAddIcon className="svg-icon" /></a>
              <p className="no-playlist__bot-text">Нажмите на кнопку «создать плейлист»</p>
          </div>
      </div>
    )
  }
}

export default EmptyPlaylists;
