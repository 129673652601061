import React from 'react';
import Accordion from '../Layout/Accordion.js';

export default class Help extends React.Component {
  render() {
    return (
      <span>
        <Accordion header="Основные разделы">
          <div title="Треки">
            <p>Раздел Сервиса в котором представлены все музыкальные произведения.<br/>
              Треки можно сортировать по нескольким выбранным фильтрам, в том числе по типу предприятия, музыкальным жанрам или по новизне,  также доступны три варианта визуального отображения.</p>

            <p>Кнопка добавления трека в плейлист «плюсик» активируется после того, как вы создадите первый плейлист или выберете готовый в одноименном разделе.</p>
          </div>
          <div title="Плейлисты">
            <p>Раздел расположен в правой части экрана и позволяет создавать/управлять плейлистами предприятия и подготовить их для добавления в ленту.</p>
          </div>
          <div title="Лента">
            <p>Основной раздел сервиса, в котором можно выстроить порядок воспроизведения плейлистов.  Лент можно создавать неограниченное количество. Выстраивайте персональную сетку вещания вашего предприятия для каждого дня в году отдельно, установите нужную последовательность проигрывания плейлистов в каждой ленте и присвойте ленте статус для «будних дней», «выходных» или для определенного дня в календаре.</p>
          </div>
          <div title="Подборки">
            <p>Готовые плейлисты от музыкальной редакции сервиса.  Оказавшись в нужной подборке можно ознакомиться с представленными в ней треками, выбрать или создать новый плейлист для добавления нужных треков либо перенести все треки из готовой подборки в новый или текущий плейлист одним нажатием. Далее из раздела «Треки» либо из другой «Подборки» вы сможете дополнить нужный плейлист новыми треками.</p>
          </div>
          <div title="Аккаунт">
            <p>В разделе вы сможете установить новый пароль, ознакомиться с первичной бухгалтерской документацией, если вы уже наш клиент либо загрузить сведения о компании для заключения лицензионного соглашения, если вам предоставлен тестовый доступ.</p>
          </div>
        </Accordion>

        <Accordion header="Начало работы">
          <div title="Автоматизация">
            <p>Для запуска подходящей фоновой музыки на предприятии понадобятся некоторые действия в личном кабинете. Сервис позволяет пользователю запустить фоновую музыку как за 10 минут, так и детально проработать сетку вещания с привязкой к конкретным датам, дням недели. В основе лежат плейлисты, которые вы можете создавать из треков в каталоге в неограниченном количестве. После создания, плейлисты будут доступны для добавления в первую или нужные Ленты.  Таким образом для автоматизации фоновой музыки следующий порядок действий:</p>
            <ul>
            <li>выбор нужных треков и создание плейлистов</li>
            <li>добавление плейлиста/ов в Ленту.</li>
            <li>создание нужного количества Лент.</li>
            <li>присвоение Лент дням недели в календаре.</li>
            <li>запуск фоновой музыки.</li>
            </ul>
          </div>
          <div title="Создание плейлиста">
            <p>Для создания первого плейлиста перейдите в раздел «Треки» или «Подборки» и справа нажмите на «плюсик» Введите название вашего плейлиста и назначьте время суток, в которое хотели бы его проигрывать.<br/>Теперь в плейлист можно добавлять треки или перетаскивать их из каталога.</p>
          </div>
          <div title="Быстрый запуск за 10 минут">
            <ul>
            <li>Перейдите в раздел «Подборки», выберите нужную и нажмите «Создать плейлист из подборки».</li>
            <li>Перейдите в раздел лента и выберите созданный плейлист.</li>
            </ul>
            <p>Вы можете проделать данную операция несколько раз с разными подборками и сформировать в ленте сетку с плейлистами. Они будут проигрываться в установленном порядке.</p>
          </div>
          <div title="Детальная настройка сетки вещания">
            <p>Принцип такой же как и при быстром запуске, за исключением того, что вы можете потратить больше времени на прослушивание треков и проработку плейлиста/ов, в разделе «Треки» , а также  настроить нужное количество лент, в зависимости от пожеланий по сетке вещания.</p>
          </div>
          <div title="Поддержка">
            <p>В случае необходимости и возникновения сложностей, Вы всегда можете обратиться к сотрудникам службы поддержки через форму обратной связи. Наши специалисты Музыкальной редакции  будут рады помочь Вам разработать оптимальные для Вашего типа предприятия плейлисты, выстроить сетку  и проконтролировать запуск  фоновой музыки под ключ.</p>
          </div>
        </Accordion>
      </span>
    );
  }
};


