import React, { Component } from 'react';
import StarsRating from '../../StarsRating.js';
import * as utils from "../../../utils/tracks";
import {renderGenres} from "../../../utils/tracks";

import {ReactComponent as PlayIcon} from '../../../icons/play_circle_outline.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';

class FeedTracksTableItem extends Component {
  render() {
    const {pause,
          track,
          isTrackActive,
          isTrackSelected,
          feedPlaylist,
          play,
          trackIndex
    } = this.props;
    const {title, artist, genreTracks, duration, rating} = this.props.track;

    return (
      <div className={"track-table-item " +
      (isTrackSelected(track, trackIndex) ? 'track-table-item_active' : '')}>
        <span className="track-table-item__text track-table-item__text_title">{title}</span>
        <span className="track-table-item__text track-table-item__text_artist">{artist}</span>
        <span className="track-table-item__text track-table-item__text_genre">
          {renderGenres(genreTracks)}
        </span>
        <span className="track-table-item__text track-table-item__text_time">{utils.renderTrackDuration(duration)}</span>

        <StarsRating rating={rating} className="track-table-item__rating"/>
        <div className="track-table-item__controls">
          { isTrackActive(track, trackIndex) &&
          <PauseIcon className="track-table-item__control-icon
                              track-table-item__control-icon_active
                              svg-icon"
                     onClick={pause} />
          }
          { !isTrackActive(track, trackIndex) &&
          <PlayIcon className="track-table-item__control-icon
                  track-table-item__control-icon_play
                  track-table-item__control-icon_active
                  svg-icon"
                    onClick={play.bind(this, track, feedPlaylist, trackIndex)} />
          }
        </div>
      </div>
    )
  }
}

export default FeedTracksTableItem;


