import {Component} from "react";
import React from "react";

import {ReactComponent as PowerIcon} from '../../../icons/power_settings_new.svg';
import {ReactComponent as ArrowUpIcon} from '../../../icons/keyboard_arrow_up.svg';
import {ReactComponent as ArrowDownIcon} from '../../../icons/keyboard_arrow_down.svg';

class FeedPlaylistHeader extends Component {

  constructor(props, context) {
    super(props, context);

    this.isPlaylistActiveAndExpanded = this.isPlaylistActiveAndExpanded.bind(this);
    this.isPlaylistNotActiveAndCollapsed = this.isPlaylistNotActiveAndCollapsed.bind(this);
  }

  toggleRandomPlaylist(activeFeed, playlist) {
    let isPlay = this.props.isCurrentPlaylistTrackPlaying();
    if (isPlay) {
      this.props.pauseClickHandler();
    }
    this.props.toggleRandomPlaylist(activeFeed, playlist);
    if (isPlay) {
      this.props.playbackStatistic(this.props.playingFeedTrack.fileHash);
      this.props.playClickHandler(this.props.playingFeedTrack, this.props.playlist, this.props.playingFeedTrackIndex);
    }
  }

  isPlaylistActiveAndExpanded(playlist) {
    return playlist.active && !this.props.collapsedPlaylists.includes(playlist.playListId);
  }

  isPlaylistNotActiveAndCollapsed(playlist) {
    return !playlist.active && this.props.collapsedPlaylists.includes(playlist.playListId);
  }

  toggleSwitchOffPlaylist(activeFeed, playlist) {
    if (this.props.isCurrentPlaylistTrackPlaying()) {
      this.props.pauseClickHandler();
    }

    if (this.isPlaylistActiveAndExpanded(playlist) || this.isPlaylistNotActiveAndCollapsed(playlist)) {
      this.props.toggleCollapsePlaylist(playlist.playListId);
    }

    this.props.toggleSwitchOffPlaylist(activeFeed, playlist);
  }

  render () {
    const {playlist, toggleCollapsePlaylist, collapsedPlaylists, activeFeed} = this.props;
    return (
      <div className="playlist__heading">
        {
          playlist.active &&
          <div className="playlist__turn" onClick={this.toggleSwitchOffPlaylist.bind(this, activeFeed, playlist)}>
            <PowerIcon className="playlist__icon-turn svg-icon" />
            <span className="playlist__head-text playlist__head-text_semibold">Выключить</span>
          </div>
        }
        {
          !playlist.active &&
          <div className="playlist__turn playlist__turn_on" onClick={this.toggleSwitchOffPlaylist.bind(this, activeFeed, playlist)}>
            <PowerIcon className="playlist__icon-turn svg-icon" />
            <span className="playlist__head-text playlist__head-text_semibold">Включить</span>
          </div>
        }

        <div className={(playlist.shuffle ? "playlist__shuffle_active " : "") + " playlist__shuffle"}
             onClick={this.toggleRandomPlaylist.bind(this, activeFeed, playlist)}
        >
          <span className="playlist__head-text playlist__head-text_semibold">Воспроизводить случайно</span>
        </div>
        <div className="playlist__view" onClick={toggleCollapsePlaylist.bind(this, playlist.playListId)}>
          {
            !collapsedPlaylists.includes(playlist.playListId) &&
            <span>
              <span className="playlist__head-text">Свернуть</span>
              <ArrowUpIcon className="playlist__icon-feed svg-icon" />
            </span>
          }

          {
            collapsedPlaylists.includes(playlist.playListId) &&
            <span>
              <span className="playlist__head-text">Развернуть</span>
              <ArrowDownIcon className="playlist__icon-feed svg-icon" />
            </span>
          }

        </div>
      </div>
    );
  }
}

export default FeedPlaylistHeader;
