import Account from '../components/Account.js';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {fetchUserFullInfo} from "../actions/users";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserFullInfo: bindActionCreators(fetchUserFullInfo, dispatch)
  };
};

function mapStateToProps(state, ownProps) {
  return {
    userInfo: state.user.userInfo
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
