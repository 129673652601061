import React from 'react';

import { ReactComponent as PdfIcon} from '../../icons/picture_as_pdf.svg';

export default class AccountTabContent2 extends React.Component
{
  render() {
    return (
        <div className="table">
          <div className="table__row">
            <div className="table__col table__col_width_140">
              <p className="text-small">
                <b>Дата</b>
              </p>
            </div>
            <div className="table__col">
              <p className="text-small">
                <b>Наименование</b>
              </p>
            </div>
            <div className="table__col table__col_width_60 table__col_align_right">
              <p className="text-small">
                <b>Скачать</b>
              </p>
            </div>
          </div>
          <div className="table__row">
            <div className="table__col table__col_width_140">
              <p className="text-small">20.06.2016</p>
            </div>
            <div className="table__col">
              <p className="text-small">
              Счет №123123 на оплату услуг за май
              </p>
            </div>
            <div className="table__col table__col_width_60 table__col_align_right">
              <a className="table__pdf" href="#pdf"><PdfIcon className="svg-icon"/></a>
            </div>
          </div>
          <div className="table__row">
            <div className="table__col table__col_width_140">
              <p className="text-small">20.06.2016</p>
            </div>
            <div className="table__col">
              <p className="text-small">
              Счет №123123 на оплату услуг за май
              </p>
            </div>
            <div className="table__col table__col_width_60 table__col_align_right">
              <a className="table__pdf" href="#pdf" role="button"><PdfIcon className="svg-icon"/></a>
            </div>
          </div>
          <div className="table__row">
            <div className="table__col table__col_width_140">
              <p className="text-small">20.06.2016</p>
            </div>
            <div className="table__col">
              <p className="text-small">
              Счет №123123 на оплату услуг за май
              </p>
            </div>
            <div className="table__col table__col_width_60 table__col_align_right">
              <a className="table__pdf" href="#pdf"><PdfIcon className="svg-icon"/></a>
            </div>
          </div>
          <div className="table__row">
            <div className="table__col table__col_width_140">
              <p className="text-small">20.06.2016</p>
            </div>
            <div className="table__col">
              <p className="text-small">
              Счет №123123 на оплату услуг за май
              </p>
            </div>
            <div className="table__col table__col_width_60 table__col_align_right">
              <a className="table__pdf" href="#pdf"><PdfIcon className="svg-icon"/></a>
            </div>
          </div>
      </div>
    );
  }
};
