import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as utils from '../../../utils/tracks';
import {
  startPlayingTrack,
  stopPlayingTrack,
  addTrackToPlaylist,
  removeTrackFromPlaylist
} from "../../../actions/catalog";
import UrlHelpers from "../../../helpers/Url";
import {bindActionCreators} from "redux";
import DurationHeaderPlayer from "./DurationHeaderPlayer";

import {ReactComponent as PlayIcon} from '../../../icons/play_circle_outline.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';
import {ReactComponent as PlayListAddIcon} from '../../../icons/playlist_add.svg';
import {ReactComponent as CloseIcon} from '../../../icons/close.svg';
import {randomTrackImage} from '../../../utils/images';

class HeaderPlayer extends Component {

  constructor(props, context) {
    super(props, context);
    this.playClickHandler = this.playClickHandler.bind(this);
    this.pauseClickHandler = this.pauseClickHandler.bind(this);
    this.addToPlayListClickHandler = this.addToPlayListClickHandler.bind(this);
    this.removeFromPlayListClickHandler = this.removeFromPlayListClickHandler.bind(this);
    this.state = {
      trackId: null,
      hasTrack: false,
      playlistChangeDate: new Date()
    };
  }

  playClickHandler() {
    const {playingWavesurfer, playingTrack, playingTrackSource, playingTrackIndex, isPlayingFeedTrackActive, playingFeedWavesurfer} = this.props;

    //If feed track is playing in the moment
    if (isPlayingFeedTrackActive) {
      utils.stopPlayer(playingFeedWavesurfer);
    }

    playingWavesurfer.play();
    this.props.startPlayingTrack(playingTrack, playingWavesurfer, playingTrackSource, playingTrackIndex);
  }

  pauseClickHandler() {
    const {playingWavesurfer} = this.props;
    playingWavesurfer.pause();
    this.props.stopPlayingTrack();
  }

  addToPlayListClickHandler() {
    const {playlist, playingTrack} = this.props;
    this.props.addTrackToPlaylist(playlist, playingTrack);
  }

  removeFromPlayListClickHandler() {
    const {playlist, playingTrack} = this.props;
    this.props.removeTrackFromPlaylist(playlist, playingTrack);
  }

  render() {
    const {playingTrack, playingWavesurfer, playlist, isPlayingTrackActive,
           loading} = this.props;

    let imageFileFix;
    if (playingTrack && playingTrack.imageFile && playingTrack.imageFile !== '') {
      imageFileFix = playingTrack.imageFile.replace(".", "_mini.");
    }

    let {trackId, hasTrack, playlistChangeDate} = this.state;

    if (playlist && playlist.changeDate && playingTrack && playingTrack.trackId && !(trackId === playingTrack.trackId && playlistChangeDate === playlist.changeDate)) {
      hasTrack = playlist && playingTrack && utils.hasTrack(playingTrack.trackId, playlist.trackPlayLists);
      setTimeout(() => this.setState({trackId: playingTrack.trackId, playlistChangeDate: playlist.changeDate, hasTrack: hasTrack}), 10);
    }

    return (
      <div className="player">
        <div className={playingWavesurfer && playingWavesurfer.generalWave ? "player__image" : "hidden player__image"} >
          {playingWavesurfer && playingWavesurfer.generalWave &&
            <span>
              {playingTrack && playingTrack.imageFile && true &&
                <img src={UrlHelpers.baseAudioImg() + '/' + imageFileFix} alt="track"  />
              }

              {
                (!playingTrack || !playingTrack.imageFile || playingTrack.imageFile === '') &&
                <img src={randomTrackImage['image_small']} alt="track"/>
              }
            </span>
          }
        </div>

        <div className="player__content">
          {playingWavesurfer && playingWavesurfer.generalWave &&
            <div className="player__info">
              <div className="player__controls">
                {playingWavesurfer && isPlayingTrackActive &&
                  <PauseIcon className="player__control-icon svg-icon"
                             onClick={this.pauseClickHandler} />
                }
                {(!playingWavesurfer || !isPlayingTrackActive) &&
                  <PlayIcon className="player__control-icon player__control-icon_play svg-icon"
                            onClick={this.playClickHandler} />
                }
              </div>
              <span className="player__name">{playingTrack.title} - {playingTrack.artist}</span>
              {
                playlist && hasTrack &&
                <div className="player__list-controls">
                  <CloseIcon className="player__list-add svg-icon"
                              onClick={this.removeFromPlayListClickHandler} />
                </div>
              }
              {
                playlist && !hasTrack &&
                <div className="player__list-controls">
                  <PlayListAddIcon className="player__list-add svg-icon"
                                   onClick={this.addToPlayListClickHandler} />
                </div>
              }
            </div>
          }
          <DurationHeaderPlayer />
          <div className="player-wave-wrap">
            {
              loading &&
              <div className="player-wave-stub"></div>
            }
            <div className="player__equalizer"/>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  startPlayingTrack: bindActionCreators(startPlayingTrack, dispatch),
  stopPlayingTrack: bindActionCreators(stopPlayingTrack, dispatch),
  addTrackToPlaylist: bindActionCreators(addTrackToPlaylist, dispatch),
  removeTrackFromPlaylist: bindActionCreators(removeTrackFromPlaylist, dispatch)
});

export default connect(state => ({ playingTrack: state.catalog.playingTrack,
                                    playingTrackSource: state.catalog.playingTrackSource,
                                    playingTrackIndex: state.catalog.playingTrackIndex,
                                    playingWavesurfer: state.catalog.playingWavesurfer,
                                    playingFeedWavesurfer: state.feed.playingFeedWavesurfer,
                                    playlist: state.catalog.playlist,
                                    isPlayingTrackActive: state.catalog.isPlayingTrackActive,
                                    isPlayingFeedTrackActive: state.feed.isPlayingFeedTrackActive,
                                    loading: state.catalog.loading}),
                      mapDispatchToProps)(HeaderPlayer);
