import React from 'react';
import IconMenuItem from './IconMenuItem.js';

import {ReactComponent as PlayListPlayIcon} from '../../icons/playlist_play.svg';
import {ReactComponent as LowPriorityIcon} from '../../icons/low_priority.svg';
import {ReactComponent as SettingsIcon} from '../../icons/settings_input_antenna.svg';
import {ReactComponent as HelpIcon} from '../../icons/help_outline.svg';
import {ReactComponent as ExitIcon} from '../../icons/exit_to_app.svg';

export default class IconMenu extends React.Component
{
  render() {
    return (
      <span>
        <ul className="icon-menu sidebar__menu">
          <IconMenuItem name="Треки" isActive={window.location.pathname === "/"} link="/">
            <PlayListPlayIcon className="icon-menu__icon svg-icon" />
          </IconMenuItem>
          <IconMenuItem name="Лента" isActive={window.location.pathname === "/feed"} link="/feed">
            <LowPriorityIcon className="icon-menu__icon svg-icon" />
          </IconMenuItem>
          <IconMenuItem name="Подборки" isActive={window.location.pathname === "/compilations"} link="/compilations">
            <SettingsIcon className="icon-menu__icon svg-icon" />
          </IconMenuItem>
          <IconMenuItem name="Помощь" isActive={window.location.pathname === "/help"} link="/help">
            <HelpIcon className="icon-menu__icon svg-icon" />
          </IconMenuItem>
          <IconMenuItem name="Выйти" isActive={false} link="/logout">
            <ExitIcon className="icon-menu__icon svg-icon" />
          </IconMenuItem>
        </ul>
      </span>
    );
  }
};


