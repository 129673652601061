import React from 'react';
import { connect } from 'react-redux';
import {DragDropContext} from "react-dnd";
import ReactDnDHTML5Backend from "react-dnd-html5-backend";
import UserHelpers from "../helpers/User";
import AlertTemplate from './AlertTemplate';

import {bindActionCreators} from "redux";
import { detectUserTheme, toggleUserTheme } from '../actions/users.js';

import Alert from 'react-s-alert';

const mapDispatchToProps = (dispatch) => {
  return {
   detectUserTheme: bindActionCreators(detectUserTheme, dispatch),
   toggleUserTheme: bindActionCreators(toggleUserTheme, dispatch),
   isLoggedin: () =>{
     const token = UserHelpers.getAccessToken();
     return token && token !== '';
   },
   withPlace: () =>{
     const place = UserHelpers.fetchUserPlace();
     const user = UserHelpers.fetchUserInfoFromToken();
     return place && place !== '' && user && place['userId'] === user['id'];
   }
  };
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.user,
    theme: state.user.theme,
    playingTrack: state.catalog.playingTrack,
    playingWavesurfer: state.catalog.playingWavesurfer,
    playingFeedWavesurfer: state.feed.playingFeedWavesurfer,
    isGeneralHeaderFeedPlaying: state.feed.isGeneralHeaderFeedPlaying
  };
}

class Layout extends React.Component {
  componentWillMount () {
    this.props.detectUserTheme();
  }

  render() {
    return (
      <span>
        <span>
          {this.props.children}
        </span>
        <Alert stack={{limit: 3}} contentTemplate={AlertTemplate} position='top-right'  effect='stackslide' />
      </span>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DragDropContext(ReactDnDHTML5Backend)(Layout));
