import * as moment from "moment";

export function toLocaleDateString(date) {
  return moment.utc(date).local().format("DD/MM/YYYY");
}

export function formatDate(date) {
  if (!date) return '';

  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()
  const year = date.getFullYear()
  if (month.length < 2) {
      month = '0' + month
  }
  if (day.length < 2) {
      day = '0' + day
  }
  return [ day, month, year ].join('/');
}
