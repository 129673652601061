import React, { Component } from 'react';
import PropTypes from "prop-types";
import { reduxForm, Field } from 'redux-form';
import renderField from './renderField';
import { signInUser, signInUserSuccess, signInUserFailure } from '../actions/users';
import {api} from '../index';
import UserHelpers from "../helpers/User";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {
  clearPlayingTrack
} from "../actions/catalog";
import {
  clearPlayingFeedTrack
} from "../actions/feed";
import NProgress from "nprogress";
import StoreButton from './Layout/StoreButton';
import RememberCheckbox from './Layout/signin/RememberCheckbox';


function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.username || values.username.trim() === '') {
    errors.username = 'Enter username';
    hasErrors = true;
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'Enter password';
    hasErrors = true;
  }
  return hasErrors && errors;
}

const validateAndSignInUser = (values, dispatch) => {
  return dispatch(signInUser(values)).then((result) => {
        if (result.body.role === 'Owner') {
          return dispatch(signInUserFailure({
            response: {
              body: {
                errors: [ {errorMessage: "Access restricted!"}]
              }
            }
          }));
        }

        const Mubicon = require('@mubicon/api');
        const oAuth = new Mubicon.OAuth();
        oAuth.accessToken = result.body.authToken;
        api.setDefaultAuthentication(oAuth);

        UserHelpers.setAccessToken(result.body.authToken, result.body.expiresIn);
        return dispatch(signInUserSuccess(result.body));
      }).catch((error) => {
          return dispatch(signInUserFailure(error));
      });
};


class SignInForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    NProgress.done();
    this.props.clearPlayingFeedTrack(this.props.playingFeedWavesurfer);
    this.props.clearPlayingTrack(this.props.playingWavesurfer);
  }

  componentWillReceiveProps(nextProps) {
    let token = UserHelpers.getAccessToken();
    let authenticated = token && token !== '';

    if (authenticated && !nextProps.user.error) {
      this.context.router.history.push('/');
    }
  }

  render() {
    const {handleSubmit, submitting, user, invalid} = this.props;

    return (
      <div className="container__sign-in">
        <div className="container_center">
          <form className="input__logged logged" onSubmit={handleSubmit(validateAndSignInUser)}>
            <div className="logged__inner">
              <div className="logged__item logged__item--left">
                <div className="logged__entered">Введите данные для входа</div>
                <div className="logged__row">
                  {user.error && <div className="alert">{user.error.login_failure}</div>}

                  <Field
                    name="username"
                    type="text"
                    component={renderField}
                    label="Имя пользователя"
                    // placeholder="Введите Ваш Логин"
                  />
                </div>
                <div className="logged__row">
                  {user.error && <div className="alert">{user.error.Login}</div>}

                  <Field
                    name="password"
                    type="password"
                    component={renderField}
                    label="Пароль"
                    // placeholder="*********"
                  />
                  {user.error && <div className="alert">{user.error.Password}</div>}
                </div>
                <RememberCheckbox />
                <div className="logged__row container__sign-in_submit">
                  <button disabled={submitting || invalid} className="btn" type="submit">Войти</button>
                </div>
              </div>
              <div className="logged__item logged__item--right">
                <h2>У вас еще нет доступа?</h2>
                <a href="https://mubicloud.ru/request/" rel="noopener noreferrer">
                  Получить тестовый или полный доступ
                </a>
                <div className="img" />
              </div>
            </div>
          </form>
          <div className="container__signin-apps">
            <StoreButton type="rustore"/>
            <StoreButton type="google"/>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearPlayingFeedTrack: bindActionCreators(clearPlayingFeedTrack, dispatch),
  clearPlayingTrack: bindActionCreators(clearPlayingTrack, dispatch)
});

export default connect(state => ({  playingWavesurfer: state.catalog.playingWavesurfer,
  playingFeedWavesurfer: state.feed.playingFeedWavesurfer}), mapDispatchToProps)(reduxForm({
  form: 'SignInForm',
  validate
})(SignInForm));

