import UserHelpers from "../helpers/User";

const Mubicon = require('@mubicon/api');

export default function mubicloudAPI(api) {
    const oAuth = new Mubicon.OAuth();
    oAuth.accessToken = UserHelpers.getAccessToken();
    api.setDefaultAuthentication(oAuth);

    const requestWrapper = async (method, ...args) => {
        try {
            const response = await api[method](...args);
            return response;
        } catch (error) {
            if (error.response && (error.response.statusCode === 403 || error.response.statusCode === 401)) {
                UserHelpers.removeAccessToken();
                window.location = '/';
            }

            throw error;
        }
    };

    return new Proxy(api, {
        get(target, prop) {
            return (...args) => requestWrapper(prop, ...args);
        }
    });
}