import React from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './AccordionItem.js';

import {ReactComponent as MenuIcon} from '../../icons/menu.svg';

export default class Accordion extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
          activeItemIndex: -1
        };
        this.handleAccordionItemClick = this.handleAccordionItemClick.bind(this);
    }

    handleAccordionItemClick(index) {
      let activeItemIndex = -1;
      if (this.state.activeItemIndex !== index) {
        activeItemIndex = index
      }
      this.setState({ activeItemIndex });
    }

    render() {
      const self = this;
      return (
        <div className="accordion">
          <div className="accordion__header">
              <MenuIcon className="accordion__icon svg-icon" />
              <h2 className="accordion__heading">{this.props.header}</h2>
          </div>
          <ul className="accordion__items">
            {this.props.children.map((child, i) => (
              <AccordionItem
                handleAccordionItemClick={self.handleAccordionItemClick}
                accordionItemTitle={child.props.title}
                activeItemIndex={self.state.activeItemIndex}
                index={i}
                key={i}
              >
                {child.props.children}
              </AccordionItem>
            ))}
          </ul>
        </div>
      )
    }
}
Accordion.propTypes = {
  activeItemIndex: PropTypes.number
};
