import React from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {fetchSearchTracks, toggleFetchSearchTracksLoader} from "../../actions/catalog";

class TracksForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      search: ''
    };

    this.fetchTracks = this.fetchTracks.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentWillMount() {
    this.props.tracksLoader(false, true);
  }

  componentWillUnmount() {
    this.props.tracksLoader(false, true);
  }

  fetchTracks(e) {
    e.preventDefault();
    const search = this.state.search;
    if (search !== '' && search !== null && search.length > 3) {
      this.props.tracksLoader(true);
      this.props.fetchTracks(search);
    }
  }

  onSearchChange(e) {
    this.setState({search: e.target.value});
  }

  render() {
    const {loading} = this.props;
    const {search} = this.state;
    return (
      <form className="account__form">
        <div>
          <div className="inputbox">
            <div className="inputbox__left">
              <input name="search"
                      className="input"
                      type="text"
                      placeholder="Введите трек"
                      value={search}
                      onChange={this.onSearchChange} />
            </div>
            <div className="inputbox__right inputbox__right-button">
              <button
                className="button button_color_blue button_size_big button_weight_normal account__button"
                type="submit"
                disabled={loading}
                onClick={this.fetchTracks}>ПОИСК
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTracks: bindActionCreators(fetchSearchTracks, dispatch),
  tracksLoader: bindActionCreators(toggleFetchSearchTracksLoader, dispatch)
});

export default connect(state => ({loading: state.catalog.searchTracksLoading}),
  mapDispatchToProps)(TracksForm);
