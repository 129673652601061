import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";

class IconMenuItem extends React.Component
{

  render() {
    return (
      <li className={"icon-menu__item " + (this.props.isActive ? 'active' : '')}>
        {
          this.props.isGeneralHeaderFeedPlaying &&
          <div className="general-feed-playing-stub" />
        }
        <Link className='icon-menu__link' to={`${this.props.link}`}>
            {this.props.children}
            <span className="icon-menu__text">{this.props.name}</span>
        </Link>
      </li>
    );
  }
};

IconMenuItem.propTypes = {
  isActive: PropTypes.bool,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default connect(state => ({ isGeneralHeaderFeedPlaying: state.feed.isGeneralHeaderFeedPlaying}), null)(IconMenuItem);
