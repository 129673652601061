import React, { Component } from 'react';
import { ReactComponent as TableIcon} from '../../../icons/view_list.svg';
import { ReactComponent as GridIcon} from '../../../icons/view_module.svg';
import { ReactComponent as ListIcon} from '../../../icons/view_headline.svg';
import { ReactComponent as CloseIcon} from '../../../icons/close.svg';
import { ReactComponent as DropDownIcon} from '../../../icons/arrow_drop_down.svg';
import { ReactComponent as DownwardIcon} from '../../../icons/arrow_downward.svg';
import { ReactComponent as UpwardIcon} from '../../../icons/arrow_upward.svg';

//TODO:: repalce it
const sortLabels = {
  CreateDate: 'По дате добавления',
  GenreTracksString: 'По жанру',
  Rating: 'По рейтингу',
  Duration: 'По длительности'
};

class TracksFilter extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      currentOpenedFilter: ''
    };
  }

  toggleTracksView(view, e) {
    let {tracksView} = this.props;
    if (tracksView === 'list')
    this.props.clearPlayingTrack(this.props.playingWavesurfer);

    if (view === 'list')
    this.props.limitTracks(this.props.tracks);
    this.props.toggleTracksView(view);
  }

  toggleOpenedFilter(filter, e) {
    const currentOpenedFilter = this.state.currentOpenedFilter === filter ? '' : filter;
    this.setState({ currentOpenedFilter });
    this.props.toggleOpenedTracksFilter(currentOpenedFilter);
  }

  addGenreFilter(genre, e) {
    const {genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection, peaks} = this.props;
    this.props.addGenreFilter(genre);
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection);
  }

  deleteGenreFilter(genre, e) {
    const {genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection, peaks} = this.props;
    this.props.deleteGenreFilter(genre);
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection);
  }

  addCompanyTypeFilter(companyType, e) {
    const {genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection, peaks} = this.props;
    this.props.addCompanyTypeFilter(companyType);
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection);
  }

  deleteCompanyTypeFilter(companyType, e) {
    const {genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection, peaks} = this.props;
    this.props.deleteCompanyTypeFilter(companyType);
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection);
  }

  sortTracksBy(sortBy, e) {
    e.preventDefault();
    const {genresFilter, companyTypeFilter, sortDirection, tracksSortDirection, peaks} = this.props;
    this.props.changeSortBy(sortBy);
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, genresFilter, companyTypeFilter, sortBy, tracksSortDirection);
  }

  changeTracksSortDirection(e) {
    e.preventDefault();
    const {genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection, peaks} = this.props;
    let direction = tracksSortDirection === 'asc' ? 'desc' : 'asc';
    this.props.changeTracksSortDirection(direction);
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, genresFilter, companyTypeFilter, sortTracksBy, direction);
  }

  removeSort(e) {
    e.preventDefault();
    const {genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection, peaks} = this.props;
    this.props.removeTracksSort();
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, genresFilter, companyTypeFilter, '', tracksSortDirection);
    return false;
  }

  render() {

    let {tracksView, genres, tracksFilter, companyTypes, genresFilter, companyTypeFilter, sortTracksBy, tracksSortDirection} = this.props;
    let filterLabelClass = tracksSortDirection === 'asc' ? 'filter-label_down' : 'filter-label_up';

    return (
      <div className="filter-container" id="filters">
          <div className="filter-container__top-table">
              <div className="filter-container__top-table-col">
                  <b className="filter-container__name">ФИЛЬТРЫ:</b>
              </div>
              <div className="filter-container__top-table-col">
                  <a className="filter-item filter-container__item" onClick={this.toggleOpenedFilter.bind(this, 'genreFilter')}>
                      <span className="filter-item__text">Музыкальные жанры</span>
                      <DropDownIcon className="filter-item__icon svg-icon" />
                  </a>
                  <a className="filter-item filter-container__item" onClick={this.toggleOpenedFilter.bind(this, 'companyFilter')}>
                      <span className="filter-item__text">Тип предприятия</span>
                      <DropDownIcon className="filter-item__icon svg-icon" />
                  </a>
                  <a className="filter-item filter-container__item" onClick={this.toggleOpenedFilter.bind(this, 'sortingFilter')}>
                      <span className="filter-item__text">Сортировать</span>
                      <DropDownIcon className="filter-item__icon svg-icon" />
                  </a>
              </div>
          </div>
          <div className="view-toggler filter-container__toggler">
              <a className={"view-toggler__tab" + (tracksView==="list" ? ' view-toggler__tab_active' : '')} onClick={this.toggleTracksView.bind(this, 'list')}>
                <ListIcon className="view-toggler__icon svg-icon"/>
              </a>
              <a className={"view-toggler__tab" + (tracksView==="grid" ? ' view-toggler__tab_active' : '')} onClick={this.toggleTracksView.bind(this, 'grid')}>
                <GridIcon className="view-toggler__icon svg-icon"/>
              </a>
              <a className={"view-toggler__tab" + (tracksView==="table" ? ' view-toggler__tab_active' : '')} onClick={this.toggleTracksView.bind(this, 'table')}>
                <TableIcon className="view-toggler__icon svg-icon"/>
              </a>
          </div>
          <div className="filter-container__labels">
              {genresFilter.map((genreFilter, i) =>

                <span className="filter-label filter-container__label" key={i}>
                  <span className="filter-label__text">
                     {genres.map(function(genre) {
                        if (genre.genreId === genreFilter){
                          return genre.name;
                        }
                      })
                    }
                  </span>
                  <CloseIcon className="filter-label__icon filter-label__icon_close svg-icon" onClick={this.deleteGenreFilter.bind(this, genreFilter)}/>
                </span>
              )}

              {companyTypeFilter.map((filter, i) =>

                <span className="filter-label filter-container__label" key={i}>
                  <span className="filter-label__text">
                     {companyTypes.map(function(type, i) {
                        if (type.companyTypeId === filter){
                          return type.name;
                        }
                      })
                    }
                  </span>
                  <CloseIcon className="filter-label__icon filter-label__icon_close svg-icon" onClick={this.deleteCompanyTypeFilter.bind(this, filter)}/>
                </span>
              )}


              { sortTracksBy !== '' &&
                <a className={"filter-label filter-label_sort filter-container__label " + filterLabelClass}>
                    <span className="filter-label__text" onClick={this.changeTracksSortDirection.bind(this)}>{sortLabels[sortTracksBy]}</span>
                    <DownwardIcon className="filter-label__icon filter-label__icon_arrow_down svg-icon" onClick={this.changeTracksSortDirection.bind(this)}/>
                    <UpwardIcon className="filter-label__icon filter-label__icon_arrow_up svg-icon" onClick={this.changeTracksSortDirection.bind(this)}/>
                    <CloseIcon className="filter-label__icon filter-label__icon_close svg-icon" onClick={this.removeSort.bind(this)}/>
                </a>
              }
          </div>
          <div className="filter-container__accordions">
              <div className="filter-container__accordion" id="genreFilter" style={{maxHeight: (tracksFilter === 'genreFilter' ? 1000 : 0)}} >
                  <a className="filter-container__accordion-close" onClick={this.toggleOpenedFilter.bind(this, '')}>
                    <CloseIcon className="svg-icon"/>
                  </a>

                  {genres.map((genre, i) =>
                    genresFilter.indexOf(genre.genreId) === -1 &&
                      <a className="filter-label filter-label_nodelete filter-container__label" key={i}>
                        <span className="filter-label__text" onClick={this.addGenreFilter.bind(this, genre.genreId)}>{genre.name}</span>
                      </a>
                  )}

              </div>
              <div className="filter-container__accordion" id="companyFilter" style={{maxHeight: (tracksFilter === 'companyFilter' ? 1000 : 0)}}>
                  <a className="filter-container__accordion-close" onClick={this.toggleOpenedFilter.bind(this, '')}>
                    <CloseIcon className="svg-icon"/>
                  </a>

                  {companyTypes.map((type, i) =>
                    companyTypeFilter.indexOf(type.companyTypeId) === -1 &&
                    <a className="filter-label filter-label_nodelete filter-container__label" key={i}>
                        <span className="filter-label__text" onClick={this.addCompanyTypeFilter.bind(this, type.companyTypeId)}>{type.name}</span>
                    </a>
                  )}

              </div>
              <div className="filter-container__accordion" id="sortingFilter" style={{maxHeight: (tracksFilter === 'sortingFilter' ? 1000 : 0)}}>
                  <a className="filter-container__accordion-close" onClick={this.toggleOpenedFilter.bind(this, '')}>
                    <CloseIcon className="svg-icon"/>
                  </a>
                  <a className="filter-label filter-label_nodelete filter-container__label" onClick={this.sortTracksBy.bind(this, 'CreateDate')}>
                      <span className="filter-label__text ">{sortLabels.CreateDate}</span>
                  </a>
                  <a className="filter-label filter-label_nodelete filter-container__label" onClick={this.sortTracksBy.bind(this, 'Duration')}>
                      <span className="filter-label__text">{sortLabels.Duration}</span>
                  </a>
              </div>
          </div>
      </div>
    )
  }
}

export default TracksFilter;
