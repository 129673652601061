import Alert from "react-s-alert";
import {logoutUser} from "../actions/users";
import EnvHelpers from './Env';
import { Base64 } from 'js-base64';

export default class UserHelpers {
  static get ACCESS_TOKEN_COOKIE_NAME() {
    return 'access_mubicon_token';
  }

  static get PLACE_COOKIE_NAME() {
    return '_userPlace';
  }

  static isLoggedin() {
    const token = UserHelpers.getAccessToken();
    if (!(token && token !== ''))
      return false;

    const user = JSON.parse(atob(token.split('.')[1]));
    if (user['role'] === 'Owner') {
      UserHelpers.removeAccessToken();
      return false;
    }

    window.Rollbar.configure({
      payload: {
        enabled: window.APP_ENV === 'production',
        environment: window.APP_ENV,
        user: {id: user['id'], role: user['role']}
      }
    });
    return true;
  }

  static withPlace() {
    const place = UserHelpers.fetchUserPlace();
    if (!place)
      return false;

    const token = UserHelpers.getAccessToken();
    const user = JSON.parse(Base64.atob(token.split('.')[1]));

    if (place['userId'] !== user['id'])
      return false;

    window.Rollbar.configure({
      payload: {
        enabled: window.APP_ENV === 'production',
        environment: window.APP_ENV,
        user: {id: user['id'], role: user['role'], placeId: place}
      }
    });

    return true;
  }

  static fetchUserPlace() {
    let data = document.cookie.match('(^|;)\\s*' + UserHelpers.PLACE_COOKIE_NAME + '\\s*=\\s*([^;]+)');
    if (data) {
      try {
        const result = JSON.parse(Base64.decode(data.pop()));
        return result['placeId'] ? result : null;
      } catch {
        return null;
      }
    } else {
      return null;
    }
  }

  static setUserPlace(placeId, expires) {
    if (!expires) {
      expires = 360000;
    }
    let d = new Date();
    d.setTime(d.getTime() + expires * 1000);

    const token = UserHelpers.getAccessToken();
    const user = JSON.parse(atob(token.split('.')[1]));

    const encodedPlace = Base64.encode(JSON.stringify({userId: user['id'], placeId: placeId}));
    document.cookie = UserHelpers.PLACE_COOKIE_NAME + "=" + encodedPlace + ";path=/;expires=" + d.toGMTString();
  }

  static getAccessToken() {
    let data = document.cookie.match('(^|;)\\s*' + UserHelpers.ACCESS_TOKEN_COOKIE_NAME + '\\s*=\\s*([^;]+)');
    return data ? data.pop() : '';
  }

  static fetchUserInfoFromToken() {
    let token = this.getAccessToken();
    if (token && token !== '') {
      return JSON.parse(atob(token.split('.')[1]));
    }
  }

  static setAccessToken(token, expires) {
    if (!expires) {
      expires = 3600;
    }
    let d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    document.cookie = UserHelpers.ACCESS_TOKEN_COOKIE_NAME + "=" + token + ";path=/;expires=" + d.toGMTString();
  }

  static removeAccessToken() {
    var domain;
    if (EnvHelpers.isProduction())
      domain = process.env.REACT_APP_COOKIE_DOMAIN;
    if (EnvHelpers.isStaging())
      domain = process.env.REACT_APP_COOKIE_DOMAIN_ALPHA;

    if (EnvHelpers.isProduction() || EnvHelpers.isStaging())
      document.cookie = UserHelpers.ACCESS_TOKEN_COOKIE_NAME + "=;domain=" + domain + ";path=/;expires=-1";

    document.cookie = UserHelpers.ACCESS_TOKEN_COOKIE_NAME + "=;path=/;expires=-1";
  }

  static showError(message) {
    Alert.error(message);
  }

  static showSuccess(message) {
    Alert.success(message);
  }

  static handleAuthError(response, data) {
    if (response && response.statusCode === '401') {
      window.location = '/';
      return logoutUser();
    } else {
      return data;
    }
  }
}
