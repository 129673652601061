import React, { Component } from 'react';
import FeedCreateAnimation from './FeedCreateAnimation';
import FeedForm from './FeedForm';
import {ReactComponent as AddIcon} from '../../../icons/add.svg';

export default class CreateFeedForm extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showForm: false
    };

    this.displayFormHandler = this.displayFormHandler.bind(this);
  }

  displayFormHandler(e) {
    e.preventDefault();
    this.setState({showForm: true});
  }


  render() {
    const {showForm} = this.state;

    return (
      <div className="feed-new feed-control feed-new__control feed-new__inside">
        <div className="playlist-info">
          <div className="no-feed playlist-info__no-feed">
            <p className="no-feed__heading">Создайте первую ленту и подключите <br/>к ней плейлисты вашей компании.</p>
            { !showForm && <span>
            <a  href="/"className="no-feed__icon" onClick={this.displayFormHandler}><AddIcon className="svg-icon" /></a>
            <p className="no-feed__bot-text">Нажмите на кнопку <br/>«создать ленту»</p>
            </span> }
            { showForm &&
              <FeedForm saveFeedHandler={this.props.saveFeedHandler} />
            }
          </div>
          <FeedCreateAnimation className="feed-new__animation" />
        </div>
      </div>
    )
  }
}

