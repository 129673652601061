import React, { Component } from 'react';
import StarsRating from '../../StarsRating.js';
import withWavesurfer from "../Player/withWavesurfer";
import * as utils from "../../../utils/tracks";
import UrlHelpers from "../../../helpers/Url";
import DragSource from "react-dnd/lib/DragSource";
import {renderGenres} from "../../../utils/tracks";

import {ReactComponent as CloseIcon} from '../../../icons/close.svg';
import {ReactComponent as PlayIcon} from '../../../icons/play_circle_outline.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';
import {ReactComponent as DurationIcon} from '../../../icons/access_time-13px.svg';
import {ReactComponent as PlayListAddIcon} from '../../../icons/playlist_add.svg';
import {randomTrackImage} from '../../../utils/images';

class TracksGridItem extends Component {

  constructor(props, context) {
    super(props, context);
    this.playClickHandler = this.playClickHandler.bind(this);
  }

  playClickHandler(e) {
    let {play, track, peaks} = this.props;
    play(track, e.target, peaks[track.trackId]);
  }

  render() {
    const {addTrackToPlaylist,
      removeTrackFromPlaylist,
      playlist,
      pause,
      track,
      isTrackActive, isContentTrackActive, connectDragSource} = this.props;
    const {title, artist, genreTracks, duration, rating, imageFile, trackId} = this.props.track;
    let imageFileFix;
    if(imageFile && imageFile !== '') {
      imageFileFix = imageFile.replace(".", "_mid.");
    }

    const hasTrack = playlist && utils.hasTrack(trackId, playlist.trackPlayLists);

    return connectDragSource(
      <div className="track-wrap">
          <div className={`track ${isContentTrackActive(track) ? 'track__playing' : ''}`}>
            {hasTrack &&
            <div className="track__in__playlist_status">в плейлисте</div>
            }
            <div className="track__image-wrap">
              {
                imageFile && imageFile !== '' &&
                <img className="track__image" src={UrlHelpers.baseAudioImg() + '/' + imageFileFix} alt="track"/>
              }

              {
                (!imageFile || imageFile === '') &&
                <img className="track__image" src={randomTrackImage['image']} alt="track"/>
              }
              {isContentTrackActive(track) &&
              <a onClick={pause}>
                <PauseIcon className="track__control-icon track__control-icon_play svg-icon"/>
              </a>
              }
              {!isContentTrackActive(track) &&
              <a onClick={this.playClickHandler}>
                <PlayIcon className="track__control-icon track__control-icon_play svg-icon"/>
              </a>
              }
            </div>
            <div className="track__description">
              <span className="track__text track__text_name">{title}</span>
              <span className="track__text track__text_semibold track__text_color_grey">{artist}</span>
            </div>

            <div className="track__details">
                <StarsRating rating={rating} className="track__rating"/>
                <div className="track__playlist-controls">
                  {hasTrack &&
                  <a onClick={removeTrackFromPlaylist.bind(this, playlist, this.props.track)}>
                    <CloseIcon className="track__control-icon track__control-icon_delete svg-icon"/>
                  </a>
                  }
                  {!hasTrack && playlist &&
                  <a onClick={addTrackToPlaylist.bind(this, playlist, this.props.track)}>
                    <PlayListAddIcon className="track__control-icon track__control-icon_add svg-icon"/>
                  </a>
                  }
                </div>
                <span className="track__text track__text_semibold track__text_color_white track__text_duration">
                        <DurationIcon className="track__duration-icon svg-icon"/>{utils.renderTrackDuration(duration)}
                      </span>
                <span
                  className="track__text track__text_semibold track__text_color_white">{renderGenres(genreTracks)}</span>
                <span className="track__text track__text_semibold track__text_color_white">{artist}</span>
            </div>
          </div>
      </div>
    )
  }
}

const DraggableTrackItem = DragSource('playlist-track', utils.trackDragSource, utils.getCollectTrack)(TracksGridItem);

export default withWavesurfer(DraggableTrackItem, {height: 90,
  waveContainerSelector: ".layout__header .player__equalizer", generalWave: true, source: utils.TRACK_SOURCE_GRID});

