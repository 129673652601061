import React from 'react';

import {ReactComponent as ErrorIcon} from '../icons/error_outline.svg';
import {ReactComponent as CloseIcon} from '../icons/close.svg';
import {ReactComponent as CheckIcon} from '../icons/check.svg';

class AlertTemplate extends React.Component {

  render() {
    return (
      <div className={this.props.classNames} id={this.props.id} style={this.props.styles}>
        <div className='s-alert-box-inner'>
          <span className="s-alert-icon">
            {
              this.props.condition === 'error' &&
              <ErrorIcon className="svg-icon" />
            }
            {
              this.props.condition === 'success' &&
              <CheckIcon className="svg-icon" />
            }
          </span>
          <span className="s-alert-message">{this.props.message}</span>
        </div>
        <span className='s-alert-close' onClick={this.props.handleClose}><CloseIcon className="svg-icon" /></span>
      </div>
    )
  }
}

export default AlertTemplate;
