import React from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {fetchSearchTracks, toggleFetchTracksLoader} from "../../actions/catalog";
import {renderTrackDuration} from "../../utils/tracks";
import {toLocaleDateString} from "../../utils/dates";
import TracksForm from "./TracksForm";

class SearchTracks extends React.Component
{
  renderItem(item) {
    return (<div className="table__row" key={item.trackId}>
      <div className="table__col table__col_width_120">
        <p className="text-small table__col_width_120 table__col_ellipsis">{ item.isrc }</p>
      </div>
      <div className="table__col table__col_width_240">
        <p className="text-small table__col_width_240 table__col_ellipsis">
          { item.name }
        </p>
      </div>
      <div className="table__col table__col_width_100">
        <p className="text-small">
          { renderTrackDuration(item.duration) }
        </p>
      </div>
      <div className="table__col table__col_width_40">
        <p className="text-small">
          { item.createDate && toLocaleDateString(item.createDate) }
        </p>
      </div>
      <div className="table__col">
        <p className="text-small">
          { item.changeDate && toLocaleDateString(item.changeDate) }
        </p>
      </div>
      <div className="table__col table__col_width_60 table__col_align_right">
        <p className="text-small">
          { item.cancelDate && toLocaleDateString(item.cancelDate) }
          { !item.cancelDate && 'Нет' }
        </p>
      </div>
    </div>);
  }
  render() {
    const { tracks, loading } = this.props;

    return (
        <div className="account__search_container">
          <TracksForm />
          <div className="table">
            <div className="table__row">
              <div className="table__col table__col_width_120">
                <p className="text-small table__col_width_120 table__col_ellipsis">
                  <b>Номер</b>
                </p>
              </div>
              <div className="table__col table__col_width_240">
                <p className="text-small table__col_width_240 table__col_ellipsis">
                  <b>Название</b>
                </p>
              </div>
              <div className="table__col table__col_width_100">
                <p className="text-small">
                  <b>Длительность</b>
                </p>
              </div>
              <div className="table__col table__col_width_40">
                <p className="text-small">
                  <b>Добавлен</b>
                </p>
              </div>
              <div className="table__col ">
                <p className="text-small">
                  <b>Изменен</b>
                </p>
              </div>
              <div className="table__col table__col_width_60 table__col_align_right">
                <p className="text-small">
                  <b>Исключен</b>
                </p>
              </div>
            </div>
            { loading && <div className="table__row">
              <div className="table__col table__col_width_140">
                <p className="text-small">Загрузка</p>
              </div>
            </div>}
            { !loading && tracks && tracks.map((track, i) => this.renderItem(track)) }
            { !loading && (!tracks || tracks.length == 0) && <div className="table__row">
              <div className="table__col table__col_width_140">
                <p className="text-small">Треков не найдено</p>
              </div>
            </div>}
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchTracks: bindActionCreators(fetchSearchTracks, dispatch),
  tracksLoader: bindActionCreators(toggleFetchTracksLoader, dispatch)
});

export default connect(state => ({tracks: state.catalog.searchTracks, loading: state.catalog.searchTracksLoading}),
  mapDispatchToProps)(SearchTracks);
