export const MORNING_TIME = 1;
export const DAY_TIME = 2;
export const EVENING_TIME = 3;
export const NIGHT_TIME = 4;

export function isMorningTime (timeIdx) {
  //TODO:: use constants
  return timeIdx === 1;
}

export function isDayTime (timeIdx) {
  return timeIdx === 2;
}

export function isEveningTime (timeIdx) {
  return timeIdx === 3;
}

export function isNightTime (timeIdx) {
  return timeIdx === 4;
}

export function getTimeOfDayTitle(timeOfDay) {
  switch(timeOfDay) {
    case MORNING_TIME:
      return 'Утро';
    case DAY_TIME:
      return 'День';
    case EVENING_TIME:
      return 'Вечер';
    case NIGHT_TIME:
      return 'Ночь';
    default:
      return '';
  }
}

export function shuffleTracks(tracks) {
  let currentIndex = tracks.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = tracks[currentIndex];
    tracks[currentIndex] = tracks[randomIndex];
    tracks[randomIndex] = temporaryValue;
  }
  return tracks;
}

export function renderDuration(duration) {
  let durations = duration.split(':');
  let h = durations[0].split('.')[1];
  let result = "";
  if (h) {
    result = h + ":";
  } else if (parseInt(durations[0]) > 0)
    result = parseInt(durations[0]) + ":";
  const min = durations[1];
  const sec = durations[2].split('.');
  return result + min + ":" + sec[0];
}

