import React from 'react';
import Lottie from "lottie-react";
import feedAnimation from '../../../lottie/lottie.json';

const FeedCreateAnimation = ({className=''}) => {
  return <div className="feed__lottie_wrap">
    <Lottie animationData={feedAnimation} loop={true} className={className}/>
  </div>;
};

export default FeedCreateAnimation;