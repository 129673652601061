import React from 'react';

export default class AccordionItem extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleAccordionItemClick = this.handleAccordionItemClick.bind(this);
    }

    handleAccordionItemClick(index, e) {
      this.props.handleAccordionItemClick(index);
    }

    render() {
      let {accordionItemTitle, activeItemIndex, index, children} = this.props;
      const isActive = activeItemIndex === index;
      return (
        <li className={"accordion__item " + (isActive ? 'active' : '')}>
          <div className="accordion__title" onClick={this.handleAccordionItemClick.bind(this, index)}>{accordionItemTitle}</div>
          <div className="accordion__content" style={{display: isActive ? 'block' : 'none' }}>{children}</div>
      </li>
    )
  }
}
