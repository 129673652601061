import React from 'react';

export default class Tariff extends React.Component
{
  render() {
    return (
      <div className="tariff__tab">
        Тариф
      </div>
    );
  }
};
