import React, { Component } from 'react';
import PropTypes from "prop-types";
import { reduxForm, Field } from 'redux-form';
import Slider from 'react-slick';
import {setUserPlace, setUserPlaceMemory} from '../actions/users';
import UserHelpers from "../helpers/User";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {
  clearPlayingTrack
} from "../actions/catalog";
import {
  clearPlayingFeedTrack
} from "../actions/feed";
import NProgress from "nprogress";
import { ReactComponent as UserIcon} from '../icons/account_circle.svg';
import { ReactComponent as LocationIcon} from '../icons/room_black.svg';
import StoreButton from './Layout/StoreButton';

function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.current_place || values.current_place.length === 0) {
    errors.current_place = 'Enter place';
    hasErrors = true;
  }

  return hasErrors && errors;
}

const sliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  slidesPerRow: 1,
  infinite: false,
  rows: 5,
  dots: true,
  arrows: false,
};

const findIndexOfPlace = (places, placeId) => {
  const position = places.findIndex(place => place.value === placeId);
  if (position === -1) return -1;

  return Math.floor(position / sliderSettings.rows);
};

const renderRadioButtons = ({ input, options, title = "Default Title", dispatch}) => {
  if (!options || options.length === 0) {
    return <div>No options available</div>;
  }

  const handleLocationSelect = (value) => {
    const result = input.onChange(value);
    dispatch(setUserPlaceMemory(value));
    return result;
  };

  const selectedIndex = findIndexOfPlace(options, input.value);

  const updatedSliderSettings = {
    ...sliderSettings,
    initialSlide: selectedIndex >= 0 ? selectedIndex : 0
  };

  const locationsClass = options.length == 1 ? 'locations__single' : '';

  return (
    <div className={`logged__locations locations ${locationsClass}`}>
      <div className="locations__title">{title}</div>
      <Slider {...updatedSliderSettings}>
        {options.map((location, index) => (
          <div key={index} className="locations__item">
            <label className="locations__label">
              <input
                type="radio"
                {...input}
                value={location.value}
                className="locations__radio"
                checked={input.value === location.value}
                onChange={() => handleLocationSelect(location.value)}
              />
              <LocationIcon className="locations__label_icon" />
              {location.name}
            </label>
          </div>
        ))}
      </Slider>
    </div>
  );
}

class SelectPlaceForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      success: false,
    };

    this.validateAndUpdatePlace = this.validateAndUpdatePlace.bind(this);
  }

  selectCurrentPlace(place) {
    console.log(place);
  }

  validateAndUpdatePlace(values, dispatch) {
    if (!validate(values)) {
      const { user } = this.props;
      dispatch(setUserPlace(values.current_place));

      let hasPlace = UserHelpers.withPlace();

      if (hasPlace && !user.error) {
        this.context.router.history.push('/');
      }

      this.setState({success: true});
    } else {
      this.setState({success: false});
    }
  };

  componentDidMount() {
    if (!UserHelpers.isLoggedin())
      this.context.router.history.push('/login');

    this.props.fetchPlaces();
    this.props.fetchUserFullInfo();
    NProgress.done();
    this.props.clearPlayingFeedTrack(this.props.playingFeedWavesurfer);
    this.props.clearPlayingTrack(this.props.playingWavesurfer);
  }

  componentDidUpdate(prevProps) {
    if (this.props.places && this.props.places.length > 0 && prevProps.places.length === 0)
    {
      this.props.change('current_place', this.props.places[0].placeId.toString());
    }

    if (this.props.currentPlace !== prevProps.currentPlace) {
      const selectedIndex = findIndexOfPlace(this.props.places, this.props.currentPlace);
      this.slider.slickGoTo(selectedIndex); // assuming this.slider refers to your Slider component
    }
  }

  render() {
    const {handleSubmit, submitting, places, userInfo, dispatch, place} = this.props;

    const placesList = places && places.map(place => { return { name: `${place.name}: ${place.address}`, value: place.placeId } });

    return (
      <div className="container__select-place">
        <div className="container_center">
          <form className="input__logged logged" onSubmit={ handleSubmit(this.validateAndUpdatePlace) }>
            <div className="logged__inner">
              <div className="logged__item logged__item--left">
                <div className="logged__entered">Вы вошли в аккаунт</div>
                <div className="logged__user">
                  <div className="logged__avatar">
                    <UserIcon className="logged__def-user"/>
                    {/* <div className="user__icon material-icon logged__def-user">&#xE853;</div> */}
                  </div>
                  <div className="logged__meta">
                    <p className="logged__login">{ userInfo && userInfo.companyName }</p>
                    <p className="logged__mail">{ userInfo && userInfo.email }</p>
                  </div>
                </div>
                <div className="logged__row">
                  <button disabled={submitting || !place} className="logged__btn btn">
                    Продолжить
                  </button>
                </div>
              </div>

              <div className="logged__item logged__item--right">
                <Field
                  name="current_place"
                  id="current_place"
                  component={props => 
                    renderRadioButtons({...props, ref: slider => this.slider = slider, dispatch})
                  }
                  options={placesList}
                  title="Выберите вашу текущую локацию"
                />
              </div>
            </div>
          </form>
          <div className="container__select-place-apps">
            <StoreButton type="rustore"/>
            <StoreButton type="google"/>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserPlace: bindActionCreators(setUserPlace, dispatch),
  setUserPlaceMemory: bindActionCreators(setUserPlaceMemory, dispatch),
  clearPlayingFeedTrack: bindActionCreators(clearPlayingFeedTrack, dispatch),
  clearPlayingTrack: bindActionCreators(clearPlayingTrack, dispatch)
});

export default connect(state => ({  playingWavesurfer: state.catalog.playingWavesurfer,
  playingFeedWavesurfer: state.feed.playingFeedWavesurfer}), mapDispatchToProps)(reduxForm({
  form: 'selectPlaceForm',
  enableReinitialize : true,
  validate
})(SelectPlaceForm));

