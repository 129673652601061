import React from 'react';
import { Route } from 'react-router-dom';
import Aside from './components/Layout/Aside';
import MainHeader from './components/Main/Header';
import LeftSidebar from './components/Main/LeftSidebar.js';

const layoutLess = ({components: Components, matchProps}) => {
  return (
    <div className="layout layout__less">
      <div className="layout__center">
        <main className="layout__content" role="main">
          <Components.main {...matchProps} />
        </main>
      </div>
    </div>
  )
};

const layout = ({components: Components, matchProps}) => {
  return (
    <div className="layout">
      <Aside>
        <LeftSidebar {...matchProps} />
      </Aside>
      <div className="layout__center">
        <MainHeader {...matchProps} />
        <main className="layout__content" role="main">
          <Components.main {...matchProps} />
        </main>
      </div>
      <Aside klass="layout__right-side" innerKlass="layout__right-block">
      </Aside>
    </div>
  )
};

export const PublicRoute = ({components: Components, withLayout = true, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      withLayout ? layout({components: Components, matchProps}) : layoutLess({components: Components, matchProps})
    )} />
  )
};
