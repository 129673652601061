import React from 'react';

const renderField = ({ input, label, type, placeholder, required, value, disabled }) => (
  <div className="inputbox">
      <div className="inputbox__left">
          <p className="text-small">
            {label}
            {
              required &&
              <span>*</span>
            }
          </p>
      </div>
      <div className="inputbox__right">
          <input {...input} className="input" type={type} placeholder={placeholder} defaultValue={value} disabled={disabled}/>
      </div>
  </div>
)

export default renderField;

export const renderDropDownField = (formValues) => {

  const { input, label, options, id, className } = formValues;

  return (
    <div className="form-group">
      {label && <label htmlFor={input.name}>{label}</label>}
      <select {...input} id={id} className={className}>
        {
          options.map((option) => <option value={option.value} key={option.value}>{option.name}</option>)
        }
      </select>
    </div>
  );
}
