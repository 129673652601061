import React, { Component } from 'react';
import {noun} from "plural-ru";
import UrlHelpers from "../../../helpers/Url";
import {Link} from 'react-router-dom';
import {renderDuration} from "../../../utils/playlists";

import { ReactComponent as StarIcon} from '../../../icons/star.svg';
import { ReactComponent as RemoveRedEyeIcon} from '../../../icons/remove_red_eye.svg';

class CompilationPreview extends Component {
  render() {
    const {compilation, size} = this.props;
    return (
      <div className={"card " + size}>
        <div className="card__texts">
          <h3 className="card__heading">{compilation.name}</h3>
          <p className={"card__smalltext"}>Жанр: {compilation.genreSelections && compilation.genreSelections[0]}</p>
        </div>
        <div className="card__texts card__texts_duration">
          <p className="card__smalltext">Длительность:&nbsp;{renderDuration(compilation.duration)}</p>
        </div>
        <div className="card__count">
          <span className="card__count-text card__count-text_value">{compilation.count}</span>
          <span className="card__count-text">&nbsp;{noun(compilation.count, 'трек', 'трека', 'треков')}</span>
        </div>
        <div className="card__count card__count_views">
          <RemoveRedEyeIcon className="card__view-icon svg-icon" />
          <span className="card__count-text card__count-text_value">{compilation.views}</span>
        </div>
        <div className="rating rating_rounded card__rating">
          <StarIcon className="rating__icon svg-icon" />
          <span className="rating__count">{compilation.rating.toFixed(1)}</span>
        </div>
        <Link className="button button_color_blue button_size_big card__button"  to={`/compilation/${compilation.selectionId}`}>В подборку</Link>
        <img className="card__image" alt="" role="presentation" src={UrlHelpers.baseImg() + '/' + compilation.imageFile} />
      </div>
    )
  }
}

export default CompilationPreview;
