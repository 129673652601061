import DroppablePlaylistRightSidebar from '../components/Catalog/Playlists/PlaylistRightSidebar.js';
import { connect } from 'react-redux';
import {
  createPlaylist,
  cancelCreatePlaylist,
  fetchPlaylists,
  savePlaylist,
  showPlaylist,
  editPlaylist,
  cancelUpdatePlaylist,
  deletePlaylist,
  addTrackToPlaylist,
  startPlayingTrack,
  stopPlayingTrack,
  updatePlaylist,
  updatePlaylistTracks,
  toggleLoader,
  updateCurrentTimeForPlayingTrack,
  removeTrackFromPlaylist,
  fetchTrackPeaks,
  fetchAllTrackPeaks, downloadPlayList,
  playbackStatistic
} from '../actions/catalog';
import {bindActionCreators} from "redux";


const mapDispatchToProps = (dispatch) => ({
  fetchPlaylists: bindActionCreators(fetchPlaylists, dispatch),
  createPlaylist: bindActionCreators(createPlaylist, dispatch),
  downloadPlayList: bindActionCreators(downloadPlayList, dispatch),
  cancelCreatePlaylist: bindActionCreators(cancelCreatePlaylist, dispatch),
  cancelUpdatePlaylist: bindActionCreators(cancelUpdatePlaylist, dispatch),
  updatePlaylistTracks: bindActionCreators(updatePlaylistTracks, dispatch),
  updatePlaylist: bindActionCreators(updatePlaylist, dispatch),
  showPlaylist: bindActionCreators(showPlaylist, dispatch),
  savePlaylist: bindActionCreators(savePlaylist, dispatch),
  editPlaylist: bindActionCreators(editPlaylist, dispatch),
  deletePlaylist: bindActionCreators(deletePlaylist, dispatch),
  addTrackToPlaylist: bindActionCreators(addTrackToPlaylist, dispatch),
  removeTrackFromPlaylist: bindActionCreators(removeTrackFromPlaylist, dispatch),
  startPlayingTrack: bindActionCreators(startPlayingTrack, dispatch),
  stopPlayingTrack: bindActionCreators(stopPlayingTrack, dispatch),
  updateCurrentTimeForPlayingTrack: bindActionCreators(updateCurrentTimeForPlayingTrack, dispatch),
  toggleLoader: bindActionCreators(toggleLoader, dispatch),
  fetchTrackPeaks: bindActionCreators(fetchTrackPeaks, dispatch),
  fetchAllTrackPeaks: bindActionCreators(fetchAllTrackPeaks, dispatch),
  playbackStatistic: bindActionCreators(playbackStatistic, dispatch)
});

function mapStateToProps(state, ownProps) {
  return {
    playlistAction: state.catalog.playlistAction,
    playlists: state.catalog.playlists,
    error: state.catalog.error,
    playlist: state.catalog.playlist,
    playingTrack: state.catalog.playingTrack,
    playingWavesurfer: state.catalog.playingWavesurfer,
    isPlayingTrackActive: state.catalog.isPlayingTrackActive,
    playingTrackSource: state.catalog.playingTrackSource,
    peaks: state.catalog.peaks
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DroppablePlaylistRightSidebar);
