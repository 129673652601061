import Tracks from '../components/Catalog/Tracks/Tracks.js';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  toggleLoader, fetchTracks, toggleTracksView, toggleOpenedTracksFilter,
  fetchGenres, fetchCompanyTypes,
  addGenreFilter, deleteGenreFilter, addCompanyTypeFilter, deleteCompanyTypeFilter, changeSortBy,
  changeTracksSortDirection, removeTracksSort, addTrackToPlaylist, removeTrackFromPlaylist,
  startPlayingTrack, stopPlayingTrack, clearPlayingTrack, limitTracks, updateCurrentTimeForPlayingTrack,
  fetchTrackPeaks, fetchAllTrackPeaks, deselectPlaylist, toggleFetchTracksLoader
} from '../actions/catalog';
import {clearPlayingFeedTrack, stopPlayingFeedTrack, startPlayingGeneralFeedTrack, startPlayingGeneralFeedPlaylist} from "../actions/feed";

const mapDispatchToProps = (dispatch) => ({
  toggleLoader: bindActionCreators(toggleLoader, dispatch),
  fetchTracks: bindActionCreators(fetchTracks, dispatch),
  fetchTrackPeaks: bindActionCreators(fetchTrackPeaks, dispatch),
  fetchAllTrackPeaks: bindActionCreators(fetchAllTrackPeaks, dispatch),
  addGenreFilter: bindActionCreators(addGenreFilter, dispatch),
  deleteGenreFilter: bindActionCreators(deleteGenreFilter, dispatch),
  addCompanyTypeFilter: bindActionCreators(addCompanyTypeFilter, dispatch),
  deleteCompanyTypeFilter: bindActionCreators(deleteCompanyTypeFilter, dispatch),
  changeSortBy: bindActionCreators(changeSortBy, dispatch),
  changeTracksSortDirection: bindActionCreators(changeTracksSortDirection, dispatch),
  removeTracksSort: bindActionCreators(removeTracksSort, dispatch),
  toggleTracksView: bindActionCreators(toggleTracksView, dispatch),
  toggleOpenedTracksFilter: bindActionCreators(toggleOpenedTracksFilter, dispatch),
  fetchGenres: bindActionCreators(fetchGenres, dispatch),
  fetchCompanyTypes: bindActionCreators(fetchCompanyTypes, dispatch),
  addTrackToPlaylist: bindActionCreators(addTrackToPlaylist, dispatch),
  removeTrackFromPlaylist: bindActionCreators(removeTrackFromPlaylist, dispatch),
  startPlayingTrack: bindActionCreators(startPlayingTrack, dispatch),
  stopPlayingTrack: bindActionCreators(stopPlayingTrack, dispatch),
  clearPlayingTrack: bindActionCreators(clearPlayingTrack, dispatch),
  updateCurrentTimeForPlayingTrack: bindActionCreators(updateCurrentTimeForPlayingTrack, dispatch),
  startPlayingGeneralFeedPlaylist: bindActionCreators(startPlayingGeneralFeedPlaylist, dispatch),
  startPlayingGeneralFeedTrack: bindActionCreators(startPlayingGeneralFeedTrack, dispatch),
  stopPlayingFeedTrack: bindActionCreators(stopPlayingFeedTrack, dispatch),
  clearPlayingFeedTrack: bindActionCreators(clearPlayingFeedTrack, dispatch),
  limitTracks: bindActionCreators(limitTracks, dispatch),
  deselectPlaylist: bindActionCreators(deselectPlaylist, dispatch),
  toggleFetchTracksLoader: bindActionCreators(toggleFetchTracksLoader, dispatch)
});

function mapStateToProps(state) {
  return {
    tracksLoading: state.catalog.tracksLoading,
    tracks: state.catalog.tracks,
    allTracksLoaded: state.catalog.allTracksLoaded,
    tracksView: state.catalog.tracksView,
    genres: state.catalog.genres,
    tracksFilter: state.catalog.tracksFilter,
    companyTypes: state.catalog.companyTypes,
    genresFilter: state.catalog.genresFilter,
    companyTypeFilter: state.catalog.companyTypeFilter,
    sortTracksBy: state.catalog.sortTracksBy,
    tracksSortDirection: state.catalog.tracksSortDirection,
    playlist: state.catalog.playlist,
    playingTrack: state.catalog.playingTrack,
    playingWavesurfer: state.catalog.playingWavesurfer,
    wavesurfer: state.catalog.wavesurfer,
    isPlayingTrackActive: state.catalog.isPlayingTrackActive,
    peaks: state.catalog.peaks,
    loading: state.catalog.loading,
    playingFeedWavesurfer: state.feed.playingFeedWavesurfer
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tracks);
