import EnvHelpers from './Env';

export default class UrlHelpers {
  static siteRedirect(history) {
    if (EnvHelpers.isDemo() || EnvHelpers.isStaging() || UrlHelpers.isLocalhost() || UrlHelpers.isAspNetDev()) {
      history.push('login');
      return;
    }
    window.location.href = process.env.REACT_APP_SITE_LOGIN;
  }

  static openTab(url) {
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  static api() {
    if (EnvHelpers.isStaging())
      return process.env.REACT_APP_API_ALPHA_BASE;

    if (EnvHelpers.isProduction())
      return process.env.REACT_APP_API_BASE;

    if (UrlHelpers.isAspNetDev())
      return `${window.location.origin}${process.env.REACT_APP_ASP_API_BASE}`;

    return process.env.REACT_APP_API_BASE;
  }

  static baseImg() {
    if (EnvHelpers.isStaging())
      return process.env.REACT_APP_IMG_ALPHA_BASE;

    if (EnvHelpers.isProduction())
      return process.env.REACT_APP_IMG_BASE;

    if (UrlHelpers.isLocalhost() && !UrlHelpers.isAspNetDev())
      return process.env.REACT_APP_IMG_BASE;

    return `${window.location.origin}${process.env.REACT_APP_ASP_IMG_BASE}`;
  }

  static baseAudio() {
    if (EnvHelpers.isStaging())
      return process.env.REACT_APP_AUDIO_ALPHA_BASE;

    if (EnvHelpers.isProduction())
      return process.env.REACT_APP_AUDIO_BASE;

    if (UrlHelpers.isLocalhost() && !UrlHelpers.isAspNetDev())
      return process.env.REACT_APP_AUDIO_BASE;

    return process.env.REACT_APP_ASP_AUDIO_BASE;
  }

  static baseAudioImg() {
    if (EnvHelpers.isStaging())
      return process.env.REACT_APP_AUDIO_IMAGE_ALPHA_BASE;

    if (EnvHelpers.isProduction())
      return process.env.REACT_APP_AUDIO_IMAGE_BASE;

    if (UrlHelpers.isLocalhost() && !UrlHelpers.isAspNetDev())
      return process.env.REACT_APP_AUDIO_IMAGE_BASE;

    return process.env.REACT_APP_ASP_AUDIO_IMAGE_BASE;
  }

  static basePeaks() {
    if (EnvHelpers.isStaging())
      return process.env.REACT_APP_PEAKS_ALPHA_BASE;

    if (EnvHelpers.isProduction())
      return process.env.REACT_APP_PEAKS_BASE;

    if (UrlHelpers.isLocalhost() && !UrlHelpers.isAspNetDev())
      return process.env.REACT_APP_PEAKS_BASE;

    return process.env.REACT_APP_ASP_PEAKS_BASE;
  }

  static baseArchive() {
    if (EnvHelpers.isStaging())
      return process.env.REACT_APP_ARCHIVE_ALPHA_BASE;

    if (EnvHelpers.isProduction())
      return process.env.REACT_APP_ARCHIVE_BASE;

    if (UrlHelpers.isLocalhost() && !UrlHelpers.isAspNetDev())
      return process.env.REACT_APP_ARCHIVE_BASE;

    return process.env.REACT_APP_ASP_ARCHIVE_BASE;
  }

  static isAspNetDev() {
    return window.location.href.indexOf(process.env.REACT_APP_API_ASP) > 0;
  }

  static isLocalhost() {
    return window.location.href.indexOf('localhost') > 0;
  }
}
