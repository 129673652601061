import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as utils from '../../../utils/tracks';
import {
  updateCurrentTimeForPlayingTrack,
} from "../../../actions/catalog";
import {bindActionCreators} from "redux";

class DurationHeaderPlayer extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {playingTrack, playingWavesurfer, currentTimePlayingTrack} = this.props;

    return (
      <div className="player__duration">
      {playingWavesurfer && playingWavesurfer.generalWave &&
        <div className="player__timer">
          {playingWavesurfer && currentTimePlayingTrack ?
            currentTimePlayingTrack : utils.renderTrackDuration(playingTrack.duration)}
        </div>
      }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTimeForPlayingTrack: bindActionCreators(updateCurrentTimeForPlayingTrack, dispatch),
});

export default connect(state => ({ playingTrack: state.catalog.playingTrack,
                                   playingWavesurfer: state.catalog.playingWavesurfer,
                                   currentTimePlayingTrack: state.catalog.currentTimePlayingTrack}),
  mapDispatchToProps)(DurationHeaderPlayer);
