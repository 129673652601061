import * as moment from "moment";

export const WEEKEND_FEED_TYPE = 1;
export const WEEKDAY_FEED_TYPE = 2;
export const OTHER_FEED_TYPE = 3;
export const FEED_COOKIE_NAME = 'selected_feed';

//TODO:: use constants
export function isOtherFeedType(type) {
  return type === 3;
}

export function isWeekendFeedType(type) {
  return type === 1;
}

export function isWeekdayFeedType(type) {
  return type === 2;
}

export function isFeedCalendarShown(dayType) {
  return dayType === 3 || dayType === 2 || dayType === 1;
}

export function isFeedsPageOpened() {
  return window.location.pathname === "/feed";
}

export function getSavedFeed() {
  let data = document.cookie.match('(^|;)\\s*' + FEED_COOKIE_NAME + '\\s*=\\s*([^;]+)');
  return data ? data.pop() : '';
}

export function setSavedFeed(feedId, expires) {
  if (!expires) {
    expires = 3600;
  }
  let d = new Date();
  d.setTime(d.getTime() + expires * 1000);
  document.cookie = FEED_COOKIE_NAME + "=" + feedId + ";path=/;expires=" + d.toGMTString();
}

export function removeSavedFeed() {
  document.cookie = FEED_COOKIE_NAME+ "=;path=/;expires=-1";
}

export function isWeekend(dateStr) {
  let date = dateStr ? moment(new Date(dateStr)) : moment(new Date());
  return date.day() === 0 || date.day() === 6;
}

export function getCurrentSelectedFeedId(feeds) {
  const savedId = getSavedFeed();

  let id = null;
  // if saved in cookie
  if (savedId !== '') {
    id = savedId;
  } else {
    const feedsForSpecificDate = feeds.filter((feed) => {
      const calendarDate = feed.calendarDate;
      if (calendarDate) {
        //select all feeds for today date
        return moment(calendarDate).isSame(new Date(), 'day');
      } else {
        return false;
      }
    });

    let feedForSpecificDayOfWeek = [];

    if (feedsForSpecificDate.length === 0) {
      feedForSpecificDayOfWeek = feeds.filter((feed) => {
        const calendarDay = feed.calendarDay;
        //select all feeds for today day of week
        if (isWeekend()) {
          return isWeekendFeedType(calendarDay);
        } else {
          return isWeekdayFeedType(calendarDay);
        }
      });
      if (feedForSpecificDayOfWeek.length > 0) {
        // Select first feed with current day of week
        id = feedForSpecificDayOfWeek[0].feedId;
      }

    } else {
      // Select first feed with current date
      id = feedsForSpecificDate[0].feedId;
    }
  }

  if (!id && feeds.length > 0) {
    id = feeds[0].feedId;
  }

  return id;
}
