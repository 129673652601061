import React from 'react';
import ATabs from './Account/Tabs';


const Account = props => (
    <div className="account">
        <h1 className="account__heading heading-main">Управление аккаунтом</h1>
        <ATabs fetchUserFullInfo={props.fetchUserFullInfo} userInfo={props.userInfo} />
    </div> 
);

export default Account;
