import FeedCalendar from '../components/Catalog/Feed/FeedCalendar.js';
import { connect } from 'react-redux';
import { changeFeedDayType } from '../actions/feed';
import {bindActionCreators} from "redux";


const mapDispatchToProps = (dispatch) => ({
  changeFeedDayType: bindActionCreators(changeFeedDayType, dispatch)
});

function mapStateToProps(state) {
  return {
    activeFeed: state.feed.activeFeed,
    activeFeedDate: state.feed.activeFeedDate
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedCalendar);
