import React, { Component } from 'react';
import StarsRating from '../../StarsRating.js';
import TracklistPlayer from '../Player/TracklistPlayer.js';
import UrlHelpers from '../../../helpers/Url';
import DragSource from "react-dnd/lib/DragSource";
import * as utils from "../../../utils/tracks";

import {ReactComponent as CloseIcon} from '../../../icons/close.svg';
import {ReactComponent as PlayListAddIcon} from '../../../icons/playlist_add.svg';
import {randomTrackImage} from '../../../utils/images';

class TracksListItem extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {addTrackToPlaylist, removeTrackFromPlaylist, playlist, connectDragSource, peaksQueryTrackIds, peaksQuerySide, peaksQuery} = this.props;
    const {rating, imageFile, trackId, ownerContainer, genreTracks} = this.props.track;
    let imageFileFix;
    if (imageFile && imageFile !== '') {
      imageFileFix = imageFile.replace(".", "_mini.");
    }

    const hasTrack = playlist && utils.hasTrack(trackId, playlist.trackPlayLists);

    return connectDragSource(
      <div className="track-list track-lists__track">
          <div className="track-list__heading">
            {
              hasTrack &&
              <a className="track-list__add"
                 onClick={removeTrackFromPlaylist.bind(this, playlist, this.props.track)}>
                <CloseIcon className="track-list__add-icon svg-icon" />
                <span className="track-list__add-text">Удалить</span>
              </a>
            }

            {
              !hasTrack &&
              <a className={"track-list__add " + (playlist ? '' : 'hidden')}
                 onClick={addTrackToPlaylist.bind(this, playlist, this.props.track)}>
                <PlayListAddIcon className="track-list__add-icon svg-icon"/>
                <span className="track-list__add-text">Добавить</span>
              </a>
            }
            <StarsRating rating={rating} className="track-list__rating"/>
          </div>
          <div className="track-list__content">
              <div className="track-list__image-wrap">
                {
                  imageFile && imageFile !== '' &&
                  <img className="track-list__image" src={UrlHelpers.baseAudioImg() + '/' + imageFileFix} alt="track"  />
                }

                {
                  (!imageFile || imageFile === '') &&
                  <img className="track-list__image" src={randomTrackImage['image']} alt="track"/>
                }
              </div>

              <TracklistPlayer {...this.props} />
          </div>
      </div>
    )
  }
}

export default DragSource('playlist-track', utils.trackDragSource, utils.getCollectTrack)(TracksListItem);





