import NProgress from "nprogress";

export const TRACK_SOURCE_LIST = 'TRACK_SOURCE_LIST';
export const TRACK_SOURCE_GRID = 'TRACK_SOURCE_GRID';
export const TRACK_SOURCE_TABLE = 'TRACK_SOURCE_TABLE';
export const TRACK_SOURCE_PLAYLIST = 'TRACK_SOURCE_PLAYLIST';


export function hasTrack (id, tracks) {
  if (tracks === null) return false;
  return tracks.some(function(track){
    return track.trackId === id;
  });
}

export function isLoadedPicks (trackId, peaksQueryTrackIds, peaksQuerySide, peaksQuery, side) {
  if (!peaksQuery)
    return true;
  if (peaksQuerySide !== side)
    return true;
  return !peaksQueryTrackIds.includes(trackId);
}

export function trackName (track) {
  return `${track.artist} - ${track.title}`;
}

export function isListTracksView (tracksView) {
  return tracksView === 'list';
}

export function isTableTracksView (tracksView) {
  return tracksView === 'table';
}

export function isGridTracksView (tracksView) {
  return tracksView === 'grid';
}

export function renderGenres(genreTracks) {
  return genreTracks ? genreTracks.join(", ") : '';
}

export function renderTrackDuration(duration) {
  if (duration) {
    let durations = duration.split(':');
    const min = durations[1];
    const sec = durations[2].split('.');
    return min + ":" + sec[0];
  }
}

export function getCollectTrack(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export const trackDragSource = {
  beginDrag(props) {
    return {
      track: props.track
    };
  },
  canDrag(props, monitor) {
    return props.playlist && props.playlist.playListId && props.playlist.playListId !== ''
      && !hasTrack(props.track.trackId, props.playlist.trackPlayLists);
  }
};

export function clearPlayer(playingWavesurfer) {
  if (playingWavesurfer) {
    NProgress.done();
    playingWavesurfer.stop();
    playingWavesurfer.destroy();
    playingWavesurfer = null;
  }
}

export function stopPlayer(playingWavesurfer) {
  if (playingWavesurfer) {
    playingWavesurfer.stop();
  }
}

export function calcRatingByUser(rating, newRatingValue, ratingCount) {
  return (rating * ratingCount + newRatingValue) / (ratingCount+1);
}
