import React, { Component } from 'react';
import TracksListItem from './TracksListItem.js';

class TracksList extends Component {

  render() {
    let {tracks,
      addTrackToPlaylist,
      removeTrackFromPlaylist,
      playlist,
      startPlayingTrack,
      playingTrack,
      playingWavesurfer,
      isPlayingTrackActive,
      stopPlayingTrack,
      playingTrackSource,
      updateCurrentTimeForPlayingTrack,
      fetchTrackPeaks,
      peaks,
      toggleLoader} = this.props;

    return (
      <div className="track-lists">
        {tracks.map((track, i) =>
          <TracksListItem key={track.trackId}
                          track={track}
                          addTrackToPlaylist={addTrackToPlaylist}
                          removeTrackFromPlaylist={removeTrackFromPlaylist}
                          playlist={playlist}
                          startPlayingTrack={startPlayingTrack}
                          stopPlayingTrack={stopPlayingTrack}
                          playingWavesurfer={playingWavesurfer}
                          playingTrack={playingTrack}
                          isPlayingTrackActive={isPlayingTrackActive}
                          playingTrackSource={playingTrackSource}
                          updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                          fetchTrackPeaks={fetchTrackPeaks}
                          peaks={peaks}
                          toggleLoader={toggleLoader}/>
        )}
      </div>
    )
  }
}

export default TracksList;


