import React, { Component } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {connect} from "react-redux";
import * as feedUtils from '../../../utils/feeds';

import {ReactComponent as CalendarIcon} from '../../../icons/date_range.svg';
import {ReactComponent as ArrowLeftIcon} from '../../../icons/keyboard_arrow_left.svg';
import {ReactComponent as ArrowRightIcon} from '../../../icons/keyboard_arrow_right.svg';

const WEEKDAYS_SHORT = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
};
const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ]
};

const FIRST_DAY_OF_WEEK = {
  ru: 1
};

function Navbar({
                  month,
                  nextMonth,
                  previousMonth,
                  onPreviousClick,
                  onNextClick
                }) {
  return (
    <div className="calendar-controls calendar-container__controls">
      <button className="calendar-controls__arrow" onClick={() => onPreviousClick()}><ArrowLeftIcon className="svg-icon" /></button>
      <span className="calendar-controls__text">{MONTHS['ru'][month.getMonth()]}, {month.getFullYear()} год</span>
      <button className="calendar-controls__arrow" onClick={() => onNextClick()}><ArrowRightIcon className="svg-icon" /></button>
    </div>
  );
}

class FeedCalendar extends Component {

  constructor(props, context) {
    super(props, context);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {selectedDay: null};
    this.firstLoad = true;
  }

  isWeekend(day) {
    return (day.getDay() === 0 || day.getDay() === 6);
  }

  isWeekday(day) {
    return (day.getDay() !== 0 && day.getDay() !== 6);
  }

  componentWillReceiveProps(props) {
    const type = props.activeFeedDayType;
    if (feedUtils.isWeekdayFeedType(type)) {
      this.setState({selectedDay: this.isWeekday})
    } else if (feedUtils.isWeekendFeedType(type)) {
      this.setState({selectedDay: this.isWeekend})
    } else {
      const date = props.activeFeedDate ? props.activeFeedDate : new Date();
      this.setState({selectedDay: date})
    }
  }
  handleDayClick(day, { selected, disabled }) {
    const type = this.props.activeFeedDayType;
    if (!feedUtils.isOtherFeedType(type)) {
      return false
    }

    if (disabled) {
      return;
    }
    if (selected) {
      this.setState({ selectedDay: undefined });
      return;
    }
    this.setState({ selectedDay: day }, () => {
      this.props.changeFeedDayType(feedUtils.OTHER_FEED_TYPE, this.props.activeFeed, day);
    });
  }

  render() {
    const {activeFeed, activeFeedDayType, isGeneralHeaderFeedPlaying} = this.props;
    return (
      <span>
        {
          (activeFeed && feedUtils.isFeedCalendarShown(activeFeedDayType)) &&
          <span className="feed-calendar-wrap">
            {
              isGeneralHeaderFeedPlaying &&
              <div className="general-feed-playing-stub" />
            }
            <div className="right-heading">
                          <span className="right-heading__icon right-heading__icon_calendar">
                              <CalendarIcon className="svg-icon" />
                          </span>
              <span className="right-heading__text">Календарь</span>
            </div>

            <div className = "calendar-container" >
              <DayPicker onDayClick={this.handleDayClick}
                         selectedDays={this.state.selectedDay}
                         showOutsideDays
                         locale="ru"
                         months={MONTHS['ru']}
                         weekdaysShort={WEEKDAYS_SHORT['ru']}
                         firstDayOfWeek={FIRST_DAY_OF_WEEK['ru']}
                         navbarElement={<Navbar />}
                         classNames={{
                           day: 'calendar__item',
                           selected: 'calendar__item calendar__item_bg_blue calendar__item_color_white',
                           disabled: 'calendar__item calendar__item_color_grey',
                           navBar: 'calendar-controls calendar-container__controls',
                           navButtonPrev: 'calendar-controls__arrow',
                           navButtonNext: 'calendar-controls__arrow',
                           caption: '',
                           month: '',
                           outside: 'DayPicker-Day--outside',
                           today: 'calendar__item calendar__item_bg_grey calendar__item_color_white',
                           weekday: 'calendar__item',
                           weekdays: '',
                           body: 'DayPicker-Body',
                           week: '',
                           weekdaysRow: 'calendar__content calendar__content_head'
                         }}
              />
            </div>
          </span>
        }
      </span>
    )
  }
}

export default connect(state => ({  activeFeed: state.feed.activeFeed,
  activeFeedDayType: state.feed.activeFeedDayType, activeFeedDate: state.feed.activeFeedDate,
  isGeneralHeaderFeedPlaying: state.feed.isGeneralHeaderFeedPlaying}))(FeedCalendar);
