import React, { Component } from 'react';
import * as playlistUtils from '../../../utils/playlists';

import {ReactComponent as LibraryMusicIcon} from '../../../icons/library_music.svg';

class Playlists extends Component {

  showPlaylistItem(playlistId) {
    this.props.showPlaylistItem(playlistId);
  }

  render() {
    let {playlists} = this.props;
    return (
      <div className="playlist-info">
        <h3 className="playlist-info__heading"></h3>
        <p className="playlist-info__hint">Выберите плейлист, в который собираетесь добавлять музыкальные композиции.
            <br/> Для добавления выберите трек и нажмите на иконку добавления в плейлист или перетащите композицию в правую область.</p>
        <div className="playlist-scroll playlist-info__playlist-scroll">
           {playlists.map((playlist, i) =>
              <div key={playlist.playListId} className="playlist-scroll__item"
                   onClick={this.props.showPlaylistItem.bind(this, playlist.playListId)}>
                  <div className="playlist-scroll__icon-wrap">
                      <LibraryMusicIcon className="playlist-scroll__icon svg-icon" />
                  </div>
                  <div className="playlist-scroll__text-wrap">
                      <div className="playlist-scroll__text-item">
                          <span className="playlist-scroll__text playlist-scroll__text_name">{playlist.name}</span>
                      </div>
                      <div className="playlist-scroll__text-item">
                          <span className="playlist-scroll__text">Количество треков:&nbsp;</span>
                          <span className="playlist-scroll__text playlist-scroll__text_value">{playlist.count}</span>
                      </div>
                      <div className="playlist-scroll__text-item">
                          <span className="playlist-scroll__text">Длительность:&nbsp;</span>
                          <span className="playlist-scroll__text playlist-scroll__text_value">{playlistUtils.renderDuration(playlist.duration)}</span>
                      </div>
                  </div>
              </div>
            )}
          </div>
      </div>
    )
  }
}
export default Playlists;
