import React, { Component } from 'react';
import StarsRating from '../../StarsRating.js';
import * as utils from "../../../utils/tracks";
import DragSource from "react-dnd/lib/DragSource";
import {renderGenres} from "../../../utils/tracks";

import {ReactComponent as CloseIcon} from '../../../icons/close.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';
import {ReactComponent as PlayIcon} from '../../../icons/play_arrow.svg';
import {ReactComponent as PlayListAddIcon} from '../../../icons/playlist_add.svg';

class TracksTableItem extends Component {

  constructor(props, context) {
    super(props, context);
    this.playClickHandler = this.playClickHandler.bind(this);
  }

  playClickHandler(e) {
    let {play, track, peaks} = this.props;
    play(track, e.target, peaks[track.trackId]);
  }

  render() {
    const {addTrackToPlaylist,
      removeTrackFromPlaylist,
      playlist,
      pause,
      track,
      isContentTrackActive,
      isTrackActive,
      isTrackSelected,
      connectDragSource
    } = this.props;
    const {title, artist, genreTracks, duration, rating, trackId} = this.props.track;
    const hasTrack = playlist && utils.hasTrack(trackId, playlist.trackPlayLists);
    return connectDragSource (
      <div className={"track-table-item " +
                        (isContentTrackActive(track) ?
                        'track-table-item_active' : '')}>
          <span className="track-table-item__text track-table-item__text_title">{title}</span>
          <span className="track-table-item__text track-table-item__text_artist">{artist}</span>
          <span className="track-table-item__text track-table-item__text_genre">
            {renderGenres(genreTracks)}
          </span>
          <span
            className="track-table-item__text track-table-item__text_time">{utils.renderTrackDuration(duration)}</span>

          <StarsRating rating={rating} className="track-table-item__rating"/>
          <div className="track-table-item__controls">
            {isContentTrackActive(track) &&
            <PauseIcon className="track-table-item__control-icon
                              track-table-item__control-icon_active
                              svg-icon"
                       onClick={pause}/>
            }
            {!isContentTrackActive(track) &&
            <PlayIcon className="track-table-item__control-icon
                  track-table-item__control-icon_play
                  track-table-item__control-icon_active
                  svg-icon"
                      onClick={this.playClickHandler}/>
            }
          </div>
          {hasTrack &&
          <div className="track-table-item__add track-table-item__remove"
               onClick={removeTrackFromPlaylist.bind(this, playlist, this.props.track)}>
            <CloseIcon className="track-table-item__add-icon svg-icon"/>
          </div>
          }
          {!hasTrack && playlist &&
          <div className="track-table-item__add" onClick={addTrackToPlaylist.bind(this, playlist, this.props.track)}>
            <PlayListAddIcon className="track-table-item__add-icon svg-icon"/>
          </div>
          }
      </div>
    )
  }
}

export default DragSource('playlist-track', utils.trackDragSource, utils.getCollectTrack)(TracksTableItem);


