import Feeds from '../components/Catalog/Feed/Feeds.js';
import { connect } from 'react-redux';
import {
  removeFeed,
  fetchFeeds, selectFeed,
  toggleSwitchOffPlaylist,
  toggleRandomPlaylist,
  toggleLoader,
  saveFeed,
  changeFeedDayType,
  updateFeedPlaylists,
  changeFeedDayTypeForm,
  changeFeedPlaylistPosition,
  removePlaylistFromFeed,
  startPlayingGeneralFeedPlaylist,
  startPlayingGeneralFeedTrack,
  startPlayingFeedTrack,
  stopPlayingFeedTrack, clearPlayingFeedTrack, updateCurrentTimeForPlayingFeedTrack, getGeneralFeedPlay
} from '../actions/feed';

import {
  clearPlayingTrack,
  deletePlaylist,
  fetchPlaylists, fetchTrackPeaks, fetchAllTrackPeaks, stopPlayingTrack,
  playbackStatistic
} from "../actions/catalog";

import {bindActionCreators} from "redux";

const mapDispatchToProps = (dispatch) => ({
  fetchFeeds: bindActionCreators(fetchFeeds, dispatch),
  selectFeed: bindActionCreators(selectFeed, dispatch),
  removeFeed: bindActionCreators(removeFeed, dispatch),
  toggleSwitchOffPlaylist: bindActionCreators(toggleSwitchOffPlaylist, dispatch),
  saveFeed: bindActionCreators(saveFeed, dispatch),
  toggleRandomPlaylist: bindActionCreators(toggleRandomPlaylist, dispatch),
  changeFeedDayType: bindActionCreators(changeFeedDayType, dispatch),
  updateFeedPlaylists: bindActionCreators(updateFeedPlaylists, dispatch),
  changeFeedDayTypeForm: bindActionCreators(changeFeedDayTypeForm, dispatch),
  startPlayingGeneralFeedPlaylist: bindActionCreators(startPlayingGeneralFeedPlaylist, dispatch),
  startPlayingGeneralFeedTrack: bindActionCreators(startPlayingGeneralFeedTrack, dispatch),
  startPlayingFeedTrack: bindActionCreators(startPlayingFeedTrack, dispatch),
  stopPlayingFeedTrack: bindActionCreators(stopPlayingFeedTrack, dispatch),
  clearPlayingFeedTrack: bindActionCreators(clearPlayingFeedTrack, dispatch),
  updateCurrentTimeForPlayingFeedTrack: bindActionCreators(updateCurrentTimeForPlayingFeedTrack, dispatch),
  fetchPlaylists: bindActionCreators(fetchPlaylists, dispatch),
  fetchTrackPeaks: bindActionCreators(fetchTrackPeaks, dispatch),
  deletePlaylist: bindActionCreators(deletePlaylist, dispatch),
  fetchAllTrackPeaks: bindActionCreators(fetchAllTrackPeaks, dispatch),
  changeFeedPlaylistPosition: bindActionCreators(changeFeedPlaylistPosition, dispatch),
  removePlaylistFromFeed: bindActionCreators(removePlaylistFromFeed, dispatch),
  stopPlayingTrack: bindActionCreators(stopPlayingTrack, dispatch),
  clearPlayingTrack: bindActionCreators(clearPlayingTrack, dispatch),
  toggleLoader: bindActionCreators(toggleLoader, dispatch),
  getGeneralFeedPlay: bindActionCreators(getGeneralFeedPlay, dispatch),
  playbackStatistic: bindActionCreators(playbackStatistic, dispatch)
});

function mapStateToProps(state) {
  return {
    peaks: state.catalog.peaks,
    feeds: state.feed.feeds,
    playlists: state.catalog.playlists,
    activeFeed: state.feed.activeFeed,
    playingFeedTrack: state.feed.playingFeedTrack,
    playingFeedPlaylist: state.feed.playingFeedPlaylist,
    playingFeedTrackIndex: state.feed.playingFeedTrackIndex,
    activeFeedDayType: state.feed.activeFeedDayType,
    playingWavesurfer: state.catalog.playingWavesurfer,
    playingFeedWavesurfer: state.feed.playingFeedWavesurfer,
    generalHeaderFeed: state.feed.generalHeaderFeed,
    isGeneralHeaderFeedPlaying: state.feed.isGeneralHeaderFeedPlaying
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feeds);
