import React from 'react';

export default class LayoutAside extends React.Component
{
  static defaultProps = {
	  klass: 'layout__left-side',
  	innerKlass: 'sidebar j-sidebar'
  };

  render() {
    return (
      <aside className={this.props.klass}>
        <div className={this.props.innerKlass}>
          {this.props.children}
        </div>
      </aside>
    );
  }
};
