import React from 'react';
import { reduxForm, Field } from 'redux-form';
import renderField from '../renderField';
import connect from "react-redux/es/connect/connect";

import UrlHelpers from '../../helpers/Url';

const validateAndSaveInfo = (values, _) => {
  if (values.anketaUrl === '') {
    return;
  }
  return UrlHelpers.openTab(values.anketaUrl);
};

class UpdateCompanyInfoForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.firstLoad = true;
    this.state = {
      editable: false
    };
  }

  componentDidMount() {
    this.props.fetchUserFullInfo();
  }

  componentWillReceiveProps(nextProps) {
    if (this.firstLoad && Object.keys(nextProps.userInfo).length > 0) {
      this.firstLoad = false;
      const {editable} = nextProps.userInfo;
      this.setState({ editable }, () => {
        this.props.initialize(nextProps.userInfo);
      });
    }
  }

  render() {
    const {handleSubmit,} = this.props;
    const {editable} = this.state;

    return (
        <form className="account__form" method="post" onSubmit={ handleSubmit(validateAndSaveInfo) }>

          <Field
            name="companyName"
            type="text"
            component={ renderField }
            disabled={true}
            label="Наименование юр. лица"
            placeholder="" />

          <Field
            name="contractNumber"
            type="text"
            component={ renderField }
            disabled={true}
            label="Номер договора"
            placeholder="" />

            <div className="inputbox inputbox_header">
                <div className="inputbox__left">
                    <p className="text-small">
                        <b />
                    </p>
                </div>
                <div className="inputbox__right">
                    <button className="button button_color_blue button_size_big button_weight_normal account__button"
                            type="submit"
                            disabled={!editable}
                    >РЕДАКТИРОВАТЬ АНКЕТУ</button>
                </div>
            </div>
        </form>
    );
  }
}

UpdateCompanyInfoForm = reduxForm({
  form: 'UpdateCompanyInfoForm'
})(UpdateCompanyInfoForm);

export default connect(
  state => ({
    initialValues: state.user.userInfo
  })
)(UpdateCompanyInfoForm)



