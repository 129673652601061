import {
  FETCH_COMPILATIONS_SUCCESS,
  FETCH_COMPILATIONS_FAILURE,
  SELECT_COMPILATION_FAILURE,
  SELECT_COMPILATION_SUCCESS,
  CHANGE_COMPILATION_TRACKS_VIEW_TYPE,
  DESELECT_COMPILATION, CREATE_PLAYLIST_FROM_COMPILATION_FAILURE,
  CREATE_PLAYLIST_FROM_COMPILATION_SUCCESS,
  VIEW_COMPILATION_SUCCESS
} from '../actions/compilations';

const INITIAL_STATE = {error: null, compilations: [], activeCompilation: null, tracksViewType: 'grid'};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COMPILATIONS_FAILURE:
      return {...state, compilations: [], error: action.payload};

    case FETCH_COMPILATIONS_SUCCESS:
      return {...state, compilations: action.payload, error: null};
    case SELECT_COMPILATION_SUCCESS:
      return {...state, activeCompilation: action.payload, error: null};
    case SELECT_COMPILATION_FAILURE:
      return {...state, activeCompilation: null, error: action.payload};
    case CHANGE_COMPILATION_TRACKS_VIEW_TYPE:
      return {...state, tracksViewType: action.payload};
    case DESELECT_COMPILATION:
      return {...state, activeCompilation: null};
    case CREATE_PLAYLIST_FROM_COMPILATION_FAILURE:
      return {...state, error: action.payload};
    case CREATE_PLAYLIST_FROM_COMPILATION_SUCCESS:
      return {...state, playlistAction: 'LIST', playlist: null};
    case VIEW_COMPILATION_SUCCESS:
      return {...state};
    default:
      return state;
  }
}
