import React, { Component } from 'react';
import FeedFilter from './FeedFilter';
import FeedPlaylist from './FeedPlaylist';

class Feeds extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      collapsedPlaylists: []
    };

    this.toggleCollapsePlaylist = this.toggleCollapsePlaylist.bind(this);
    this.selectFeedHandler = this.selectFeedHandler.bind(this);
    this.removeFeedHandler = this.removeFeedHandler.bind(this);
    this.playNextPlaylist = this.playNextPlaylist.bind(this);
    this.collapseNotActiveFeedPlaylists = this.collapseNotActiveFeedPlaylists.bind(this);

    this.isFirstLoad = true;
  }

  componentWillMount() {
    this.props.fetchFeeds(true);
    this.props.fetchPlaylists();
    if (this.props.playingWavesurfer) {
      this.props.playingWavesurfer.pause();
      this.props.stopPlayingTrack();
      this.props.clearPlayingTrack();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.isFirstLoad && nextProps.activeFeed) {
      this.isFirstLoad = false;
      this.collapseNotActiveFeedPlaylists(nextProps.activeFeed);
    }
  }

  deletePlaylist(id) {
    this.props.deletePlaylist(id);
  }

  collapseNotActiveFeedPlaylists(feed) {
    let collapsedPlaylists = [];
    feed.playlists.forEach((playlist) => {
      if (!playlist.active) {
        collapsedPlaylists.push(playlist.playListId);
      }
    });
    this.setState({collapsedPlaylists});
  }

  toggleCollapsePlaylist(playlistId) {
    let collapsedPlaylists = this.state.collapsedPlaylists.slice(0);
    if (collapsedPlaylists.includes(playlistId)) {
      collapsedPlaylists.splice(collapsedPlaylists.indexOf(playlistId), 1);
    } else {
      collapsedPlaylists.push(playlistId);
    }

    this.setState({collapsedPlaylists});
  }

  clearPlayingFeedTrack() {
    this.props.clearPlayingFeedTrack(this.props.playingFeedWavesurfer);
  }

  removeFeedHandler(feed) {
    if (feed === null) return;
    this.props.removeFeed(feed);
  }

  selectFeedHandler(feed) {
    this.clearPlayingFeedTrack();
    this.props.selectFeed(true, feed.feedId);
    this.props.getGeneralFeedPlay(feed.feedId);
    this.collapseNotActiveFeedPlaylists(feed);
  }

  playNextPlaylist(nextTrack, nextPlaylist) {
    let feedPlaylist = this['feed_playlist_' + nextPlaylist.playListId];
    if (feedPlaylist) {
      feedPlaylist.getWrappedInstance().play(nextTrack, nextPlaylist, 0);
    }
  }

  render() {
    let {feeds,
      activeFeed,
      toggleRandomPlaylist,
      toggleSwitchOffPlaylist,
      saveFeed,
      playlists,
      playbackStatistic,
      changeFeedDayType,
      addPlaylistsToFeed,
      showFeedCalendar,
      hideFeedCalendar,
      removePlaylistFromFeed,
      updateFeedPlaylists,
      changeFeedDayTypeForm,
      activeFeedDayType,
      fetchTrackPeaks,
      fetchAllTrackPeaks,
      toggleLoader,
      startPlayingFeedTrack,
      stopPlayingFeedTrack,
      updateCurrentTimeForPlayingFeedTrack,
      clearPlayingFeedTrack,
      changeFeedPlaylistPosition,
      playingFeedTrackIndex,
      isGeneralHeaderFeedPlaying} = this.props;
    const {collapsedPlaylists} = this.state;

    return (
      <span>
        {
          isGeneralHeaderFeedPlaying &&
          <div className="general-feed-playing-stub" />
        }
        <div className="feed">

          {
            <FeedFilter feeds={feeds} activeFeedDayType={activeFeedDayType}
                        activeFeed={activeFeed} selectFeedHandler={this.selectFeedHandler} saveFeed={saveFeed}
                        playlists={playlists} changeFeedDayType={changeFeedDayType}
                        changeFeedDayTypeForm={changeFeedDayTypeForm}
                        addPlaylistsToFeed={addPlaylistsToFeed}
                        showFeedCalendar={showFeedCalendar}  hideFeedCalendar={hideFeedCalendar}
                        clearPlayingFeedTrack={clearPlayingFeedTrack}
                        updateFeedPlaylists={updateFeedPlaylists}
                        removeFeed={this.removeFeedHandler}
                        deletePlaylist={this.deletePlaylist.bind(this)}
            />
          }
          {
            activeFeed &&
            <div className="feed__playlists" >
              {activeFeed.playlists.map((playlist, i) =>
                <FeedPlaylist key={playlist.playListId + activeFeed.feedId}
                              playlist={playlist}
                              index={i + 1}
                              removePlaylistFromFeed={removePlaylistFromFeed}
                              collapsedPlaylists={collapsedPlaylists}
                              toggleCollapsePlaylist={this.toggleCollapsePlaylist}
                              toggleRandomPlaylist={toggleRandomPlaylist}
                              toggleSwitchOffPlaylist={toggleSwitchOffPlaylist}
                              fetchTrackPeaks={fetchTrackPeaks}
                              fetchAllTrackPeaks={fetchAllTrackPeaks}
                              toggleLoader={toggleLoader}
                              playbackStatistic={playbackStatistic}
                              startPlayingFeedTrack={startPlayingFeedTrack}
                              stopPlayingFeedTrack={stopPlayingFeedTrack}
                              updateCurrentTimeForPlayingFeedTrack={updateCurrentTimeForPlayingFeedTrack}
                              playNextPlaylist={this.playNextPlaylist}
                              clearPlayingFeedTrack={clearPlayingFeedTrack}
                              changeFeedPlaylistPosition={changeFeedPlaylistPosition}
                              playingFeedTrackIndex={playingFeedTrackIndex}
                              ref={ref => { this['feed_playlist_' + playlist.playListId] = ref; }}
                />
              )}
            </div>
          }
        </div>
      </span>
    )
  }
}

export default Feeds;
