import React, { Component } from 'react';

export default class FeedForm extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      name: ''
    };

    this.nameChangedHandler = this.nameChangedHandler.bind(this);
    this.saveFeedHandler = this.saveFeedHandler.bind(this);
  }

  nameChangedHandler(e) {
    this.setState({name: e.target.value});
  }

  saveFeedHandler(event) {
    event.preventDefault();
    this.props.saveFeedHandler(this.state.name);
  }

  render() {
    const {name} = this.state;
    return (
      <form className="feed-new__create-form" onSubmit={this.saveFeedHandler}>
        <input value={name} className="feed-new__input" type="text"
                maxLength={20}
                placeholder="Введите название новой ленты" onChange={this.nameChangedHandler} />
        <input className="feed-new__submit" type="submit" value="OK" />
      </form>
    )
  }
}

