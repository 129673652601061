import { ReactComponent as Sun } from "../../images/Sun.svg";
import { ReactComponent as Moon } from "../../images/Moon.svg";
import React from 'react'
import '../../index.css';

const DayNight = ({selectedTheme = 'light', toggleTheme = () => {}}) => {
    return (
        <div className='toggle-switch'>
            <label className='switch'>
                <input type='checkbox' onChange={toggleTheme} defaultChecked={selectedTheme === "dark"}/>
                <span  className='slider'></span>
            </label>
        </div>
    );
};

export default DayNight;
 