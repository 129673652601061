import PlayerRenderer from "../components/Catalog/Player/PlayerRenderer";
import UrlHelpers from "../helpers/Url";

export const PRELOADED_TRACKS_COUNT = 1;

export function generateWaveGradient(opacity, width=720) {
  let ctx = document.createElement('canvas').getContext('2d');
  let linGrad = ctx.createLinearGradient(0,0, width,0);
  linGrad.addColorStop(0, 'rgba(169,120,229, ' + opacity + ')');
  linGrad.addColorStop(0.2, 'rgba(137,140,223, ' + opacity + ')');
  linGrad.addColorStop(0.4, 'rgba(106,159,217, ' + opacity + ')');
  linGrad.addColorStop(0.6, 'rgba(76,178,211, ' + opacity + ')');
  linGrad.addColorStop(0.8, 'rgba(44,198,204, ' + opacity + ')');
  linGrad.addColorStop(1, 'rgba(0,225,195, ' + opacity + ')');
  return linGrad;
}

export function generateWaveColor(opacity) {
  return 'rgba(154, 173, 200, ' + opacity + ')';
}

export function loadWavesurfer(wavesurfer, audio, trackPeaks, preload) {
  wavesurfer.load(audio, trackPeaks, preload);
  patchWavesurfer(wavesurfer);
  setPeaks(wavesurfer, trackPeaks);
}

export function getWavesurferInitOptions(container, height, backend='MediaElement') {
  return {
    container: container,
    responsive: false,
    scrollParent: false,
    progressColor: '#cbc9ca',
    cursorColor: '#ddd',
    height: height,
    minPxPerSec: 1,
    barWidth: 2,
    cursorWidth: 0,
    normalize: true,
    waveColor: '#d9e4f2',
    renderer: PlayerRenderer,
    backend: backend
  };
}

export function wavesurferDestroyed(wavesurfer) {
  return !wavesurfer || wavesurfer.isDestroyed;
}

export function getFeedPlaylistWaveContainer(playlist) {
  let waveContainerSelector = '#playlist-' + playlist.playListId + ' .track-list__equalizer';
  return document.querySelector(waveContainerSelector);
}

export function formatTime(time) {
  return [
    Math.floor((time % 3600) / 60), // minutes
    ('00' + Math.floor(time % 60)).slice(-2) // seconds
  ].join(':');
}

export function trackUrl(audioFile) {
  return UrlHelpers.baseAudio() + '/' + audioFile;
}

function patchWavesurfer(wavesurfer) {
  wavesurfer.backend.seekTo = function (start) {
    if (start != null && !isNaN(start)) {
      wavesurfer.backend.media.currentTime = start;
    }

    wavesurfer.backend.clearPlayEnd();
  };
}

export function loadTrack(wavesurfer, audioFile) {
  const url = trackUrl(audioFile);
  wavesurfer.getArrayBuffer(url, data => wavesurfer.loadArrayBuffer(data));
}

export function setPeaks(wavesurfer, peaks) {
  if (peaks) {
    wavesurfer.backend.peaks = peaks;
  }
  wavesurfer.drawBuffer();
}

export function elementTrack(audioFile) {
  let audio = document.createElement('audio');
  audio.id = 'allTracksAudio';
  audio.src = UrlHelpers.baseAudio() + '/' + audioFile;
  audio.crossOrigin = 'anonymous';
  audio.preload = 'auto';
  return audio;
}

export function removePreloadedTracks() {
  const removeElements = (elms) => elms.forEach(el => el.remove());
  removeElements( document.querySelectorAll(".feedAudioPreload") );
}

export function removePreloadedTracksId(id) {
  const removeElements = (elms) => elms.forEach(el => el.remove());
  removeElements( document.querySelectorAll(".feedAudioPreload" + id) );
}

export function preloadPlayerTrack(nextTrack, id) {
  if (!document.getElementById(nextTrack.trackId)) {
    setTimeout(() => {
      removePreloadedTracksId(id);
      let preloadedAudio = document.createElement("audio");
      preloadedAudio.id = nextTrack.trackId;
      preloadedAudio.className = 'feedAudioPreload feedAudioPreload' + id;
      preloadedAudio.src = UrlHelpers.baseAudio() + '/' + nextTrack.audioFile;
      preloadedAudio.crossOrigin = "anonymous";
      document.body.appendChild(preloadedAudio);
    }, 100);
  }
}
