import React, { Component } from 'react';
import * as playlistUtils from '../../../utils/playlists';
import {toLocaleDateString} from "../../../utils/dates";

class EditPlaylist extends Component {

  componentWillMount() {
    let {playlist} = this.props;
    this.setState({name: playlist.name, dayTime: playlist.timeOfDay});
  }

  onNameChange(event) {
    this.setState({name: event.target.value});
  }

  updatePlaylist(event) {
    let {name, dayTime} = this.state;
    this.props.updatePlaylist(name, dayTime, this.props.playlist);
  }

  handleDayTimeChange(event) {
    this.setState({
      dayTime: +event.target.value
    });
  }

  render() {
    let {playlist} = this.props;
    let {dayTime} = this.state;
    return (
      <div className="playlist-info">
          <h3 className="playlist-info__heading">Информация о плейлисте</h3>
          <div className="playlist-info__tag-container">
              <input className="playlist-info__name" value={this.state.name} type="text" onChange={this.onNameChange.bind(this)}/>
          </div>
          <div className="playlist-info__tag-container">
              <label className="playlist-info__radio-wrap">
                  <input className="playlist-info__control" type="radio" name="time-tag" checked={playlistUtils.isMorningTime(dayTime)}
                         value={playlistUtils.MORNING_TIME} onChange={this.handleDayTimeChange.bind(this)} />
                  <span className="playlist-info__time-tag playlist-info__time-tag_round_left">Утро</span>
              </label>
              <label className="playlist-info__radio-wrap">
                  <input className="playlist-info__control" type="radio" name="time-tag" checked={playlistUtils.isDayTime(dayTime)}
                         value={playlistUtils.DAY_TIME} onChange={this.handleDayTimeChange.bind(this)} />
                  <span className="playlist-info__time-tag">День</span>
              </label>
              <label className="playlist-info__radio-wrap">
                  <input className="playlist-info__control" type="radio" name="time-tag" checked={playlistUtils.isEveningTime(dayTime)}
                         value={playlistUtils.EVENING_TIME} onChange={this.handleDayTimeChange.bind(this)} />
                  <span className="playlist-info__time-tag">Вечер</span>
              </label>
              <label className="playlist-info__radio-wrap">
                  <input className="playlist-info__control" type="radio" name="time-tag" checked={playlistUtils.isNightTime(dayTime)}
                         value={playlistUtils.NIGHT_TIME} onChange={this.handleDayTimeChange.bind(this)} />
                  <span className="playlist-info__time-tag playlist-info__time-tag_round_right">Ночь</span>
              </label>
          </div>
          <div className="playlist-about playlist-info__about">
              <div className="playlist-about__item">
                  <span>Создан: </span>
                {/* TODO:: format date */}
                  <span className="playlist-about__value">{toLocaleDateString(playlist.createDate)}</span>
              </div>
              <div className="playlist-about__item">
                  <span>Последнее изменение: </span>
                  <span className="playlist-about__value">{toLocaleDateString(playlist.changeDate)}</span>
              </div>
              <div className="playlist-about__item">
                  <span>Количество треков: </span>
                  <span className="playlist-about__value playlist-about__value_bolder">{playlist.trackPlayLists.length}</span>
              </div>
              <div className="playlist-about__item">
                  <span>Продолжительность: </span>
                  <span className="playlist-about__value">{playlistUtils.renderDuration(playlist.duration)}</span>
              </div>
              {/*TODO:: add real info*/}
              {/*<div className="playlist-about__item">*/}
                  {/*<span>Проигровался: </span>*/}
                  {/*<span className="playlist-about__value">23 часа 18 минуты назад</span>*/}
              {/*</div>*/}
              {/*<div className="playlist-about__item">*/}
                  {/*<span>Скачан: </span>*/}
                  {/*<span className="playlist-about__value">1 раз</span>*/}
              {/*</div>*/}
              <div className="playlist-about__item">
                  <span>Автор: </span>
                  <span>{playlist.userName}</span>
              </div>
          </div>
          <a className="button button_color_blue button_size_huge playlist-info__button"
             onClick={this.updatePlaylist.bind(this)}>Сохранить изменения</a>
          <a className="button button_color_grey button_size_huge playlist-info__button"
             onClick={this.props.cancelUpdatePlaylist}>Вернуться в плейлист</a>
      </div>
    )
  }
}

export default EditPlaylist;
