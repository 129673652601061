import SignInForm from '../components/SignInForm.js';
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.user
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
