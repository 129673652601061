import React from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {createTicket} from "../../actions/users";

class HelpForm extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      message: '',
      ticketType: 6,
      header: ''
    };

    this.createTicket = this.createTicket.bind(this);
    this.onHeaderChange = this.onHeaderChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onTicketTypeChange = this.onTicketTypeChange.bind(this);
  }

  createTicket(e) {
    e.preventDefault();
    const header = this.state.header;
    const msg = this.state.message;
    const ticketType = this.state.ticketType;
    if (msg && msg !== '' && header !== '' && ticketType !== null) {
      this.props.createTicket(header, msg, ticketType);
    }
  }

  onMessageChange(e) {
    this.setState({message: e.target.value});
  }

  onTicketTypeChange(e) {
    this.setState({ticketType: e.target.value});
  }

  onHeaderChange(e) {
    this.setState({header: e.target.value});
  }

  render() {
    return (
      <div className="big-page-header layout__content help__header">
        { false &&
          <div className="big-page-header__container">
            <h1 className="heading-main">Создайте тикет, задайте свой вопрос</h1>
            <form className="help__form">
              <select onChange={this.onTicketTypeChange}
                      defaultValue={this.state.ticketType}
                      className="input help__select">
                <option value="6">Вопрос</option>
                <option value="7">Составление плейлиста</option>
                <option value="5">Скачивание плейлиста</option>
              </select>
              <input value={this.state.header}
                     onChange={this.onHeaderChange}
                     className="input help__input"
                     placeholder="Введите Ваш вопрос"/>
              <textarea value={this.state.message}
                        onChange={this.onMessageChange}
                        className="input help__textarea"
                        placeholder="Описание вопроса"/>
              <button className="button button_color_blue button_size_big help__send" onClick={this.createTicket}>
                <span>Отправить</span>
              </button>
            </form>
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  createTicket: bindActionCreators(createTicket, dispatch)
});

export default connect(state => ({}),
  mapDispatchToProps)(HelpForm);
