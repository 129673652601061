import React from 'react';

import { ReactComponent as PdfIcon} from '../../icons/picture_as_pdf.svg';
import TicketForm from "./TicketForm";

export default class AccountTabContent2 extends React.Component
{
  render() {
    return (
        <TicketForm />
    );
  }
};
