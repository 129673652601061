import React from 'react';

const StoreButton = ({ type, disabled, onClick }) => {
    const buttonClass = `stores__button stores__button--${type}`;
  
    if (disabled) {
        return <span className={buttonClass}></span>;
    }

    let href;
    switch (type) {
        case 'google':
            href = 'https://play.google.com/store/apps/details?id=com.mubicloud.app';
            break;
        case 'rustore':
            href = 'https://apps.rustore.ru/app/com.mubicloud.app';
            break;
        case 'apple':
            href = 'https://www.apple.com/app-store/';
            break;
        default:
            break;
    }
  
    return (
      <a
        href={href}
        className={buttonClass}
        target="_blank"
        rel="noopener noreferrer"
        // onClick={onClick}
      ></a>
    );
  };
  
  export default StoreButton;