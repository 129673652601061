import {Component} from "react";
import React from "react";
import UrlHelpers from "../../../helpers/Url";
import {getTimeOfDayTitle} from "../../../utils/playlists";
import connect from "react-redux/es/connect/connect";
import * as utils from "../../../utils/tracks";

import {ReactComponent as PlayIcon} from '../../../icons/play_circle_outline.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';

class FeedPlaylistPlayer extends Component {

  constructor(props, context) {
    super(props, context);
    this.playTrackClickHandler = this.playTrackClickHandler.bind(this);
    this.playPlaylistClickHandler = this.playPlaylistClickHandler.bind(this);
  }

  playTrackClickHandler() {
    const {playingFeedWavesurfer, playingFeedTrack, playlist} = this.props;
    playingFeedWavesurfer.play();
    this.props.startPlayingFeedTrack(playingFeedTrack, playingFeedWavesurfer, undefined, playlist);
  }

  playPlaylistClickHandler(playlist) {
    if (playlist.tracks.length > 0) {
      this.props.play(playlist.tracks[0], playlist, 0);
    }
  }

  render() {
    const {playlist, playingFeedTrackIndex, playingFeedTrack, pauseClickHandler, play, loading,
      isCurrentPlaylistTrackSelected, isCurrentPlaylistTrackPaused, isCurrentPlaylistTrackPlaying, currentTimePlayingFeedTrack} = this.props;
    let imageFileFix;
    if(isCurrentPlaylistTrackSelected() && playingFeedTrack.imageFile && playingFeedTrack.imageFile !== '')
      imageFileFix = playingFeedTrack.imageFile.replace(".", "_mini.");
    if(!isCurrentPlaylistTrackSelected() || !playingFeedTrack.imageFile || playingFeedTrack.imageFile === '') {
      const trackImage = playlist.tracks.find(track => track.imageFile !== null);
      if (trackImage !== undefined) {
        imageFileFix = trackImage.imageFile.replace(".", "_mini.");
      }
    }

    return (

      <div className="track-list__content">
        <div className="track-list__image-wrap track-list__image-wrap_icon">
          <span>
            {isCurrentPlaylistTrackSelected() && playingFeedTrack.imageFile && playingFeedTrack.imageFile !== '' &&
              <img className="track-list__image" src={UrlHelpers.baseAudioImg() + '/' + imageFileFix} alt="track"  />
            }
            {(!isCurrentPlaylistTrackSelected() || !playingFeedTrack.imageFile || playingFeedTrack.imageFile === '') &&
              <img className="track-list__image" src={UrlHelpers.baseAudioImg() + '/' + imageFileFix} alt="track"  />
            }
          </span>
        </div>
        <div className="track-list__player">
          {
            playingFeedTrack.duration &&
            <span className="track-list__time">
              {isCurrentPlaylistTrackPlaying() ? currentTimePlayingFeedTrack : utils.renderTrackDuration(playingFeedTrack.duration)}
            </span>
          }

          {
            playlist.timeOfDay &&
            <span className="track-list__tag">{getTimeOfDayTitle(playlist.timeOfDay)}</span>
          }

          <div className="player-wave-wrap">
            {
              loading &&
              <div className="player-wave-stub"></div>
            }
            <div className="track-list__equalizer">
            </div>
          </div>

          <div className="track-list__player-info">
            <div className="track-list__controls">
              {isCurrentPlaylistTrackPlaying() &&
                <PauseIcon className="player__control-icon svg-icon" onClick={pauseClickHandler} />
              }
              {isCurrentPlaylistTrackPaused() &&
                <PlayIcon className="player__control-icon player__control-icon_play svg-icon"
                          onClick={play.bind(this, playingFeedTrack, playlist, playingFeedTrackIndex)} />
              }
              {!isCurrentPlaylistTrackSelected() &&
                <PlayIcon className="player__control-icon player__control-icon_play svg-icon"
                          onClick={this.playPlaylistClickHandler.bind(this, playlist)} />
              }
            </div>
            {isCurrentPlaylistTrackSelected() &&
            <span className="track-list__title">
              {playingFeedTrack.name}
            </span>
            }
            { !isCurrentPlaylistTrackSelected() &&
            <span className="track-list__title">
              {playlist.name}
            </span>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({  playingFeedWavesurfer: state.feed.playingFeedWavesurfer,
  playingFeedTrack: state.feed.playingFeedTrack,
  isPlayingFeedTrackActive: state.feed.isPlayingFeedTrackActive,
  currentTimePlayingFeedTrack: state.feed.currentTimePlayingFeedTrack,
  playingFeedPlaylist: state.feed.playingFeedPlaylist, loading: state.feed.loading,
  playingFeedTrackIndex: state.feed.playingFeedTrackIndex}))(FeedPlaylistPlayer);

