import React, { Component } from 'react';
import * as feedUtils from '../../../utils/feeds';
import CreateFeedForm from './CreateFeedForm';
import * as playlistUtils from "../../../utils/playlists";
import {ReactComponent as AddIcon} from '../../../icons/add.svg';
import {ReactComponent as CloseIcon} from '../../../icons/close.svg';
import {ReactComponent as TrashIcon} from '../../../icons/delete.svg';
import {ReactComponent as QueueMusicIcon} from '../../../icons/queue_music.svg';
import {ReactComponent as CheckIcon} from '../../../icons/check.svg';
import {ReactComponent as ArrowDownIcon} from '../../../icons/keyboard_arrow_down.svg';
import {ReactComponent as CalendarIcon} from '../../../icons/date_range.svg';
// import EmptyPlaylists from '../Playlists/EmptyPlaylists';
// import CreatePlaylist from '../Playlists/CreatePlaylist';
// import PlaylistItem from '../Playlists/PlaylistItem';
import CreeateFeedForm from './CreateFeedForm'; 
import FeedForm from './FeedForm'; 

import {ReactComponent as PlayListAddIcon} from '../../../icons/playlist_add.svg';
 

class FeedFilter extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      selectFeedBoxActive: false,
      deleteFeedBoxActive: false,
      selectPlaylistBoxActive: false,
      createFeedFormShown: false,
      editCalendarFormShown: false
    };

    this.clickFilterSelectBoxHandler = this.clickFilterSelectBoxHandler.bind(this);
    this.clickPlaylistSelectBoxHandler = this.clickPlaylistSelectBoxHandler.bind(this);
    this.clickCalendarSelectBoxHandler = this.clickCalendarSelectBoxHandler.bind(this);
    this.createFeed = this.createFeed.bind(this);
    this.removeFeedHandler = this.removeFeedHandler.bind(this);
    this.cancelCreateFeed = this.cancelCreateFeed.bind(this);
    this.saveFeedHandler = this.saveFeedHandler.bind(this);
    this.selectPlaylistHandler = this.selectPlaylistHandler.bind(this);
    this.getFeedPlaylistsIds = this.getFeedPlaylistsIds.bind(this);
    this.getPlaylistsTags = this.getPlaylistsTags.bind(this);
    this.isFeedContainsPlaylist = this.isFeedContainsPlaylist.bind(this);
  }

  selectFeedHandler(feed) {
    this.setState({deleteFeedBoxActive: false}, () => {
      this.props.selectFeedHandler(feed);
    });
  }

  clickCalendarSelectBoxHandler(e) {
    const {editCalendarFormShown} = this.state;
    this.setState({editCalendarFormShown: !editCalendarFormShown, selectPlaylistBoxActive: false, selectFeedBoxActive: false});
  }

  clickFilterSelectBoxHandler(e) {
    const {selectFeedBoxActive} = this.state;
    this.setState({selectFeedBoxActive: !selectFeedBoxActive, selectPlaylistBoxActive: false, editCalendarFormShown: false});
  }

  clickPlaylistSelectBoxHandler(e) {
    const {selectPlaylistBoxActive} = this.state;
    this.setState({selectPlaylistBoxActive: !selectPlaylistBoxActive, selectFeedBoxActive: false, editCalendarFormShown: false});
  }

  dayTypeCheckboxChanged(feedType, e) {
    if (this.props.activeFeed === null) return;
    const checked = e.target.checked;
    if (checked) {
      this.props.changeFeedDayTypeForm(feedType);
      if (!feedUtils.isOtherFeedType(feedType)) {
        this.props.changeFeedDayType(feedType, this.props.activeFeed);
      }
    } else {
      this.props.changeFeedDayType(0, this.props.activeFeed);
    }
  }

  createFeed() {
    this.setState({createFeedFormShown: true});
  }

  removeFeedHandler(feed, e) {
    e.stopPropagation();
    const {removeFeed} = this.props;
    this.setState({selectFeedBoxActive: false}, () => {
      removeFeed(feed);
    });
  }

  cancelCreateFeed() {
    this.setState({createFeedFormShown: false});
  }

  saveFeedHandler(name) {
    this.setState({createFeedFormShown: false}, () => {
      this.props.saveFeed(name);
      this.props.clearPlayingFeedTrack();
    });
  }

  isFeedContainsPlaylist(playlist) {
    const feed = this.props.activeFeed;
    if (feed === null) return false;
    if (feed === undefined) return false;
    return feed.playlists.find(function(p) {
      return p.playListId === playlist.playListId;
    });
  }

  selectPlaylistHandler(playlist) {
    let feed = this.props.activeFeed;
    if (feed === null) return;
    playlist.active = true;
    this.props.updateFeedPlaylists(feed, playlist);
  }

  getFeedPlaylistsIds() {
    let ids = [];
    if (this.props.activeFeed === null || this.props.activeFeed === undefined) return [];
    this.props.activeFeed.playlists.map(function(pl) {
      ids.push(pl.playListId);
    });
    return ids;
  }

  getPlaylistsTags(ids) {
    let playlistsTags = [];
    const selectPlaylistHandler = this.selectPlaylistHandler;
    const isFeedContainsPlaylist = this.isFeedContainsPlaylist;
    const { deletePlaylist } =this.props;

    this.props.playlists.forEach(function(playlist) {
      playlistsTags.push(
        <a key={playlist.playListId}
           className={(isFeedContainsPlaylist(playlist) ? 'selected ' : '') + "filter-label filter-label_nodelete filter-container__label"}
           onClick={selectPlaylistHandler.bind(this, playlist)}>
          <span className="filter-label__text">{playlist.name}</span>
        </a>
      )
    });
    return playlistsTags;
  }

  render() {
    const {selectFeedBoxActive, createFeedFormShown, selectPlaylistBoxActive, editCalendarFormShown} = this.state;
    const {feeds, activeFeed, activeFeedDayType, playlists,CreatePlaylist} = this.props;
    const hasFeeds = feeds != null && feeds.length > 0;
    let ids = this.getFeedPlaylistsIds();
    let playlistsTags = this.getPlaylistsTags(ids);
    const feedPlaylistsExists = activeFeed && activeFeed.playlists.length > 0;
    return (  
      <div className="feed__top-columns">
        <div className="feed__top-columns-col">
          {playlistsTags && playlistsTags.length === 0 &&
            <div className="feed-control">
            <div className="playlist-info">
          <h3 className="playlist-info__heading"> </h3>
          <p className="playlist-info__hint">Выберите плейлист, в который собираетесь добавлять музыкальные композиции.
              <br /> Для добавления выберите трек и нажмите на иконку добавления в плейлист или перетащите композицию в правую область.</p>
          <div className="no-playlist playlist-info__no-playlist">
              <p className="no-playlist__heading">Вы не создали еще ни одного плейлиста</p>
              <a  href="/"className="no-playlist__icon" onClick={this.props.CreatePlaylist} ><PlayListAddIcon className="svg-icon" /></a>
              <p className="no-playlist__bot-text">Нажмите на кнопку «создать плейлист»</p>
          </div>
           </div>
            <div className="feed-control__empty">
              <a href="/" className="button button_color_blue button_size_big button_weight_normal button_radius_small">
                Для работы с лентами, создайте хотя бы один плейлист.
              </a>
            </div>
          </div>
          }
          {playlistsTags && playlistsTags.length > 0 &&
          <div className="feed-control">
            <div className="feed-control__top-controls">
              <div className="feed-new feed-new__control">
                {
                  createFeedFormShown &&
                  <FeedForm saveFeedHandler={this.saveFeedHandler}/>
                }
                {
                  !createFeedFormShown && hasFeeds &&
                  <button className="feed-new__add" onClick={this.createFeed}>
                    <AddIcon className="svg-icon"/>
                  </button>
                }
                {
                  createFeedFormShown &&
                  <button className="feed-new__add" onClick={this.cancelCreateFeed}>
                    <CloseIcon className="svg-icon"/>
                  </button>
                }
              </div>
              {activeFeed &&
              <a className={(selectFeedBoxActive ? 'active ' : '') + "feed-new__control selectbox feed-control__selectbox"}
                 onClick={this.clickFilterSelectBoxHandler}>
                <ArrowDownIcon className="selectbox__icon svg-icon"/>
                <span className="selectbox__select">{activeFeed.name}</span>
              </a>}
              {playlistsTags && hasFeeds && playlistsTags.length > 0 &&
              <a className={(selectPlaylistBoxActive ? 'active ' : '') + "feed-new__control selectbox feed-control__selectbox feed-control__selectbox-playlist"}
                 onClick={this.clickPlaylistSelectBoxHandler}>
                  <span className="selectbox-control__icon selectbox-control__icon-playlist">
                    <QueueMusicIcon className="svg-icon"/>
                    <span className="right-heading__count">{playlists.length}</span>
                  </span>
                  <span className="selectbox-control__text selectbox-control__text-playlist">Выбрать плейлисты</span>
              </a>
              }
              {activeFeed &&
                <a className={(editCalendarFormShown ? 'active ' : '') + "feed-new__control selectbox feed-control__selectbox feed-control__selectbox-calendar"}
                   onClick={this.clickCalendarSelectBoxHandler}>
                    <span className="selectbox-control__icon selectbox-control__icon-calendar">
                      <CalendarIcon className="svg-icon"/>
                    </span>
                    {!createFeedFormShown &&
                      <span className="selectbox-control__text selectbox-control__text-calendar">Настройки календаря</span>
                    }
                </a>
              }
            </div>
            <div className={"feed-control__top-control-values" + (selectFeedBoxActive || selectPlaylistBoxActive || editCalendarFormShown ? ' active' : '')}>
            <div className={"filter-container__accordion filter-container__accordion-feed " + (selectFeedBoxActive ? 'filter-container__accordion-active' : '')}>
              <a className="filter-container__accordion-close" onClick={this.clickFilterSelectBoxHandler}><CloseIcon
                className="svg-icon"/></a>
              {feeds && feeds.map((feed) =>
                <a key={feed.feedId} className="filter-label filter-container__label"
                   onClick={this.selectFeedHandler.bind(this, feed)}>
                {/* <a key={feed.feedId} className="filter-label filter-container__label"> */}
                  <span className="filter-label__text">{feed.name}</span>
                  <TrashIcon className="filter-label__icon filter-label__icon_close svg-icon" onClick={this.removeFeedHandler.bind(this, feed)}/>
                </a>
              )}
            </div>

            {playlistsTags && playlistsTags.length > 0 &&
            <div className={"filter-container__accordion filter-container__accordion-feed " + (selectPlaylistBoxActive ? 'filter-container__accordion-active ' : '')}>
              <a className="filter-container__accordion-close"
                 onClick={this.clickPlaylistSelectBoxHandler.bind(this, !selectPlaylistBoxActive)}>
                <CloseIcon className="svg-icon"/>
              </a>
              {playlistsTags}
            </div>
            }

            {activeFeed &&
            <div className={"filter-container__accordion filter-container__accordion-feed " + (editCalendarFormShown ? 'filter-container__accordion-active ' : '')}>
              <a className="filter-container__accordion-close"
                 onClick={this.clickCalendarSelectBoxHandler.bind(this, !editCalendarFormShown)}>
                <CloseIcon className="svg-icon"/>
              </a>
              <div className="feed-control__checkbox-wrap">
                <label className="checkbox">
                  <input className="checkbox__input" type="checkbox" name="new-feed"
                        checked={activeFeedDayType === feedUtils.WEEKEND_FEED_TYPE}
                        onChange={this.dayTypeCheckboxChanged.bind(this, feedUtils.WEEKEND_FEED_TYPE)}/>
                  <CheckIcon className="checkbox__box svg-icon"/>
                  <p className="checkbox__text text-small">Для выходных дней</p>
                </label>
                <label className="checkbox">
                  <input className="checkbox__input" type="checkbox" name="new-feed"
                        checked={activeFeedDayType === feedUtils.WEEKDAY_FEED_TYPE}
                        onChange={this.dayTypeCheckboxChanged.bind(this, feedUtils.WEEKDAY_FEED_TYPE)}/>
                  <CheckIcon className="checkbox__box svg-icon"/>
                  <p className="checkbox__text text-small">Для будней</p>
                </label>
                <label className="checkbox">
                  <input className="checkbox__input" type="checkbox" name="new-feed"
                        checked={activeFeedDayType === feedUtils.OTHER_FEED_TYPE}
                        onChange={this.dayTypeCheckboxChanged.bind(this, feedUtils.OTHER_FEED_TYPE)}
                  />
                  <CheckIcon className="checkbox__box svg-icon"/>
                  <p className="checkbox__text text-small">Для определенного дня</p>
                </label>
              </div>
            </div>
            }
            </div>
            {
              !activeFeed && feeds.length === 0 &&
              <CreeateFeedForm saveFeedHandler={this.saveFeedHandler} />
              // <div className="feed__playlists">
              //   <a onClick={this.createFeed} className="button button_color_blue button_size_big button_weight_normal button_radius_small">
              //     Создайте ленту
              //   </a>
              // </div>
            }
            {/* {
              activeFeed && activeFeed.playlists.length === 0 &&
              // <CreeatePlaylistForm />
              <div className="feed__playlists">
                <a onClick={this.clickPlaylistSelectBoxHandler.bind(this, !selectPlaylistBoxActive)}
                   className="button button_color_blue button_size_big button_weight_normal button_radius_small">
                  Выберите плейлист
                </a>
              </div>
            } */}
          </div>
          }
        </div>
        <div className="feed__top-columns-col">
          <h1 className="layout__heading heading-main">Ленты</h1>
        </div>
      </div>
    )
  }
}

export default FeedFilter;
