import React, { Component } from 'react';
import CompilationPreview from "./CompilationPreview";
import Compilation from "./Compilation";
import Slider from "react-slick";

class CompilationsGrid extends Component {

  constructor(props, context) {
    super(props, context);
    this.fetchCompilationsPages = this.fetchCompilationsPages.bind(this);
    this.COMPILATION_PER_PAGE = 9;
  }

  componentWillUnmount() {
    this.props.deselectPlaylist();
  }

  componentDidMount() {
    this.props.fetchCompilations();
    // if (this.props.playingWavesurfer) {
    //   this.props.playingWavesurfer.pause();
    //   this.props.stopPlayingTrack();
    // }
  }

  fetchCompilationsPages() {
    let pages = [];
    const {compilations} = this.props;

    let compilationsCopy = compilations.slice(0);
    while (compilationsCopy.length) {
      pages.push(compilationsCopy.splice(0, this.COMPILATION_PER_PAGE));
    }

    return pages;
  }

  render() {
    const {} = this.props;
    const compilationsPages = this.fetchCompilationsPages();

    if (compilationsPages.length == 0) {
      return (
        <div className="catalogue">
          <span>
            <h1 className="layout__heading heading-main">Подборки</h1>
            <div className="slider swiper-container catalogue-slider">
              <div className="">
              </div>
            </div>
          </span>
        </div>
      );
    }

    const sliderSettings = {
      dots: true,
      speed: 500,
      appendDots: dots => (
        <div className="slider__dots swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
          {dots}
        </div>
      ),
      customPaging: i => (
        <span className="swiper-pagination-bullet"/>
      )
    };
    return (
      <span>
        <div className="catalogue">
            <h1 className="layout__heading heading-main">Подборки</h1>
            <div className="slider swiper-container catalogue-slider">
              <div className="">
                <Slider {...sliderSettings}>
                  {compilationsPages.map((page, i) =>

                    <div className="slider__slide swiper-slide" key={i}>
                      <div className="grid slider__grid">
                        <div className="grid__col grid__col_four">
                          { page.length > 0 &&
                            <CompilationPreview compilation={page[0]} size="card_size_four" />
                          }
                        </div>
                        <div className="grid__col grid__col_four">
                          { page.length > 1 &&
                            <CompilationPreview compilation={page[1]} size="card_size_four" />
                          }
                        </div>
                        <div className="grid__col grid__col_four">
                          {page.length > 2 &&
                            <CompilationPreview compilation={page[2]} size="card_size_four" />
                          }
                        </div>
                        <div className="grid__col grid__col_four">
                          {page.length > 3 &&
                            <CompilationPreview compilation={page[3]} size="card_size_four" />
                          }
                        </div>
                        <div className="grid__col grid__col_four">
                          {page.length > 4 &&
                            <CompilationPreview compilation={page[4]} size="card_size_four" />
                          }
                        </div>
                        <div className="grid__col grid__col_four">
                          {page.length > 5 &&
                            <CompilationPreview compilation={page[5]} size="card_size_four" />
                          }
                        </div>
                        <div className="grid__col grid__col_four">
                          {page.length > 6 &&
                            <CompilationPreview compilation={page[6]} size="card_size_four" />
                          }
                        </div>
                        <div className="grid__col grid__col_four">
                          {page.length > 7 &&
                            <CompilationPreview compilation={page[7]} size="card_size_four" />
                          }
                        </div>
                        <div className="grid__col grid__col_four">
                          {page.length > 8 &&
                            <CompilationPreview compilation={page[8]} size="card_size_four" />
                          }
                        </div>
                      </div>
                    </div>
                    )
                  }
                </Slider>
              </div>
            </div>
        </div>
      </span>
    )
  }
}

export default CompilationsGrid;
