import React, { Component } from 'react';
import * as playlistUtils from "../../../utils/playlists";

class CreatePlaylist extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      name: '',
      dayTime: playlistUtils.MORNING_TIME
    }
  }

  onNameChange(event) {
    this.setState({name: event.target.value});
  }

  savePlaylist(event) {
    let {name, dayTime} = this.state;
    if (name !== '') {
      this.props.savePlaylist(name, dayTime);
    }
  }

  handleDayTimeChange(event) {
    this.setState({
      dayTime: +event.target.value
    });
  }

  render() {
    let {dayTime} = this.state;
    return (
      <div className="playlist-info">
          <h3 className="playlist-info__heading">Создание нового плейлиста</h3>
          <p className="playlist-info__hint">Создайте новый плейлист и выберете 
время суток для него. 
После этого добавляйте новые треки.</p>
          <div className="playlist-info__tag-container">
              <input className="playlist-info__name" placeholder="Название Вашего плейлиста" type="text" value={this.state.name} onChange={this.onNameChange.bind(this)} />
          </div>
          <div className="playlist-info__tag-container">
              <label className="playlist-info__radio-wrap">
                  <input className="playlist-info__control" type="radio" name="time-tag" checked={playlistUtils.isMorningTime(dayTime)}
                         value={playlistUtils.MORNING_TIME} onChange={this.handleDayTimeChange.bind(this)} />
                  <span className="playlist-info__time-tag playlist-info__time-tag_round_left">Утро</span>
              </label>
              <label className="playlist-info__radio-wrap">
                  <input className="playlist-info__control" type="radio" name="time-tag" checked={playlistUtils.isDayTime(dayTime)}
                         value={playlistUtils.DAY_TIME} onChange={this.handleDayTimeChange.bind(this)} />
                  <span className="playlist-info__time-tag">День</span>
              </label>
              <label className="playlist-info__radio-wrap">
                  <input className="playlist-info__control" type="radio" name="time-tag" checked={playlistUtils.isEveningTime(dayTime)}
                         value={playlistUtils.EVENING_TIME} onChange={this.handleDayTimeChange.bind(this)} />
                  <span className="playlist-info__time-tag">Вечер</span>
              </label>
              <label className="playlist-info__radio-wrap">
                  <input className="playlist-info__control" type="radio" name="time-tag" checked={playlistUtils.isNightTime(dayTime)}
                         value={playlistUtils.NIGHT_TIME} onChange={this.handleDayTimeChange.bind(this)} />
                  <span className="playlist-info__time-tag playlist-info__time-tag_round_right">Ночь</span>
              </label>
          </div>
          <a className="button button_color_blue button_size_huge playlist-info__button" onClick={this.savePlaylist.bind(this)}>Создать плейлист</a>
          <a className="button button_color_grey button_size_huge playlist-info__button" onClick={this.props.cancelCreatePlaylist} >Отмена</a>
      </div>
    )
  }
}

export default CreatePlaylist;
