import Compilations from '../components/Catalog/Compilations/CompilationsGrid';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {
  changeCompilationTracksViewType,
  fetchCompilations,
  selectCompilation,
  deselectCompilation,
  rateCompilation,
  viewCompilation,
  createPlaylistFromCompilation
} from "../actions/compilations";
import {
  addTrackToPlaylist, removeTrackFromPlaylist, startPlayingTrack, stopPlayingTrack,
  updateCurrentTimeForPlayingTrack, fetchTrackPeaks, fetchAllTrackPeaks, toggleLoader, updatePlaylist, deselectPlaylist
} from "../actions/catalog";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompilations: bindActionCreators(fetchCompilations, dispatch),
    selectCompilation: bindActionCreators(selectCompilation, dispatch),
    addTrackToPlaylist: bindActionCreators(addTrackToPlaylist, dispatch),
    removeTrackFromPlaylist: bindActionCreators(removeTrackFromPlaylist, dispatch),
    startPlayingTrack: bindActionCreators(startPlayingTrack, dispatch),
    stopPlayingTrack: bindActionCreators(stopPlayingTrack, dispatch),
    updateCurrentTimeForPlayingTrack: bindActionCreators(updateCurrentTimeForPlayingTrack, dispatch),
    fetchTrackPeaks: bindActionCreators(fetchTrackPeaks, dispatch),
    fetchAllTrackPeaks: bindActionCreators(fetchAllTrackPeaks, dispatch),
    toggleLoader: bindActionCreators(toggleLoader, dispatch),
    updatePlaylist: bindActionCreators(updatePlaylist, dispatch),
    changeCompilationTracksViewType: bindActionCreators(changeCompilationTracksViewType, dispatch),
    deselectCompilation: bindActionCreators(deselectCompilation, dispatch),
    rateCompilation: bindActionCreators(rateCompilation, dispatch),
    viewCompilation: bindActionCreators(viewCompilation, dispatch),
    createPlaylistFromCompilation: bindActionCreators(createPlaylistFromCompilation, dispatch),
    deselectPlaylist: bindActionCreators(deselectPlaylist, dispatch)
  };
};

function mapStateToProps(state) {
  return {
    compilations: state.compilations.compilations,
    activeCompilation: state.compilations.activeCompilation,
    playlist: state.catalog.playlist,
    playingWavesurfer: state.catalog.playingWavesurfer,
    playingTrack: state.catalog.playingTrack,
    isPlayingTrackActive: state.catalog.isPlayingTrackActive,
    playingTrackSource: state.catalog.playingTrackSource,
    peaks: state.catalog.peaks,
    tracksViewType: state.compilations.tracksViewType
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Compilations);
