import React from 'react';
import WaveSurfer from "wavesurfer.js";
import UrlHelpers from "../../../helpers/Url";
import NProgress from 'nprogress';
import * as tracksUtils from "../../../utils/tracks";
import * as wsUtils from "../../../utils/wavesurfer";

export default function withWavesurfer(Component, data) {
  return class extends React.Component {

    constructor(props, context) {
      super(props, context);

      this.wavesurfer = null;
      this.updateWave = this.updateWave.bind(this);
      this.initPlayer = this.initPlayer.bind(this);
      this.play = this.play.bind(this);
      this.pause = this.pause.bind(this);
      this.needToDestroyGeneralWave = this.needToDestroyGeneralWave.bind(this);
      this.trackIsDifferent = this.trackIsDifferent.bind(this);
      this.isContentTrackActive = this.isContentTrackActive.bind(this);
      this.isTrackActive = this.isTrackActive.bind(this);
      this.isTrackPaused = this.isTrackPaused.bind(this);
      this.isTrackSelected = this.isTrackSelected.bind(this);
    }

    updateWave() {
      if (this.wavesurfer && !this.wavesurfer.isDestroyed) {
        this.wavesurfer.drawBuffer();
      }
    }

    componentDidMount() {
      this.updateWave();
      window.addEventListener("resize", this.updateWave);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWave);
    }

    isWavePositionChangedToSpecific(playingWavesurfer) {
      return playingWavesurfer && playingWavesurfer.generalWave && !data.generalWave;
    }

    needToDestroyGeneralWave(newTrack, trackIndex) {
      const {playingWavesurfer, playingTrack, playingTrackSource, playingTrackIndex} = this.props;
      const isGeneralWavePlaying = playingWavesurfer && playingWavesurfer.generalWave;
      const needToDestroy = this.isWavePositionChangedToSpecific(playingWavesurfer) ||
        (playingTrackSource !== data.source) ||
        (newTrack.trackId !== playingTrack.trackId) ||
        (newTrack.trackId === playingTrack.trackId && playingWavesurfer.generalWave !== data.generalWave) ||
        (newTrack.trackId === playingTrack.trackId && trackIndex !== playingTrackIndex);

      return isGeneralWavePlaying && needToDestroy;
    }

    trackIsDifferent(newTrack) {
      const {playingTrack, playingTrackSource} = this.props;
      return playingTrack.trackId && (playingTrack.trackId !== newTrack.trackId || playingTrackSource !== data.source);
    }

    play(newTrack, target, trackPeaks, trackIndex) {
      const {playingWavesurfer} = this.props;
      if (this.needToDestroyGeneralWave(newTrack, trackIndex) || !this.wavesurfer) {
        //if previous track has general wave and it need to be destroyed
        tracksUtils.clearPlayer(playingWavesurfer);
        if (target && this.wavesurfer) {
          this.wavesurfer = null;
        }
      } else if (this.trackIsDifferent(newTrack)) {
        //if each player has separate wave and need to stop the previous track
        tracksUtils.stopPlayer(this.props.playingWavesurfer);
      }

      if (target && (this.trackIsDifferent(newTrack) || !this.wavesurfer)) {
        this.initPlayer(trackPeaks, target);
      }

      if (this.wavesurfer && !this.wavesurfer.loaded) {
        NProgress.start();
        this.props.toggleLoader(true);
      }

      if (this.wavesurfer && !this.wavesurfer.loaded) {
        let that = this;

        this.props.startPlayingTrack(newTrack, this.wavesurfer, data.source, trackIndex);

        wsUtils.loadWavesurfer(this.wavesurfer, wsUtils.trackUrl(newTrack.audioFile), trackPeaks, 'auto');

        this.wavesurfer.on('play', () => {
          NProgress.done();
          that.props.startPlayingTrack(newTrack, that.wavesurfer, data.source, trackIndex);
          if (that.props.loadNextTracks) {
            that.props.loadNextTracks(trackIndex);
          }
        });

        this.wavesurfer.on('audioprocess',  () => {
          that.props.updateCurrentTimeForPlayingTrack( wsUtils.formatTime(that.wavesurfer.getCurrentTime()) );
        });

        this.wavesurfer.on('seek',  () => {
          that.props.updateCurrentTimeForPlayingTrack( wsUtils.formatTime(that.wavesurfer.getCurrentTime()) );
        });

        this.wavesurfer.on('finish', () => {
          that.props.stopPlayingTrack();
          if (that.props.onTrackStopCallback) {
            that.props.onTrackStopCallback(newTrack);
          }
        });
      }
      this.wavesurfer && this.wavesurfer.play();
    }

    pause() {
      const {playingWavesurfer} = this.props;
      let wavesurfer = this.wavesurfer || playingWavesurfer;
      wavesurfer.pause();
      this.props.stopPlayingTrack();
    }

    isContentTrackActive(track) {
      const {playingTrack, playingWavesurfer, isPlayingTrackActive} = this.props;
      if (!playingWavesurfer) {
        return false;
      }
      return track.trackId === playingTrack.trackId &&
        playingWavesurfer.generalWave &&
        isPlayingTrackActive;
    }

    //If track is playing
    isTrackActive(track, trackIndex) {
      const {isPlayingTrackActive} = this.props;
      if (!this.wavesurfer) {
        return false;
      }
      return  this.isTrackSelected(track, trackIndex) && isPlayingTrackActive;
    }

    isTrackPaused(track, trackIndex) {
      return this.isTrackSelected(track, trackIndex) && !this.isTrackActive(track, trackIndex);
    }

    //If track is playing or paused
    isTrackSelected(track, trackIndex) {
      const {playingTrack, playingWavesurfer, playingTrackSource, playingTrackIndex} = this.props;
      if (!this.wavesurfer) {
        return false;
      }
      return playingTrackSource === data.source &&
        track.trackId === playingTrack.trackId &&
        this.wavesurfer.generalWave === playingWavesurfer.generalWave &&
        (playingTrackIndex === -1 || playingTrackIndex === trackIndex);
    }

    initPlayer(peaks, elem) {
      if (wsUtils.wavesurferDestroyed(this.wavesurfer)) {
        this.wavesurfer = null;
      }
      //If wavesurfer wasn't initialized earlier
      if (elem && !this.wavesurfer) {
        let container = document.querySelector(data.waveContainerSelector);
        //If each track has separate wave
        if (!container || !data.generalWave) {
          container = elem;
        }
        this.wavesurfer = WaveSurfer.create(wsUtils.getWavesurferInitOptions(container, data.height));

        this.wavesurfer.generalWave = data.generalWave;

        wsUtils.setPeaks(this.wavesurfer, peaks);

        let that = this;
        this.wavesurfer.on("ready", () => {
          if(!that.wavesurfer.loaded && that.wavesurfer && !that.wavesurfer.isDestroyed) {
            that.wavesurfer.loaded = true;
          }
          that.props.toggleLoader(false);
        });

        this.wavesurfer.on('destroy', () => {
          that.props.stopPlayingTrack();
        });
        this.wavesurfer.on('pause', () => {
          that.props.stopPlayingTrack();
        });

        this.wavesurfer.container.addEventListener('mouseenter', (e) => {
          if (that.wavesurfer && !that.wavesurfer.isDestroyed) {
            that.wavesurfer.params.progressColor = wsUtils.generateWaveColor(0.8);
            that.wavesurfer.drawBuffer();
          }
        });

        this.wavesurfer.container.addEventListener('mouseleave', (e) => {
          if (that.wavesurfer && !that.wavesurfer.isDestroyed) {
            that.wavesurfer.params.progressColor = wsUtils.generateWaveColor(1);
            that.wavesurfer.drawBuffer();
          }
        });
      }
    }

    render() {
      return <Component {...this.props}
                   {...this.state}
                    ref={(ref) => this.withWavesurferRef = ref}
                    play={this.play}
                    pause={this.pause}
                    initPlayer={this.initPlayer}
                    wavesurfer={this.wavesurfer}
                    isContentTrackActive={this.isContentTrackActive}
                    isTrackActive={this.isTrackActive}
                    isTrackPaused={this.isTrackPaused}
                    isTrackSelected={this.isTrackSelected}
      />;
    }
  }
}
