import MultiCanvas from 'wavesurfer.js/src/drawer.multicanvas.js';

function PlayerRenderer(container, params) {
  const multiCanvas = new MultiCanvas(container, params);

  multiCanvas.drawBars = function (peaks, channelIndex, start, end) {
    return this.prepareDraw(
      peaks,
      channelIndex,
      start,
      end,
      ({ absmax, hasMinVals, height, offsetY, halfH, peaks }) => {
        // if drawBars was called within ws.empty we don't pass a start and
        // don't want anything to happen
        if (start === undefined) {
          return;
        }
        // Skip every other value if there are negatives.
        const peakIndexScale = hasMinVals ? 2 : 1;
        const length = peaks.length / peakIndexScale;
        const bar = this.params.barWidth * this.params.pixelRatio;
        const gap =
          this.params.barGap === null
            ? Math.max(this.params.pixelRatio, ~~(bar / 2))
            : Math.max(
                  this.params.pixelRatio,
                  this.params.barGap * this.params.pixelRatio
              );
        const step = bar + gap;

        const scale = length / this.width;
        const first = start;
        const last = end;
        let i;

        for (i = first; i < last; i += step) {
          const peak =
            peaks[Math.floor(i * scale * peakIndexScale)] || 0;
          const h = Math.round((peak / absmax) * halfH);
          this.fillRect(
            i + this.halfPixel,
            halfH - h + offsetY + halfH,
            bar + this.halfPixel,
            h
          );
        }
      }
    );
  };
  return multiCanvas;
}

PlayerRenderer.prototype = Object.create(MultiCanvas.prototype);
PlayerRenderer.prototype.constructor = PlayerRenderer;

export default PlayerRenderer;
