import {api} from '../index';
import fetch from 'cross-fetch';
import UrlHelpers from "../helpers/Url";
import mubicloudAPI from "../utils/api";
import UserHelpers from "../helpers/User";
import NProgress from "nprogress";
import {CREATE_PLAYLIST_FROM_COMPILATION_SUCCESS} from "./compilations";
import {createTicketFailure, createTicketSuccess} from "./users";

export const TOGGLE_LOADER  = 'TOGGLE_LOADER';
export const TOGGLE_FETCH_TRACKS_LOADER = 'TOGGLE_FETCH_TRACKS_LOADER';
export const TOGGLE_SEARCH_FETCH_TRACKS_LOADER = 'TOGGLE_SEARCH_FETCH_TRACKS_LOADER';

export const GET_TRACKS_LIST = 'GET_TRACKS_LIST';
export const GET_TRACKS_LIST_SUCCESS = 'GET_TRACKS_LIST_SUCCESS';
export const GET_TRACKS_LIST_FAILURE = 'GET_TRACKS_LIST_FAILURE';
export const FETCH_PEAKS_SUCCESS = 'FETCH_PEAKS_SUCCESS';
export const FETCH_PEAKS_FAILURE = 'FETCH_PEAKS_FAILURE';

export const GET_SEARCH_TRACKS_LIST = 'GET_SEARCH_TRACKS_LIST';
export const GET_SEARCH_TRACKS_LIST_SUCCESS = 'GET_SEARCH_TRACKS_LIST_SUCCESS';
export const GET_SEARCH_TRACKS_LIST_FAILURE = 'GET_SEARCH_TRACKS_LIST_FAILURE';

export const ADD_GENRE_TRACKS_FILTER = 'ADD_GENRE_TRACKS_FILTER';
export const DELETE_GENRE_TRACKS_FILTER = 'DELETE_GENRE_TRACKS_FILTER';
export const ADD_COMPANY_TYPE_FILTER = 'ADD_COMPANY_TYPE_FILTER';
export const DELETE_COMPANY_TYPE_FILTER = 'DELETE_COMPANY_TYPE_FILTER';
export const SORT_TRACKS_BY = 'SORT_TRACKS_BY';
export const CHANGE_SORT_TRACKS_DIRECTION = 'CHANGE_SORT_TRACKS_DIRECTION';
export const REMOVE_TRACKS_SORT = 'REMOVE_TRACKS_SORT';

export const GET_GENRES_SUCCESS = 'GET_GENRES_SUCCESS';
export const GET_GENRES_FAILURE = 'GET_GENRES_FAILURE';

export const TOGGLE_TRACKS_VIEW = 'TOGGLE_TRACKS_VIEW';
export const TOGGLE_OPENED_TRACKS_FILTER = 'TOGGLE_OPENED_TRACKS_FILTER';

export const CREATE_PLAYLIST = 'CREATE_PLAYLIST';
export const CANCEL_CREATE_PLAYLIST = 'CANCEL_CREATE_PLAYLIST';
export const GET_PLAYLISTS_SUCCESS = 'GET_PLAYLISTS_SUCCESS';
export const GET_PLAYLISTS_FAILURE = 'GET_PLAYLISTS_FAILURE';
export const SAVE_PLAYLIST = 'SAVE_PLAYLIST';
export const SAVE_PLAYLIST_FAILURE = 'SAVE_PLAYLIST_FAILURE';
export const SAVE_PLAYLIST_SUCCESS = 'SAVE_PLAYLIST_SUCCESS';
export const SHOW_PLAYLIST_SUCCESS = 'SHOW_PLAYLIST_SUCCESS';
export const SHOW_PLAYLIST_FAILURE = 'SHOW_PLAYLIST_FAILURE';
export const EDIT_PLAYLIST = 'EDIT_PLAYLIST';
export const CANCEL_UPDATE_PLAYLIST = 'CANCEL_UPDATE_PLAYLIST';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_FAILURE = 'DELETE_PLAYLIST_FAILURE';
export const ADD_TRACK_TO_PLAYLIST_FAILURE = 'ADD_TRACK_TO_PLAYLIST_FAILURE';
export const UPDATE_PLAYLIST_FAILURE = 'UPDATE_PLAYLIST_FAILURE';

export const GET_COMPANY_TYPES_SUCCESS = 'GET_COMPANY_TYPES_SUCCESS';
export const GET_COMPANY_TYPES_FAILURE = 'GET_COMPANY_TYPES_FAILURE';

export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';
export const GET_CHANNELS_FAILURE = 'GET_CHANNELS_FAILURE';

export const START_PLAYING_TRACK = 'START_PLAYING_TRACK';
export const STOP_PLAYING_TRACK = 'STOP_PLAYING_TRACK';
export const CLEAR_PLAYING_TRACK = 'CLEAR_PLAYING_TRACK';
export const LIMIT_TRACKS = 'LIMIT_TRACKS';
export const UPDATE_CURRENT_TIME_PLAYING_TRACK = 'UPDATE_CURRENT_TIME_PLAYING_TRACK';

export const DESELECT_PLAYLIST = 'DESELECT_PLAYLIST';

export const ADD_PLAYBACK_STATISTICS = 'ADD_PLAYBACK_STATISTICS';

export function toggleLoader(show) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LOADER,
      payload: show
    });
  };
}

export function toggleFetchTracksLoader(show, clearTracks) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_FETCH_TRACKS_LOADER,
      payload: {show, clearTracks}
    });
  };
}

export function fetchTracks(peaks, genresFilter, companyTypeFilter, sortBy, direction, loadedTracks) {
  if (!loadedTracks) {
    loadedTracks = [];
  }

  let sorting = null;
  if (sortBy && sortBy !== '') {
    sorting = sortBy + '~' + direction;
  }

  return dispatch => {
    return mubicloudAPI(api).getTracks(genresFilter, companyTypeFilter, false, loadedTracks.length, sorting).then((response) => {
      dispatch(toggleFetchTracksLoader(false));
      const allTracksLoaded = response.body.data.length < response.body.limit;
      dispatch(fetchAllTrackPeaks(response.body.data, peaks));
      return dispatch(tracksSuccess(loadedTracks.concat(response.body.data), allTracksLoaded));
    }).catch((err) => {
      dispatch(toggleFetchTracksLoader(false));
      return dispatch(tracksFailure(err));
    });
  };
}

export function tracksSuccess(tracks, allTracksLoaded) {
  return (dispatch) => {
    dispatch({
      type: GET_TRACKS_LIST_SUCCESS,
      payload: {
        data: tracks,
        allTracksLoaded
      }
    });
  };
}

export function tracksFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_TRACKS_LIST_FAILURE,
      payload: error
    });
  });
}

export function toggleFetchSearchTracksLoader(show, clearTracks) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SEARCH_FETCH_TRACKS_LOADER,
      payload: {show, clearTracks}
    });
  };
}

export function fetchSearchTracks(search, loadedTracks) {
  if (!loadedTracks) {
    loadedTracks = [];
  }

  return dispatch => {
    return mubicloudAPI(api).getTracksSearch(search).then((response) => {
      dispatch(toggleFetchSearchTracksLoader(false));
      return dispatch(tracksSearchSuccess(loadedTracks.concat(response.body.data)));
    }).catch((err) => {
      dispatch(toggleFetchSearchTracksLoader(false));
      return dispatch(tracksSearchFailure(err));
    });
  };
}

export function tracksSearchSuccess(tracks) {
  return (dispatch) => {
    dispatch({
      type: GET_SEARCH_TRACKS_LIST_SUCCESS,
      payload: tracks
    });
  };
}

export function tracksSearchFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_SEARCH_TRACKS_LIST_FAILURE,
      payload: error
    });
  });
}

export function fetchAllTrackPeaks(tracks=[], peaks={}) {
  return dispatch => {
    tracks.filter(t => !peaks || !peaks.hasOwnProperty(t.trackId)).reduce((previousFetch, nextTrack) => {
      return previousFetch.then(() => {
        return fetchTrackPeaksSimple(dispatch, nextTrack);
      });
    }, Promise.resolve());
  };
}

export function fetchTrackPeaks(track) {
  return dispatch => {
    return fetch(`${UrlHelpers.basePeaks()}/${track.fileHash}.json`).then(function (response) {
      return response.json();
    }).then((json) => {
      return dispatch(fetchPeaksSuccess(json, track.trackId));
    }).catch((err) => {
      return dispatch(fetchPeaksFailure(err));
    });
  };
}

export function fetchTrackPeaksSimple(dispatch, track) {
  return fetch(`${UrlHelpers.basePeaks()}/${track.fileHash}.json`).then(function (response) {
    return response.json();
  }).then((json) => {
    return dispatch(fetchPeaksSuccess(json, track.trackId));
  });
}

export function fetchPeaksSuccess(json, trackId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PEAKS_SUCCESS,
      payload: {data: json.data, trackId}
    });
  };
}

export function fetchPeaksFailure(err) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PEAKS_FAILURE,
      payload: err
    });
  };
}

export function toggleTracksView(view) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_TRACKS_VIEW,
      payload: view
    });
  };
}

export function toggleOpenedTracksFilter(filter) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_OPENED_TRACKS_FILTER,
      payload: filter
    });
  };
}

export function fetchGenres() {
  return dispatch => {
    return mubicloudAPI(api).getGenres().then((genresData) => {
      return dispatch(genresSuccess(genresData));
    }).catch((err) => {
      return dispatch(genresFailure(err));
    });
  };
}

export function genresSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: GET_GENRES_SUCCESS,
      payload: response.body
    });
  };
}

export function genresFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_GENRES_FAILURE,
      payload: error
    });
  });
}

export function createPlaylist() {
  return (dispatch) => {
    dispatch({
      type: CREATE_PLAYLIST
    });
  };
}

export function cancelCreatePlaylist() {
  return (dispatch) => {
    dispatch({
      type: CANCEL_CREATE_PLAYLIST
    });
  };
}

export function deselectPlaylist() {
  return (dispatch) => {
    dispatch({
      type: DESELECT_PLAYLIST
    });
  };
}

export function cancelUpdatePlaylist() {
  return (dispatch) => {
    dispatch({
      type: CANCEL_UPDATE_PLAYLIST
    });
  };
}

export function fetchPlaylists() {
  return fetchPlaylistsData();
}

export function fetchPlaylistsCompilation() {
  return fetchPlaylistsData(CREATE_PLAYLIST_FROM_COMPILATION_SUCCESS);
}

export function fetchPlaylistsData(action) {
  return dispatch => {
    return mubicloudAPI(api).getPlaylists().then((playlistsData) => {
      return dispatch(fetchPlaylistsSuccess(playlistsData, action));
    }).catch((err) => {
      return dispatch(fetchPlaylistsFailure(err));
    });
  };
}

export function fetchPlaylistsSuccess(response, type=null) {
  return (dispatch) => {
    dispatch({
      type: type || GET_PLAYLISTS_SUCCESS,
      payload: response.body
    });
  };
}

export function fetchPlaylistsFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_PLAYLISTS_FAILURE,
      payload: error
    });
  });
}


export function savePlaylist(name, dayTime) {
  return dispatch => {
    return mubicloudAPI(api).postPlaylists({'name': name, "timeOfDay": dayTime}).then((data) => {
      UserHelpers.showSuccess('Плейлист создан.');
      return dispatch(savePlaylistSuccess());
    }).then(() => {
      return dispatch(fetchPlaylists());
    }).catch((err) => {
      return dispatch(savePlaylistFailure(err));
    });
  };
}

export function savePlaylistSuccess() {
  return (dispatch) => {
    dispatch({
      type: SAVE_PLAYLIST_SUCCESS
    });
  };
}

export function updatePlaylistFailure(error) {
  UserHelpers.showError('Проблема с обновлением плейлиста...');
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: UPDATE_PLAYLIST_FAILURE,
      payload: error
    });
  });
}

export function savePlaylistFailure(error) {
  UserHelpers.showError('Проблема с сохранением плейлиста...');
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: SAVE_PLAYLIST_FAILURE,
      payload: error
    });
  });
}

export function downloadPlayList(playlist) {
  const header = `Скачивание плейлиста: ${playlist.name}`;
  const message = `Рассмотреть скачивание плейлиста: ${playlist.name}`;
  const ticketType = 5;

  UserHelpers.showSuccess('Ваш запрос на скачивание отправляется...');

  return dispatch => {
    return mubicloudAPI(api).postTickets(header, message, ticketType).then((data) => {
      return dispatch(createTicketSuccess(data));
    }).catch((err) => {
      return dispatch(createTicketFailure(err));
    });
  };
}

export function showPlaylist(id) {
  return dispatch => {
    return mubicloudAPI(api).getPlaylistsById(id).then((playlistData) => {
      return dispatch(showPlaylistSuccess(playlistData));
    }).catch((err) => {
      return dispatch(showPlaylistFailure(err));
    });
  };
}

export function showPlaylistSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: SHOW_PLAYLIST_SUCCESS,
      payload: response.body
    });
  };
}

export function showPlaylistFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: SHOW_PLAYLIST_FAILURE,
      payload: error
    });
  });
}

export function editPlaylist(playlist) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PLAYLIST,
      payload: playlist
    });
  };
}

export function deletePlaylist(id) {
  return dispatch => {
    return mubicloudAPI(api).deletePlaylistsById(id).then((data) => {
      return dispatch(deletePlaylistSuccess(data));
    }).then(() => {
      return dispatch(fetchPlaylists());
    }).catch((err) => {
      return dispatch(deletePlaylistFailure(err));
    });
  };
}

export function deletePlaylistSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: DELETE_PLAYLIST_SUCCESS,
      payload: response
    });
  };
}

export function deletePlaylistFailure(error) {
  UserHelpers.showError('Проблема с удалением плейлиста...');
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: DELETE_PLAYLIST_FAILURE,
      payload: error
    });
  });
}

export function removeTrackFromPlaylist(playlist, track) {
  let newPlaylist = Object.assign({}, playlist);
  let tracks = newPlaylist.trackPlayLists;
  tracks.splice(tracks.map(function(e) { return e.trackId; }).indexOf(track.trackId), 1);

  let tracksData = [];
  tracks.map(function(t) {
    tracksData.push({'trackId': t.trackId});
  });
  newPlaylist.trackPlayLists = tracks;

  return dispatch => {
    return mubicloudAPI(api).putPlaylists({"playListId": playlist.playListId,
      "trackPlayLists": tracksData,
      "name": playlist.name,
      "timeOfDay": playlist.timeOfDay}).then(() => {
      return dispatch(showPlaylist(newPlaylist.playListId));
    }).catch((err) => {
      return dispatch(addTrackToPlaylistFailure(err));
    });
  };
}

export function addTrackToPlaylist(playlist, track) {
  let newPlaylist = Object.assign({}, playlist);
  let tracks = newPlaylist.trackPlayLists;
  tracks.push(track);
  let tracksData = [];
  tracks.map(function(t) {
    tracksData.push({'trackId': t.trackId});
  });
  newPlaylist.trackPlayLists = tracks;

  return dispatch => {
    return mubicloudAPI(api).putPlaylists({"playListId": playlist.playListId,
      "trackPlayLists": tracksData,
      "name": playlist.name,
      "timeOfDay": playlist.timeOfDay}).then(() => {
        return dispatch(showPlaylist(newPlaylist.playListId));
      }).catch((err) => {
        return dispatch(addTrackToPlaylistFailure(err));
      });
  };
}

export function updatePlaylist(name, dayTime, playlist, tracksPlaylistData) {
  playlist.name = name;
  playlist.timeOfDay = dayTime;
  const requestData = {"playListId": playlist.playListId,'name': name, "timeOfDay": dayTime};
  if (tracksPlaylistData) {
    requestData.trackPlayLists = tracksPlaylistData;
  }
  return dispatch => {
    return mubicloudAPI(api).putPlaylists(requestData).then(() => {
      return dispatch(showPlaylist(playlist.playListId));
    }).catch((err) => {
      return dispatch(updatePlaylistFailure(err));
    });
  };
}

export function updatePlaylistTracks(playlist, tracks) {
  let newPlaylist = Object.assign({}, playlist);
  newPlaylist.trackPlayLists = tracks;
  let tracksData = [];
  tracks.map(function(t) {
    tracksData.push({'trackId': t.trackId});
  });

  return dispatch => {
    return mubicloudAPI(api).putPlaylists({"playListId": playlist.playListId,
      "trackPlayLists": tracksData,
      "name": playlist.name,
      "timeOfDay": playlist.timeOfDay}).then(() => {
      return dispatch(showPlaylist(newPlaylist.playListId));
    }).catch((err) => {
      return dispatch(updatePlaylistFailure(err));
    });
  };
}

export function addTrackToPlaylistFailure(error) {
  UserHelpers.showError('Проблема с добавлением трека в плейлист...');
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: ADD_TRACK_TO_PLAYLIST_FAILURE,
      payload: error
    });
  });
}

export function fetchCompanyTypes() {
  return dispatch => {
    return mubicloudAPI(api).getCompanies().then((data) => {
      return dispatch(companyTypesSuccess(data));
    }).catch((err) => {
      return dispatch(companyTypesFailure(err));
    });
  };
}

export function companyTypesSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: GET_COMPANY_TYPES_SUCCESS,
      payload: response.body
    });
  };
}

export function companyTypesFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_COMPANY_TYPES_FAILURE,
      payload: error
    });
  });
}

export function fetchChannels() {
  return dispatch => {
    return mubicloudAPI(api).getChannels();
  };
}

export function channelsSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: GET_CHANNELS_SUCCESS,
      payload: response.body
    });
  };
}

export function channelsFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_CHANNELS_FAILURE,
      payload: error
    });
  });
}

export function addGenreFilter(genre) {
  return (dispatch) => {
    dispatch({
      type: ADD_GENRE_TRACKS_FILTER,
      payload: genre
    });
  };
}

export function deleteGenreFilter(genre) {
  return (dispatch) => {
    dispatch({
      type: DELETE_GENRE_TRACKS_FILTER,
      payload: genre
    });
  };
}

export function addCompanyTypeFilter(companyType) {
  return (dispatch) => {
    dispatch({
      type: ADD_COMPANY_TYPE_FILTER,
      payload: companyType
    });
  };
}

export function deleteCompanyTypeFilter(companyType) {
  return (dispatch) => {
    dispatch({
      type: DELETE_COMPANY_TYPE_FILTER,
      payload: companyType
    });
  };
}

export function changeSortBy(sortBy) {
  return (dispatch) => {
    dispatch({
      type: SORT_TRACKS_BY,
      payload: sortBy
    });
  };
}

export function changeTracksSortDirection(direction) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SORT_TRACKS_DIRECTION,
      payload: direction
    });
  };
}

export function removeTracksSort() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_TRACKS_SORT
    });
  };
}

export function startPlayingTrack(playingTrack, wavesurfer, playingTrackSource, trackIndex) {
  return (dispatch) => {
    dispatch({
      type: START_PLAYING_TRACK,
      playingTrack: playingTrack,
      wavesurfer: wavesurfer,
      playingTrackSource: playingTrackSource,
      playingTrackIndex: trackIndex
    });
  };
}

export function playbackStatistic(fileHash) {
  const place = UserHelpers.fetchUserPlace();

  return (dispatch) => {
    mubicloudAPI(api).putTracksByFileHashPlaybacksByPlaceId(fileHash, place['placeId']).then(()=>{
      dispatch({
        type: ADD_PLAYBACK_STATISTICS
      })
    });
  }
}

export function stopPlayingTrack() {
  return (dispatch) => {
    dispatch({
      type: STOP_PLAYING_TRACK
    });
  };
}

export function clearPlayingTrack(playingWavesurfer) {
  if (playingWavesurfer) {
    playingWavesurfer.stop();
    playingWavesurfer.destroy();
    playingWavesurfer = null;
  }
  return (dispatch) => {
    dispatch({
      type: CLEAR_PLAYING_TRACK
    });
  };
}

export function limitTracks(tracks) {
  if (!tracks) return;
  if (tracks.length > 30)
    tracks = tracks.slice(0, 30);
  return (dispatch) => {
    dispatch({
      type: LIMIT_TRACKS,
      payload: tracks
    });
  };
}

export function updateCurrentTimeForPlayingTrack(time) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CURRENT_TIME_PLAYING_TRACK,
      time: time
    });
  };
}
