import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as utils from '../../../utils/tracks';
import {
  updateCurrentTimeForPlayingTrack,
} from "../../../actions/catalog";
import {bindActionCreators} from "redux";


class DurationTracklistPlayer extends Component {

  constructor(props, context) {
    super(props, context);
    this.currentTrackPlaying = this.currentTrackPlaying.bind(this);
  }

  currentTrackPlaying() {
    const {currentTimePlayingTrack, playingTrackSource, trackId, playingTrackId} = this.props;
    return currentTimePlayingTrack && playingTrackSource === utils.TRACK_SOURCE_LIST && trackId === playingTrackId;
  }

  render() {
    const {duration, currentTimePlayingTrack} = this.props;

    return (
      <span className="track-list__time">
        {this.currentTrackPlaying() ? currentTimePlayingTrack : utils.renderTrackDuration(duration)}
      </span>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentTimeForPlayingTrack: bindActionCreators(updateCurrentTimeForPlayingTrack, dispatch),
});

export default connect(state => ({currentTimePlayingTrack: state.catalog.currentTimePlayingTrack}),
  mapDispatchToProps)(DurationTracklistPlayer);
