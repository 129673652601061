import React, { Component } from 'react';
import TracksGridItem from './TracksGridItem.js';
class TracksGrid extends Component {


  render() {

    let {tracks,
      addTrackToPlaylist,
      removeTrackFromPlaylist,
      playlist,
      startPlayingTrack,
      playingWavesurfer,
      playingTrack,
      isPlayingTrackActive,
      stopPlayingTrack,
      playingTrackSource,
      updateCurrentTimeForPlayingTrack,
      peaks,
      fetchTrackPeaks,
      toggleLoader} = this.props;

    return (
      <div className="tracks catalogue__track">
        {tracks && tracks.map((track, i) =>
          <TracksGridItem key={track.trackId} track={track}
                          addTrackToPlaylist={addTrackToPlaylist}
                          removeTrackFromPlaylist={removeTrackFromPlaylist}
                          playlist={playlist}
                          startPlayingTrack={startPlayingTrack}
                          stopPlayingTrack={stopPlayingTrack}
                          playingWavesurfer={playingWavesurfer}
                          playingTrack={playingTrack}
                          isPlayingTrackActive={isPlayingTrackActive}
                          playingTrackSource={playingTrackSource}
                          updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                          peaks={peaks}
                          fetchTrackPeaks={fetchTrackPeaks}
                          toggleLoader={toggleLoader}
          />
        )}

      </div>
    )
  }
}

export default TracksGrid;
