import SelectPlaceForm from '../components/SelectPlaceForm.js';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {fetchPlaces, setUserPlace, setUserPlaceMemory, fetchUserFullInfo} from "../actions/users";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserFullInfo: bindActionCreators(fetchUserFullInfo, dispatch),
    fetchPlaces: bindActionCreators(fetchPlaces, dispatch),
    setUserPlace: bindActionCreators(setUserPlace, dispatch),
    setUserPlaceMemory: bindActionCreators(setUserPlaceMemory, dispatch)
  };
};

function mapStateToProps(state, ownProps) {
  return {
    userInfo: state.user.userInfo,
    user: state.user.user,
    places: state.user.places,
    place: state.user.place,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlaceForm);
