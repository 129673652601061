import {
  FETCH_FEEDS_SUCCESS,
  FETCH_FEEDS_FAILURE,
  REMOVE_FEED_SUCCESS,
  REMOVE_FEED_FAILURE,
  SELECT_FEED,
  SAVE_FEED_SUCCESS,
  SAVE_FEED_FAILURE,
  CHANGE_ACTIVE_FEED_DAY_TYPE,
  UPDATE_FEED_PLAYLIST_SUCCESS,
  UPDATE_FEED_PLAYLIST_FAILURE,
  START_PLAYING_FEED_TRACK,
  STOP_PLAYING_FEED_TRACK,
  CLEAR_PLAYING_FEED_TRACK,
  UPDATE_CURRENT_TIME_PLAYING_FEED_TRACK,
  TOGGLE_FEED_LOADER,
  SELECT_FEED_FAILURE,
  GET_GENERAL_FEED_SUCCESS,
  GET_GENERAL_FEED_FAILURE,
  START_GENERAL_FEED,
  STOP_GENERAL_FEED,
  START_PLAYING_GENERAL_FEED_PLAYLIST,
  START_PLAYING_GENERAL_FEED_TRACK,
  START_PLAYING_GENERAL_FEED_PLAYLIST_ERR,
  START_PLAYING_GENERAL_FEED_TRACK_ERR
} from '../actions/feed';

import {
  getCurrentSelectedFeedId
} from '../utils/feeds';

const INITIAL_STATE = {feeds: [], activeFeed: null, isFeedCalendarShown: false,
  playingFeedTrack: {}, playingFeedWavesurfer: null, playingFeedPlaylist: {}, loading : false,
  currentTimePlayingFeedTrack: null, isPlayingFeedTrackActive: false, activeFeedDayType: 0,
  activeFeedDate: null, playingFeedTrackIndex: -1, generalHeaderFeed: null, isGeneralHeaderFeedPlaying: false};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_FEED_LOADER:
      return {...state, loading: action.payload};
    case FETCH_FEEDS_SUCCESS:
      return {...state, feeds: action.feeds};
    case FETCH_FEEDS_FAILURE:
      return {...state, feeds: [], error: action.payload};
    case REMOVE_FEED_SUCCESS:
      const newFeeds = action.payload;
      const newActiveFeed = action.activeFeed;
      return {...state, activeFeed: newActiveFeed, activeFeedDayType: newActiveFeed.calendarDay, activeFeedDate: newActiveFeed.calendarDate, feeds: newFeeds};
    case REMOVE_FEED_FAILURE:
      return {...state, error: action.payload};

    case SELECT_FEED:
      const feed = action.payload;
      return {...state, activeFeed: feed, error: null, activeFeedDayType: feed.calendarDay, activeFeedDate: feed.calendarDate};
    case SELECT_FEED_FAILURE:
      return {...state, error: action.payload};

    case SAVE_FEED_SUCCESS:
      return {...state};
    case SAVE_FEED_FAILURE:
      return {...state, error: action.payload};

    case CHANGE_ACTIVE_FEED_DAY_TYPE:
      return {...state, activeFeedDayType: action.payload, activeFeedDate: action.payload.calendarDate};

    case UPDATE_FEED_PLAYLIST_SUCCESS:
      return {...state, activeFeed: action.payload, activeFeedDayType: action.payload.calendarDay, activeFeedDate: action.payload.calendarDate};
    case UPDATE_FEED_PLAYLIST_FAILURE:
      return {...state, error: action.payload};

    case GET_GENERAL_FEED_SUCCESS:
      return {...state, generalHeaderFeed: action.payload};
    case GET_GENERAL_FEED_FAILURE:
      return {...state, error: action.payload};

    case START_PLAYING_FEED_TRACK:
      return {...state, playingFeedTrack: action.playingTrack,
        playingFeedWavesurfer: action.wavesurfer,
        playingFeedPlaylist: action.playingPlaylist,
        isPlayingFeedTrackActive: true,
        playingFeedTrackIndex: action.playingFeedTrackIndex};

    case CLEAR_PLAYING_FEED_TRACK:
      return {...state, playingFeedTrack: {},
        playingFeedWavesurfer: null,
        playingFeedPlaylist: {},
        isPlayingFeedTrackActive: false,
        playingFeedTrackIndex: -1};
    case STOP_PLAYING_FEED_TRACK:
      return { ...state, isPlayingFeedTrackActive: false};

    case UPDATE_CURRENT_TIME_PLAYING_FEED_TRACK:
      return { ...state, currentTimePlayingFeedTrack: action.time};

    case START_GENERAL_FEED:
      return { ...state, isGeneralHeaderFeedPlaying: true};
    case STOP_GENERAL_FEED:
      return { ...state, isGeneralHeaderFeedPlaying: false};

    case START_PLAYING_GENERAL_FEED_PLAYLIST:
      return { ...state, isGeneralFeedPlaylistStatSave: true};
    case START_PLAYING_GENERAL_FEED_PLAYLIST_ERR:
      return { ...state, isGeneralFeedPlaylistStatSave: false};
    case START_PLAYING_GENERAL_FEED_TRACK:
      return { ...state, isGeneralFeedPlaylistStatSave: true};
    case START_PLAYING_GENERAL_FEED_TRACK_ERR:
      return { ...state, isGeneralFeedTrackStatSave: false};

        default:
      return state;
  }
}
