export default class EnvHelpers {
  static jsEnv() {
    return process.env.REACT_APP_ENV;
  }

  static isStaging() {
    return process.env.REACT_APP_ENV === 'staging';
  }

  static isDemo() {
    return process.env.REACT_APP_DEMO === '1';
  }

  static isProduction() {
    return process.env.REACT_APP_ENV === 'production';
  }
}
