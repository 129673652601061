import {
  THEME_CHANGE,
  THEME_TOGGLE,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  RESET_USER,
  SET_USER_PLACE,
  GET_PLACES_LIST_SUCCESS,
  GET_PLACES_LIST_FAILURE,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  LOGOUT_USER,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_COMPANY_INFO_SUCCESS,
  UPDATE_COMPANY_INFO_FAILURE,
  FETCH_USER_FULL_INFO_SUCCESS,
  FETCH_USER_FULL_INFO_FAILURE,
  CREATE_TICKET_FAILURE, CREATE_TICKET_SUCCESS,
  CLEAR_TICKET
} from '../actions/users';
import { setDarkMode, setLightMode } from '../utils/theme';

const INITIAL_STATE = {user: {}, status:null, error:null, loading: false, theme: null, userInfo: {}, places: [], place: null, ticketData: null};

export default function(state = INITIAL_STATE, action) {
  let error;
  switch(action.type) {
    case SIGNUP_USER:
    return { ...state, user: {}, status:'signup', error:null, loading: true};
    case SIGNUP_USER_SUCCESS:
    return { ...state, user: action.payload.user, status:'authenticated', error:null, loading: false};
    case SIGNUP_USER_FAILURE:
    error = action.payload.data || {message: action.payload.message};
    return { ...state, user: {}, status:'signup', error:error, loading: false};

    case SET_USER_PLACE:
      return { ...state, place: action.payload};
    case GET_PLACES_LIST_SUCCESS:
      return { ...state, places: action.payload.data, error: null};
    case GET_PLACES_LIST_FAILURE:
      return { ...state, places: [], error: action.payload};

    case SIGNIN_USER_SUCCESS:
    return { ...state, user: action.payload, status:'authenticated', error:null, loading: false};
    case SIGNIN_USER_FAILURE:
    error = action.payload;
    return { ...state, user: {}, status:'signin', error:error, loading: false};

    case THEME_CHANGE:
      return { ...state, theme: action.payload};
    case THEME_TOGGLE:
      const currentTheme = state.theme === 'dark' ? setLightMode() : setDarkMode();
      return { ...state, theme: currentTheme};

    case LOGOUT_USER:
      return {...state, user:{}, status:'logout', error:null, loading: false};

    case RESET_USER:
    return { ...state, user: {}, status:null, error:null, loading: false};

    case UPDATE_PASSWORD_SUCCESS:
      return { ...state};
    case UPDATE_PASSWORD_FAILURE:
      return { ...state, error:action.payload};

    case UPDATE_COMPANY_INFO_SUCCESS:
      return { ...state};
    case UPDATE_COMPANY_INFO_FAILURE:
      return { ...state, error:action.payload};

    case FETCH_USER_FULL_INFO_FAILURE:
      return { ...state, error:action.payload};
    case FETCH_USER_FULL_INFO_SUCCESS:
        return { ...state, userInfo: Object.assign({}, action.payload)};

    case CREATE_TICKET_FAILURE:
      return { ...state, error: action.payload};
    case CREATE_TICKET_SUCCESS:
      return { ...state, ticket: action.payload};
    case CLEAR_TICKET:
      return { ...state, ticket: null};

    default:
    return state;
  }
}
