import React from 'react';

import {ReactComponent as HelpIcon} from '../../icons/help_outline.svg';
import StoreButton from '../Layout/StoreButton';

export default class HelpSidebar extends React.Component
{
  render() {
    return (
      <div>
          <div className="right-heading">
              <span className="right-heading__icon right-heading__icon_calendar">
                  <HelpIcon className="svg-icon" />
              </span>
              <span className="right-heading__text">Помощь</span>
          </div>
          <div className="playlist-info">
              <p className="playlist-info__hint nomargintop">Пожалуйста, ознакомьтесь с короткими инструкциями о сервисе и способах его использования. В случае возникновения иных вопросов, отправьте сообщение в службу поддержки через раздел Аккаунт-Тикеты, в мессенджеры либо на почту. Специалист компании свяжется удобным для вас способом.</p>
          </div>

          <div className="playlist-apps">
            <div className="playlist-apps_header">
              Управляйте музыкой <br/>в мобильном приложении
            </div>
            <StoreButton type="rustore"/>
            <StoreButton type="google"/>
            <div className="playlist-apps_sub_header">
              Служба поддержки.
            </div>
            <div className="playlist-apps_contacts">
              <a href="mailto://support@mubicloud.ru?Subject=Запрос из сервиса">support@mubicloud.ru</a>
              <br/>
              <a href="tel://+78006004391x">8 800 600-43-91</a>
            </div>
          </div>
      </div>
    )
  }
}

